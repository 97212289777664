import {
    Button
} from "@mui/material";
import { Box, Paper, Typography } from "@mui/material";
import { useSnackbar } from "src/hooks/useSnackbar";
import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { UserForm } from "./UserForm";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Clear } from "@mui/icons-material";
import { useAuth } from "src/hooks/useAuth";
import { UserService } from "src/api/user.service";

export function UpdateUserPage() {
    const { Snackbar } = useSnackbar();
    const { userId } = useParams();
    const { setSnackbarMessage } = useSnackbar();
    const { session } = useAuth();
    const [user, setUser] = useState(null);
    const [update, setUpdate] = useState(null);
    const navigate = useNavigate();

    const service = new UserService(session.token, session.tenant._id);

    const auth = session.permissions;

    useEffect(() => {
        async function fetchData() {
            const user = await service.getUserById(userId);
            setUser(user);
        }
        fetchData();
    }, []);

    useEffect(() => {
        setUser(update);
    }, [update]);

    const isBackofficeUser = auth.IsBackOffice;
    const allowRemove = isBackofficeUser;

    /**
     * Handles the removal of a user.
     * @returns {Promise<void>} A promise that resolves when the user is successfully removed.
     */
    const handleRemove = async () => {
        let res = await service.deleteUser(userId);
        if (res.status === 200) {
            setSnackbarMessage({
                message: `User ${userId} removed successfully.`,
                severity: "success",
            });
            navigate("/users/create");
        }
        else 
        {
            setSnackbarMessage({
                message: `Error removing user: ${res.message}.`,
                severity: "error",
            });
        }
    }

    /**
     * Handles saving the updated user data.
     * @param {Object} values - The updated user data.
     * @returns {Promise<void>}
     */
    const handleSave = async (values) => {
        // Handle location empty string
        if (values?.location == "")
            delete values.location;

        setUpdate(values);
        await service.patchUser(userId, values).then((data) => {
            if (data.errors) {
                setSnackbarMessage({
                    message: (<Box> Error submitting user </Box>),
                    severity: "error",
                });
            } else {
                setSnackbarMessage({
                    message: `User ${data.firstname} updated successfully.`,
                    severity: "success",
                });
                navigate(`/users/${userId}`);

            }
        });
    };

    return (
        <Paper sx={{ maxWidth: { lg: "1000px", xs: "90%" }, margin: "auto", py: 3 }} elevation={3}>
            <Helmet>
                <title>Edit User</title>
            </Helmet>
            <Box sx={{ maxWidth: "80%", margin: "auto" }}>
                <Typography variant="h4" component="h1" sx={{ mb: "2rem" }}>
                    Edit User{(user ? `: ${user.firstname} ${user.lastname}` : "")}
                    <Box sx={{ display: "inline-block", float: "right" }}>
                        {allowRemove &&
                            <Button size="small" variant="text" color="error" onClick={handleRemove}>
                                <Clear /> Remove
                            </Button>
                        }
                    </Box>
                </Typography>

                <UserForm
                    reset={false}
                    useFormParams={{ defaultValues: user }}
                    onSave={handleSave}
                />
            </Box>
            {/* DISPLAY SUCESS OR ERRORS MESSAGE */}
            <Snackbar />
        </Paper>
    );
}
