import * as React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { StandardDialog } from 'src/components/dialog/Dialog';

function DialogUncombineShipment(props) {
    return (
        <StandardDialog isOpen={props.open} onClose={props.onClose}>
            <Box sx={{ width: '100%'}}>
                <DialogTitle>Uncombine Shipment</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {`Are you sure you want to unmatch this shipment?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ pr: 6, pb: 4 }}>
                    <Button variant="text" onClick={props.onClose}>No</Button>
                    <Button variant="contained" onClick={props.onConfirm}>Yes</Button>
                </DialogActions>
            </Box>
        </StandardDialog>
    );
}

export default DialogUncombineShipment;