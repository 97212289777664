import { useMutation, useQuery } from "react-query";
import { StorageService } from "src/api/storage.service";
import { useAuth } from "src/hooks/useAuth";
import { useSnackbar } from "src/hooks/useSnackbar";

export function useStorageQueries() {
  const { session } = useAuth();
  const { setSnackbarMessage } = useSnackbar();

  const service = new StorageService(session.token, session.tenant._id);

  let filename = '';
  const { data: videoUrl, isRefetching: isVideoLoading, refetch: refetchVideoUrl } = useQuery('video-url', {
    queryFn: () => service.getVideoURL(filename),
    enabled: false,
  });

  const getVideoUrl = async (value) => {
    filename = value;
    refetchVideoUrl();
  };

  const { mutate: downloadFileFromStorage, isLoading: isLoadingFile } =
    useMutation({
      mutationFn: ({ fileName, container, action }) =>
        service
          .getFile(fileName, container)
          .then((file) => {
            switch (action) {
              case "download":
                file.download();
                break;
              case "open":
                file.openFile();
                break;
              default:
                throw new Error("Not valid action");
            }
            if (file)
              setSnackbarMessage({
                message: "File Downloaded successfully",
                severity: "success",
              });
          })
          .catch((e) => {
            setSnackbarMessage({
              message: "Failed to download file",
              severity: "error",
            });
          }),
    });

  return {
    downloadFileFromStorage,
    isLoadingFile,
    
    videoUrl,
    isVideoLoading,
    getVideoUrl,
  };
}
