import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { FormAccordion } from "src/components/form/FormAccordion";
import { DockMaintenanceWindowFields } from "../dock-maintenance-fields/DockMaintenanceFields";

export function DockMaintenanceFieldsWrapper({
  docks,
}) {
  const maintenances = docks?.map(dock => dock?.maintenanceWindow)?.flat()

  return (
    <>
     <Card
      variant="outlined"
      sx={{
        py: "1.5rem",
        px: "1rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.75rem",
        "&.MuiCard-root": { py: 0 },
      }}
    >
      <CardContent
        sx={{
          "&.MuiCardContent-root": { p: 0 },
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormAccordion
            header={
              <Box display={"flex"} gap={1}>
                <Typography fontWeight={600}>Docks Maintenance</Typography>
              </Box>
            }
            sx={{
              // p: 0,
              "& .MuiAccordionDetails-root": { px: 0 },
              "& .MuiAccordionSummary-root": { p: 0 }
            }}
            elevation={0}
            defaultExpanded={true}
          >
            {!maintenances?.length && (
                <Typography variant="body2" color="text.secondary">
                  No maintenances added yet
                </Typography>
              )}
            <Stack gap={2}>
              {docks?.map((dock, dockIndex) => {
                return dock.maintenanceWindow?.map(
                  (dockMaintenanceWindow, maintenanceIndex) => (
                    <DockMaintenanceWindowFields
                      key={dock.dockId + maintenanceIndex}
                      dock={{ ...dock, index: dockIndex }}
                      maintenanceIndex={maintenanceIndex}
                    />
                  )
                );
              })}
            </Stack>
          </FormAccordion>
        </Box>
      </CardContent>
    </Card>
      
    </>
  );
}