import { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputLabel,
} from "@mui/material";
import { StandardDialog } from "src/components/dialog/Dialog";
import { Controller, useFormContext } from "react-hook-form";
import { ShipmentFormProvider } from "../../context/ShipmentFormContext";
import { DateTimePicker } from "@mui/x-date-pickers";
import { getDayjsDate } from "src/utils/formatTime";
import dayjs from "dayjs";

function DialogAppointmentTime(props) {  
  const [ appointmentTimePickup, setAppointmentTimePickup ] = useState(null);
  const [ appointmentTimeStop, setAppointmentTimeStop ]     = useState(null);
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    localStorage.setItem("ConfirmAppointmentDialog", event.target.checked);
    setChecked(event.target.checked);
  };

  return (
    <Box sx={{ maxWidth: "80%", margin: "auto", py: 3 }}>
      <ShipmentFormProvider
        onSave={props.onSave}
        reset={props.reset ? props.reset : false}
        useFormParams={props.useFormParams}
      >
        <StandardDialog isOpen={props.open} onClose={props.onClose}>
          <Box sx={{ width: "100%" }}>
            <DialogTitle>Set Appointment</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {`Please, set an appointment in order to book the shipment.`}
              </DialogContentText>
            </DialogContent>            
              <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <Grid container spacing={2}>
                {props.appointmentPickup &&
                <Grid item xs={6}>
                <Controller
                  control={props.control}
                  name={`metadata.appointmentTimePickup`}
                  rules={{ required: false }}
                  render={({ field: { ref, ...field } }) => (
                    <DateTimePicker
                      {...field}
                      value={getDayjsDate(field.value)}
                      onChange={(date) => {
                        const formattedDate = date ? date.toISOString() : null;
                        field.onChange(date);
                        setAppointmentTimePickup(formattedDate);
                      }}
                      timezone="UTC"
                      minDate={dayjs()}
                      inputRef={ref}
                      sx={{ width: "100%", mr: 10 }}
                      label="Appointment Time Pickup"
                    />
                  )}
                />
                </Grid>
                }
                {props.appointmentStop &&
                <Grid item xs={6}>
                <Controller
                  control={props.control}
                  name={`metadata.appointmentTimeStop`}
                  rules={{ required: false }}
                  render={({ field: { ref, ...field } }) => (
                    <DateTimePicker
                      {...field}
                      value={getDayjsDate(field.value)}
                      onChange={(date) => {
                        const formattedDate = date ? date.toISOString() : null;
                        field.onChange(date);
                        setAppointmentTimeStop(formattedDate);
                      }}
                      timezone="UTC"
                      minDate={dayjs()}
                      inputRef={ref}
                      sx={{ width: "100%", mr: 10 }}
                      label="Appointment Time Delivery"
                    />
                  )}
                />
                </Grid>                
                }     
                </Grid>         
            </Box>
            <DialogActions sx={{ pr: 6, pb: 4 }}>
              <Box sx={{ justifyContent: "flex-start", pr: "70%" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleChange}
                      name="neverSeeThisAgain"
                      color="primary"
                    />
                  }
                  label="Ignore open time"
                  sx={{ justifyContent: "flex-start", width: "100%" }}
                />
              </Box>
              <Button variant="text" onClick={props.onClose}>
                No
              </Button>
              <Button variant="contained" onClick={() => {props.onConfirm(appointmentTimePickup, appointmentTimeStop)}}>
                Yes
              </Button>
            </DialogActions>
          </Box>
        </StandardDialog>
      </ShipmentFormProvider>
    </Box>
  );
}

function isValidAppointTime() {
  
}

export default DialogAppointmentTime;
