import { Box, Typography } from "@mui/material";
import { StandardDialog } from "src/components/dialog/Dialog";
import { useAuth } from "src/hooks/useAuth";
import { ContactUsForm } from "src/pages/support/components/ContactUsForm";

export const UpdateShipmentDialog = ({ isOpen, onHandleClose, shipment }) => {
    const { session } = useAuth();


  return (
    <StandardDialog isOpen={isOpen} onClose={onHandleClose}>
      <Box width="100%">
        <Typography
          fontWeight={600}
          variant="h1"
          sx={{ "&.MuiTypography-h1": { fontSize: "1.5rem" }, mb:1 }}
        >
          {"Request shipment change"}
        </Typography>

        <Typography variant="caption" fontSize={"1.2rem"} sx={{mb:2}}>
          {`Your shipment is ${shipment.status}, you need contact us in order to update it.`}
        </Typography>

        <Box width="100%" marginTop={4}>
            <ContactUsForm
            name={session.user.firstname}
            email={session.user.email}                        
            target={shipment.primaryReference}
            handleClose={onHandleClose}
            />
        </Box>
      </Box>
    </StandardDialog>
  );
};
