import PropTypes from "prop-types";
import { forwardRef } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import logoSVG from "./logo.svg";

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  // const theme = useTheme();

  // const PRIMARY_LIGHT = theme.palette.primary.light;

  // const PRIMARY_MAIN = theme.palette.primary.main;

  // const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );
  const navigate = useNavigate();
  return (
    <Box
      component="img"
      src={logoSVG}
      sx={{
        width: {
          sm: "2.5rem",
          lg: "5rem",
          maxWidth: "25%",
        },
        padding: 1,
        cursor: !disabledLink && "pointer",
        margin: "auto",
        ...sx,
      }}
      onClick={() => !disabledLink && navigate("/")}
    />
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
