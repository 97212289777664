import {
  Box,
  Button,
  Card,
  Container,
  LinearProgress,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { DataTable } from "src/components/datagrid";
import { tenantColumns } from "./constants/tenantColumns";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import CustomToolbar from "src/components/datagrid/widgets/CustomToolbar";
import { useTenantQueries } from "./hooks/useTenantQueries";
import { useEffect, useMemo } from "react";
import { TenantDetailsDrawer } from "./components/TenantDetailsDrawer";
import { useAuth } from "src/hooks/useAuth";

export function TenantsPage() {
  const navigate = useNavigate();
  const [params, setSearchParams] = useSearchParams();
  const { tenantId } = useParams();
  const { session } = useAuth();
  const isBackOffice = session.permissions.IsBackOffice;

  const { getTenants, isLoadingTenants, tenants } = useTenantQueries();

  useEffect(() => {
    getTenants();
  }, []);

  return (
    <>
      <Helmet>
        <title>All Tenants</title>
      </Helmet>
      <Container maxWidth="false">
        <Box sx={{ display: "flex", justifyContent: "space-between", py: 1 }}>
          <Typography
            variant="h4"
            sx={{ px: 1, textAlign: "center", display: "flex" }}
          >
            All Tenants
          </Typography>
          {isBackOffice && (
            <Button href="/tenants/create">Create Tenant</Button>
          )}
        </Box>
        <section>
          <Card sx={{ p: 2 }}>
            <DataTable
              loadData={tenants || []}
              columns={tenantColumns}
              loading={isLoadingTenants}
              density="compact"
              selectedItemId={tenantId}
              customDrawerComponent={TenantDetailsDrawer}
              pagination
              autoHeight
              onDrawerClosed={() => navigate(`/tenants?${params.toString()}`)}
              onEditTapped={(value) => navigate(`/tenants/${value?._id}/edit`)}
              slots={{
                toolbar: CustomToolbar,
                loadingOverlay: LinearProgress,
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  printOptions: { disableToolbarButton: true },
                  quickFilterProps: { debounceMs: 500 },
                  loadData: tenants || [],
                },
              }}
              modelKey="tenants"
            />
          </Card>
        </section>
      </Container>
    </>
  );
}
