export const tenantColumns = [
  {
    field: 'name',
    headerName: 'Name',
    width: 240
  },
  {
    field: 'title',
    headerName: 'Title',
    width: 160

  },
  {
    field: 'code',
    headerName: 'Code',
  },
  // {
  //   field: 'notify.email'
  // }
]