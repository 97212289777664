import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Controller, FormProvider } from "react-hook-form";
import { ScheduleService } from "src/api/schedule.service";
import { WarehouseService } from "src/api/warehouse.service";
import { FormInput } from "src/components/form/FormInput";
import { useSnackbar } from "src/hooks/useSnackbar";
import { useScheduleForm } from "../hooks/useScheduleForm";
import { timezones, defaultTimezone } from "src/utils/timezones";
import { StatusChip } from "./StatusChip";
import { RecurrenceFields } from "./form/RecurrenceFields";
import { useScheduleQueries } from "../hooks/useScheduleQueries";
import { getDayjsDate } from "src/utils/formatTime";
import { useAuth } from "src/hooks/useAuth";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export function ScheduleForm({ schedule, handleRefreshCalendar, ...props }) {
  const { session } = useAuth();
  const auth = session.permissions;
  const service = new ScheduleService(session.token, session.tenant._id);
  const warehouseService = new WarehouseService(
    session.token,
    session.tenant._id
  );

  const closeDrawer = props.onVisibilityChanged;

  const [selectedFiles, setSelectedFiles] = useState([]);

  const { setSnackbarMessage } = useSnackbar();
  const weekdays = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const onSave = async (values) => {
    const start = getDayjsDate(values.start);
    const end = getDayjsDate(values.end);

    if (!start || !end) {
      return setSnackbarMessage({
        message: "Start or end date is not defined",
        severity: "warning",
      });
    };

    const data = {
      ...values,
      warehouse: values.warehouseId,
      appointmentType: values.appointmentTypeId,
      company: values.company != "NO" ? values.company : null,
      date: schedule.calendarEvent.startStr,
      start: start.utc().format("HH:mm"),
      end: end.utc().format("HH:mm"),
      dockId: values.dockId || null,
      recurring: {
        ...values?.recurring,
        daysOfWeek: values?.recurring?.daysOfWeek?.map(
          (dayOfWeek) => dayOfWeek.value
        ),
      },
    };

    if (!data.dockId) {
      return setSnackbarMessage({
        message: "Dock Id is not defined",
        severity: "warning",
      });
    };

    if (data.isRecurring && !data.recurring.daysOfWeek.length) {
      return setSnackbarMessage({
        message:
          "Please select at least one day of the week for the recurrence to occur.",
        severity: "warning",
      });
    }

    if (auth.IsWarehouseManager) {
      data.status = "approved";
    }

    let scheduleResponse = {};
    try {
      if (!data._id) {
        scheduleResponse = await service.create(data);
      } else if (data._id) {
        scheduleResponse = await service.update(data);
      }

      setSnackbarMessage({
        message: !scheduleResponse.message ?
          "Appointment successfully created" : scheduleResponse.message,
        severity: "success",
      });

      handleRefreshCalendar();
      setTimeout(closeDrawer, 300);
    } catch (error) {
      setSnackbarMessage({
        message: error.message,
        severity: "error",
      });
    }
  };

  const {
    isLoading: isSubmitLoading,
    form,
    handleSubmit,
    onSubmitHandler,
    clearErrors,
  } = useScheduleForm({
    useFormParams: props.useFormParams?.defaultValues
      ? { defaultValues: props.useFormParams?.defaultValues }
      : {},
    onSave,
    reset: props.reset,
  });

  const { companies, getCompanies, isCompaniesLoading } = useScheduleQueries();

  const [warehouses, setWarehouses] = useState([]);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [warehouse, setWarehouse] = useState();
  const [workingHours, setWorkingHours] = useState();

  const [isOpen, setIsOpen] = useState(false);
  const [tz, setTz] = useState(null);
  // const [dock, setDock] = useState();
  const [isLoading, setIsLoading] = useState();
  const [minTime, setMinTime] = useState("00:00");
  const [maxTime, setMaxTime] = useState("23:55");
  const [duration, setDuration] = useState(
    form.getValues("appointmentType.minutes")
  );

  useEffect(() => {
    async function fetch() {
      try {
        setIsLoading(true);

        const warehouseId = form.getValues('warehouseId');
        if (warehouseId) getCompanies(warehouseId);

        // Load with promise all
        const [warehouses, types] = await Promise.all([
          warehouseService.getWarehouses(),
          service.appointmentTypes(),
        ]);

        setWarehouses(warehouses);
        setDock(warehouses);
        setAppointmentTypes(types);
      } finally {
        setIsLoading(false);
      }
    }

    fetch();
  }, []);

  const setDock = () => {
    const dockId = props.useFormParams?.defaultValues?.dockId;
    const warehouse = props.useFormParams?.defaultValues?.warehouse;
    const dock = warehouse?.docks?.find((d) => d.dockId === dockId);
    form.setValue("dockId", dock?.dockId ?? "");
  };

  useEffect(() => {
    if (appointmentTypes.length && form.getValues("appointmentTypeId")) {
      const appointmentType = appointmentTypes.find(
        (type) => type._id === form.getValues("appointmentTypeId")
      );

      if (appointmentType.minutes) setDuration(appointmentType.minutes);
      else console.error("No duration for the type");
    }
  }, [appointmentTypes]);

  useEffect(() => {
    if (warehouse) {
      const eventDate = dayjs(schedule.calendarEvent.startStr);
      // Set timezone
      setTz(
        timezones.find(
          (t) => t.name === (warehouse.timezone || defaultTimezone)
        )
      );
      setMinTime("00:00");
      setMaxTime("23:55");

      if (warehouse.operationHours) {
        const holyday = warehouse.operationHours.others.find(
          (h) =>
            dayjs(h.day).format("YYYY-MM-DD") === eventDate.format("YYYY-MM-DD")
        );
        if (holyday) {
          if (holyday.closed) {
            setIsOpen(false);
            setWorkingHours(`Warehouse is closed at selected date.`);
          } else {
            setIsOpen(true);
            if (holyday.operation.start && holyday.operation.end) {
              setWorkingHours(
                `Warehouse is open from ${holyday.operation.start} to ${holyday.operation.end} at selected date`
              );
              setMinTime(holyday.operation.start);
              setMaxTime(holyday.operation.end);
            } else setWorkingHours(`Warehouse is open at selected date.`);
          }
        } else {
          const weekDay = weekdays[eventDate.day()];
          const operatingHours = warehouse.operationHours[weekDay];

          if (operatingHours.closed) {
            setIsOpen(false);
            setWorkingHours(`Warehouse is closed at selected date.`);
          } else {
            setIsOpen(true);
            if (operatingHours.start && operatingHours.end) {
              setWorkingHours(
                `Warehouse is open from ${operatingHours.start} to ${operatingHours.end} at selected date`
              );
              setMinTime(operatingHours.start);
              setMaxTime(operatingHours.end);
            } else setWorkingHours(`Warehouse is open at selected date.`);
          }
        }
      }
    }
  }, [warehouse]);

  useEffect(() => {
    if (warehouses.length && !warehouse && form.getValues("warehouseId")) {
      const warehouse = warehouses.find(
        (w) => w._id === form.getValues("warehouseId")
      );
      setWarehouse(warehouse);
    }
  }, [warehouses]);

  useEffect(() => {
    const subscription = form.watch((schedule, { name }) => {
      if (name == "warehouseId" && warehouses.length) {
        const warehouse = warehouses.find(
          (w) => w._id === form.getValues("warehouseId")
        );
        setWarehouse(warehouse);
      }
      if (name == "appointmentTypeId") {
        const appointmentType = appointmentTypes.find(
          (type) => type._id === form.getValues("appointmentTypeId")
        );
        if (appointmentType.minutes) setDuration(appointmentType.minutes);
        else console.error("No duration for the type");
      }
    });

    return () => subscription.unsubscribe();
  }, [form.watch, warehouses]);

  const handleRemoveFile = (fileIndex) => {
    const updatedFiles = selectedFiles.filter(
      (selectedFile, index) => index !== fileIndex
    );

    setSelectedFiles(updatedFiles);
  };

  const handleFileChange = (e) => {
    setSelectedFiles((prevFiles) => [...prevFiles, ...Array.from(e.target.files)]);
  };

  const handleUpload = async () => {
    try {
      setIsLoading(true);
      await service.upload(
        props.useFormParams.defaultValues._id,
        selectedFiles
      );
      setSelectedFiles([]);
      setSnackbarMessage({
        message: "Files uploaded successfully.",
        severity: "success",
      });
      handleRefreshCalendar();
    } catch (error) {
      setSnackbarMessage({
        message: "An error occurred while uploading files. Please try again.",
        severity: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <FormProvider {...form}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box
          component="form"
          onSubmit={(event) => {
            clearErrors();
            handleSubmit(onSubmitHandler)(event);
          }}
        >
          <Stack spacing={2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                py: "1rem",
              }}
            >
              <Typography variant="h4" component="h1">
                Schedule an Appointment
              </Typography>
              <StatusChip status={"pending"} />
            </Box>
            <Typography component="h6" sx={{ marginTop: "0 !important" }}>
              At <b>{schedule.calendarEvent.startStr.slice(0, 10)}</b>
            </Typography>

            <FormInput
              sx={{ flex: 1, width: "100%" }}
              name="appointmentTypeId"
              label="Type"
              select
              required
            >
              {appointmentTypes.map((type) => (
                <MenuItem key={type._id} value={type._id}>
                  {type.type} ({type.minutes} min)
                </MenuItem>
              ))}
            </FormInput>

            <Stack spacing={2}>
              <FormInput name={"reference"} label="Reference" />
              <FormInput name={"note"} multiline rows={3} label="Notes" />
            </Stack>

            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="warehouse">Warehouse *</InputLabel>
              <Controller
                control={form.control}
                name="warehouseId"
                render={({ field }) => {
                  return (
                    <Select
                      {...field}
                      label="Warehouse"
                      fullWidth
                      onChange={(e) => {
                        field.onChange(e);
                        getCompanies(e.target.value);
                      }}
                      MenuProps={{
                        PaperProps: { sx: { maxHeight: "15rem" } },
                      }}
                      children={[
                        isLoading && (
                          <MenuItem key={"LOADING"}>
                            <LoadingButton loading={isLoading}>
                              Loading Warehouses
                            </LoadingButton>
                          </MenuItem>
                        ),
                        warehouses.map((warehouse) => (
                          <MenuItem key={warehouse._id} value={warehouse._id}>
                            {warehouse.name}
                          </MenuItem>
                        )),
                      ]}
                      required
                    />
                  );
                }}
              />
            </FormControl>

            {warehouse &&
              warehouse.docks.length > 0 &&
              (auth.IsBackOffice || auth.IsWarehouseManager) && (
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="dock">Dock Id *</InputLabel>
                  <Controller
                    control={form.control}
                    name="dockId"
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          label="Dock Id"
                          fullWidth
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          MenuProps={{
                            PaperProps: { sx: { maxHeight: "15rem" } },
                          }}
                          children={warehouse.docks.map((dock) => (
                            <MenuItem
                              key={dock.dockId}
                              value={dock.dockId}
                              disabled={dock.disabled}
                            >
                              {dock.dockId} / {dock.type}{" "}
                              {dock.disabled && "(Out of Service)"}
                            </MenuItem>
                          ))}
                        />
                      );
                    }}
                  />
                </FormControl>
              )}

            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="company">Company</InputLabel>
              <Controller
                control={form.control}
                name="company"
                render={({ field }) => {
                  return (
                    <Select
                      {...field}
                      value={field.value || ""}
                      label="Company"
                      fullWidth
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      MenuProps={{
                        PaperProps: { sx: { maxHeight: "15rem" } },
                      }}
                      children={[
                        <MenuItem value={"NO"} key={"NULL"}>
                          NO COMPANY
                        </MenuItem>,
                        isCompaniesLoading && (
                          <MenuItem key={"LOADING"}>
                            <LoadingButton loading={isCompaniesLoading}>
                              Loading Companies
                            </LoadingButton>
                          </MenuItem>
                        ),
                        ...companies?.map((company) => (
                          <MenuItem key={company} value={company}>
                            {company}
                          </MenuItem>
                        )),
                        !companies && !isCompaniesLoading && (
                          <MenuItem disabled key={"EMPTY"}>
                            <strong>{"NO COMPANY AVAILABLE"}</strong>
                          </MenuItem>
                        ),
                      ]}
                    />
                  );
                }}
              />
            </FormControl>

            <Typography component="h6" {...(!isOpen && { color: "error" })}>
              {workingHours}
            </Typography>
            {warehouse && tz && (
              <Typography component="h6" sx={{ mt: 1 }}>
                Timezone: {tz.name}{" "}
                {warehouse.daylightSaving && (
                  <small style={{ color: "blue" }}>Daylight Savings ON</small>
                )}
                <br />
                <small>{tz.rawFormat}</small>
              </Typography>
            )}
            <Box sx={{ display: "flex", gap: 2 }}>
              <Controller
                control={form.control}
                name={`start`}
                sx={{ flex: 1 }}
                render={({ field }) => {
                  return (
                    <TimePicker
                      {...field}
                      {...((!warehouse || !isOpen || !duration) && {
                        disabled: true,
                      })}
                      value={getDayjsDate(field.value)}
                      onChange={(event) => {
                        field.onChange(event);
                        let end = event.add(duration, "minute");
                        form.setValue("end", end);
                      }}
                      label="Start"
                      required
                      timezone="UTC"
                      minutesStep={5}
                    />
                  );
                }}
              />
              <Controller
                control={form.control}
                name={`end`}
                sx={{ flex: 1 }}
                render={({ field }) => (
                  <TimePicker
                    {...field}
                    {...((!warehouse || !isOpen) && { disabled: true })}
                    value={getDayjsDate(field.value)}
                    label="end"
                    minutesStep={5}
                    timezone="UTC"
                    readOnly
                  />
                )}
              />
            </Box>
            <Box
              mt={2}
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <Typography variant="h6">Upload Attachments</Typography>

              {selectedFiles.length === 0 && (
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  color="secondary"
                  startIcon={<CloudUploadIcon fontSize="small" />}
                  onChange={handleFileChange}
                >
                  Upload files
                  <VisuallyHiddenInput type="file" multiple />
                </Button>
              )}
              {selectedFiles.length > 0 && (
                <>
                  <List dense>
                    {selectedFiles.map((file, index) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <InsertDriveFileIcon fontSize="12" color="primary" />
                        <ListItem
                          key={index}
                          primary="Inbox"
                          disableGutters
                          sx={{ paddingLeft: "0.3rem" }}
                          secondaryAction={
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleRemoveFile(index)}
                            >
                              <CloseIcon />
                            </IconButton>
                          }
                        >
                          <ListItemText primary={file.name} />
                        </ListItem>
                      </div>
                    ))}
                  </List>
                </>
              )}

              {selectedFiles.length > 0 && (
                <>
                  <label
                    style={{ cursor: "pointer", display: "inline-block" }}
                    role="button"
                    tabIndex="0"
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{ textDecoration: "underline" }}
                    >
                      Add more files
                      <VisuallyHiddenInput
                        type="file"
                        multiple
                        onChange={handleFileChange}
                      />
                    </Typography>
                  </label>
                  <LoadingButton
                    loading={isLoading}
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    color="secondary"
                    startIcon={<CloudSyncIcon />}
                    onClick={handleUpload}
                  >
                    Save Upload
                  </LoadingButton>
                </>
              )}
            </Box>

            {(auth.IsBackOffice || auth.IsWarehouseManager) && (
              <RecurrenceFields />
            )}

            <FormInput
              name={"status"}
              defaultValue={"pending"}
              sx={{ display: "none" }}
            />
          </Stack>
          <LoadingButton
            variant="contained"
            fullWidth
            {...(!isOpen && { disabled: true })}
            type="submit"
            loading={isSubmitLoading}
            sx={{ py: "0.8rem", mt: "1rem" }}
          >
            Schedule
          </LoadingButton>
        </Box>
      </LocalizationProvider>
    </FormProvider>
  );
}
