import config from "src/config";
import { BaseService } from "./base.service";

export class ChangeNoteService extends BaseService {
    baseUrl = `${config.apiUrl}/change-note`;

    newNote = async (data) => {
        const uri = new URL(`${this.baseUrl}/create`);
        const response = await fetch(
            uri.toString(),
            this.header({
                method: 'POST',
                body: JSON.stringify(data.changeNote)
            })
        );
        return await response.json();
    }

    getAllNotes = async () => {
        const uri = new URL(`${this.baseUrl}/all`);
        const response = await fetch(
            uri.toString(),
            this.header(),
        );

        return await response.json();
    }

    getLastChangeNote = async () => {
        const uri = new URL(`${this.baseUrl}/lastChange`);
        const response = await fetch(
            uri.toString(),
            this.header(),
        );

        return await response.json();
    }

    deleteNote = async (id) => {
        const uri = new URL(`${this.baseUrl}/remove/${id}`);
        const response = await fetch(
            uri.toString(),
            this.header({
                method: 'DELETE',
            }),
        );
        
        if (response.status !== 204) {
            return false;
        }

        return true;
    }

    updateNote = async (userId, data) => {
        data.changeNote.updated = {
            by: userId,
            at: Date.now
        }
        const uri = new URL(`${this.baseUrl}/update/${data.changeNote._id}`);
        const response = await fetch(
            uri.toString(),
            this.header({
                method: 'PUT',
                body: JSON.stringify(data.changeNote)
            })
        );
        return await response.json();
    }
}