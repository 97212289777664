import { Autocomplete, TextField } from "@mui/material"
import { Controller, useFormContext } from "react-hook-form"

export const BasicPONumberField = ({ label }) => {
    const { control } = useFormContext();

    return <Controller
        name={"poNumber"}
        control={control}
        render={({ field, fieldState: { error } }) => {
            const { onChange, value } = field;
            return <Autocomplete
                {...field}
                multiple
                limitTags={1}
                freeSolo
                fullWidth
                autoComplete
                includeInputInList
                options={[]}
                value={value ? typeof (value) === 'string' ? value.split(',') : value : []}
                onChange={(event, newValue) => {
                    onChange(newValue);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={label}
                        fullWidth
                        label={label}
                        {...error}
                    />
                )}
            />
        }}
    >

    </Controller>
}