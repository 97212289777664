import { useMutation, useQuery } from "react-query";
import { SupportService } from "src/api/support.service"
import { useAuth } from "src/hooks/useAuth";

export const useContactUsQueries = () => {
    const { session } = useAuth();
    const service = new SupportService(session.token, session.tenant?._id);

    let onContactUsSent;
    const { mutate: send, isLoading: isSending } = useMutation({
        mutationFn: async (values) => service
            .contactUs(values)
            .then(() => onContactUsSent({ status: 'success' }))
            .catch((e) => onContactUsSent({ status: 'failed', message: e.message }))
    });

    const sendContactUs = (data, { onSent }) => {
        onContactUsSent = onSent;
        send(data);
    }

    let filter;
    const { data: issues, refetch: findIssues, isRefetching: isFetchingIssues } = useQuery('issue-types', {
        queryFn: () => service.getIssuesType(filter),
        enabled: false
    });

    const getIssues = (value) => {
        filter = value;
        findIssues();
    };

    return {
        sendContactUs,
        isSending,

        issues,
        getIssues,
        isFetchingIssues
    }
}