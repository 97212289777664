import { Box } from "@mui/material";
import { createContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSnackbar } from "src/hooks/useSnackbar";
import { updateErrors } from "src/pages/shipments/hooks/useShipmentFormHook";

export const TenantFormContext = createContext(null);

export function TenantFormProvider({ children, ...props }) {
  const useFormParams = props.useFormParams;
  const onSave = props.onSave;
  const reset = props.reset || false;
  
  const { setSnackbarMessage } = useSnackbar()
  const defaultValues = useFormParams?.defaultValues;

  const form = useForm({
    ...useFormParams,
    defaultValues,
    mode: "onChange",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=> {
    console.log(useFormParams)
  },[useFormParams])

  const onSubmitHandler = async (values) => {
    setIsLoading(true);

    try {
      const data = await onSave(values);
      if (data?.errors) {
        updateErrors(data.errors, form.setError);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setSnackbarMessage({
        severity: 'error',
        message: error.message
      });
      setIsLoading(false);
    }
  };

  return (
    <TenantFormContext.Provider
      value={{
        isLoading,
        form,
        onSubmitHandler,
        // ...rest,
      }}
    >
      <FormProvider {...form}>
        <Box
          component="form"
          autoComplete="off"
          onSubmit={(event) => {
            form.clearErrors();
            form.handleSubmit(onSubmitHandler)(event);
          }}
          sx={props.sx}
        >
          { children }
        </Box>
      </FormProvider>
    </TenantFormContext.Provider>
  );
}