import { CircularProgress, Dialog, DialogContent } from "@mui/material";
import * as React from 'react';
import PropTypes from 'prop-types';
import { VideoPlayer } from "src/components/video_player/VideoPlayer";

export default function VideoPlayerDialog({ videoPath, onClose, open, isLoading }) {
    return (
        <div>
            <Dialog onClose={onClose} open={open} maxWidth={"1024px"}>
                <DialogContent sx={{
                    padding: "0px",
                    margin: "0px",
                    display: "flex",
                    justifyContent: "center",
                }}>
                    {
                        isLoading ? <CircularProgress /> : <VideoPlayer videoUrl={videoPath} />
                    }
                </DialogContent>
            </Dialog>
        </div>
    );
}

VideoPlayerDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    videoPath: PropTypes.string.isRequired,
};
