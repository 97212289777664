import { useQuery } from "react-query";
import { OrderService } from "src/api/orders.service";
import { useAuth } from "src/hooks/useAuth"

export const useOrderQueries = () => {
    const { session } = useAuth();
    const service = new OrderService(session.token, session.tenant._id);

    const { refetch: getOrders, isLoading: isOrdersLoading, data: orders } = useQuery(
        'orders',
        {
            queryFn: async () => {return service.getOrders(); },
            enabled: false,
        }
    );

    return {
        getOrders,
        isOrdersLoading,
        orders,

    }
}