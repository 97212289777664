import { ShipmentFormContext } from "../context/ShipmentFormContext";

import { useContext } from "react";

export function useShipmentFormContext() {
  const context = useContext(ShipmentFormContext);
  if (!context)
    throw new Error(
      "This hook can only be used inside the ShipmentFormProvider"
    );

  return context;
}

