import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";
import { valueGetter } from "./DetailsDrawer";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import { CheckBoxOutlineBlank, CheckBoxOutlined } from "@mui/icons-material";

export function OperationHoursDetails({ warehouse }) {
  const daysOfTheWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const operationHoursFormatted = useMemo(() => {
    const data = Object.keys(warehouse.operationHours)
      .filter((key) =>
        daysOfTheWeek.some((day) => day.toLocaleLowerCase() == key)
      )
      .map((dayOfTheWeek) => {
        const capitalizedDayOfTheWeek =
          dayOfTheWeek.at(0).toLocaleUpperCase() + dayOfTheWeek.slice(1);
        return {
          ...warehouse.operationHours[dayOfTheWeek],
          dayOfTheWeek: capitalizedDayOfTheWeek,
        };
      });

    console.log(data);

    return data;
  }, [warehouse.operationHours]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        gap: "0.75rem",
        padding: "1rem",
      }}
    >
      <DataGridPro
        columns={operationHoursColumns}
        rows={operationHoursFormatted}
        getRowId={(row) => row.dayOfTheWeek}
        hideFooter
      />
      {/* ATTACHMENTS */}
      {/* <ShipmentAttachments /> */}
    </Box>
  );
}

const operationHoursColumns = [
  {
    field: "dayOfTheWeek",
    headerName: "Day of The Week",
    flex: 1,
    renderCell: params => <Typography fontWeight={600} >{params.row.dayOfTheWeek}</Typography>
  },
  {
    field: "start",
    headerName: "From",
    flex: 1,
    align: "center",
    valueFormatter: (rowDetails) =>  !rowDetails.value ? '-' : rowDetails.value 
  },
  {
    field: "end",
    headerName: "To",
    flex: 1,
    align: "center",
    valueFormatter: (rowDetails) =>  !rowDetails.value ? '-' : rowDetails.value 
  },
  {
    field: "closed",
    headerName: "Closed",
    flex: 1,
    align: "center",
    renderCell: (params) =>
      params.row.closed ? <CheckBoxOutlined /> : <CheckBoxOutlineBlank />,
  },
];
