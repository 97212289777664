import { createContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import api from "src/api/relationships";
import { useAuth } from "src/hooks/useAuth";
export const TenantContext = createContext(null);

const USER_SESSION_LOCAL_STORAGE_KEY = "user-session";

export function TenantProvider({ children }) {
  const { session, logout } = useAuth();
  const [tenant, setTenant] = useState(session?.tenant || null);
  const [tenants, setTenants] = useState([]);

  const navigate = useNavigate()

  const {
    isLoading: isLoadingTenants,
    refetch: getTenants
  } = useQuery({
    queryKey: ["tenants"],
    queryFn: async () => {
      try {
        const result = await api.tenants(session.token);
        if(Array.isArray(result))
          setTenants(result);
        
        if(result.length == 1) // CASE SINGLE TENANT, SET IT AS CURRENT TENANT
          setTenant(result[0]);

        return result;
      } catch (exception) {
        logout();
      }
    },
    enabled: false
  })

  useEffect(() => {
    if (session?.token) {
      getTenants()
    }
  }, [session]);

  useEffect(() => {
    if (tenant && (session?.tenant?._id != tenant._id)) {
      updateSession(tenant);
      // refresh  
      navigate(0)
    }
  }, [tenant]);

  function clearTenant() {
    delete session.tenant;
    localStorage.setItem(USER_SESSION_LOCAL_STORAGE_KEY, JSON.stringify(session));
    
    navigate(0)
  }

  function updateSession(tenant) {
    session.tenant = tenant;
    localStorage.setItem(
      USER_SESSION_LOCAL_STORAGE_KEY,
      JSON.stringify(session)
    );
  }

  return (
    <TenantContext.Provider
      value={{
        tenant,
        setTenant,
        tenants,
        isLoadingTenants,
        clearTenant
      }}
    >
      {children}
    </TenantContext.Provider>
  );
}
