import { createContext, useContext, useMemo, useState } from "react";

const FormStepperContext = createContext();

export function FormStepperProvider({ children, steps: stepList }) {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const isLastStep = useMemo(() => currentStepIndex === stepList.length - 1, [
    currentStepIndex,
    stepList.length
  ]);

  const handleNext = (onLastStepTapped) => {
    if (isLastStep) return onLastStepTapped && onLastStepTapped();

    setCurrentStepIndex((prevIndex) => prevIndex + 1);
  };

  const isFirstStep = useMemo(() => currentStepIndex === 0, [
    currentStepIndex
  ]);

  const handlePrevious = () => {
    if (isFirstStep) return;

    setCurrentStepIndex((prevIndex) => prevIndex - 1);
  };

  const handleReset = () => {
    console.log('rested');
    setCurrentStepIndex(0);
  };

  return (
    <FormStepperContext.Provider
      value={{
        isLastStep,
        handleNext,
        isFirstStep,
        handlePrevious,
        currentStepIndex,
        setCurrentStepIndex,
        stepList,
        handleReset
      }}
    >
      {children}
    </FormStepperContext.Provider>
  );
}

export function useFormSteps() {
  const context = useContext(FormStepperContext);

  if (!context) {
    throw new Error("useFormSteps must be used within a FormStepperProvider");
  }

  return context;
}
