import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSnackbar } from "src/hooks/useSnackbar";

export function useShipmentCarrierFormHook(props) {
  const useFormParams = props?.useFormParams;
  const defaultValues = useFormParams?.defaultValues;

  const { setSnackbarMessage } = useSnackbar();

  const form = useForm({
    ...useFormParams,
    defaultValues,
    mode: "onChange",
  });

  const {
    formState: { errors, isSubmitSuccessful },
    reset,
    handleSubmit,
    setError,
    clearErrors,
  } = form;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isSubmitSuccessful) {
      props.reset && reset();
    }
  }, [isSubmitSuccessful, reset, errors]);

  const onSubmitHandler = async (values) => {
    setIsLoading(true);

    try {
      // MAP ITEMS
      const candidatesCarriers = {
        ...values,
      };

      const mc = Number(candidatesCarriers.mc)

      props.candidatesCarriers?.carriers?.some((carrier) => {
        if (carrier.mc === mc) {
          throw Error(`This MC# already exists on the Rate: ${carrier.rate}`);
        }
      });

      if (!candidatesCarriers.mc || candidatesCarriers.mc === null)
        throw Error("Select at least one MC#");
      else if (!candidatesCarriers.rate || candidatesCarriers.rate === "")
        throw Error("Select at least one Rate");
      else if (!candidatesCarriers.eta || candidatesCarriers.eta === null)
        throw Error("Select at least one ETA");
      else if (!candidatesCarriers.emptyOn || candidatesCarriers.emptyOn === "")
        throw Error("Select at least one Empty On");

      const data = await props.onSave(candidatesCarriers);
      if (data?.errors) {
        updateErrors(data.errors, setError);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setSnackbarMessage({
        severity: "error",
        message: error.message,
      });
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    form,
    handleSubmit,
    onSubmitHandler,
    clearErrors,
  };
}

export function updateErrors(errors, setError) {
  errors.forEach((error) => {
    const param = error.param.replace("[", ".").replace("]", "");
    setError(param, {
      type: "manual",
      message: error.msg,
    });
  });
}
