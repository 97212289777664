export const toShipmentUOM = (orderUOM) => {
    const options = {
        'BG': 'Bags',
        'BX': 'Boxes',
        'CTN': 'Cartons',
        'EA': 'Each',
        'FT': 'Feet',
        'PK': 'Packet',
        'PAL': 'Pail(s)',
        'PL': 'Pallets',
        'PC': 'Pieces',
        'RL': 'Reels',
        'TB': 'Tub',  
    };

    const value = options[orderUOM];
    return value ?? 'EA';
}