import { Navigate } from 'react-router-dom';
import { useAuth } from 'src/hooks/useAuth';

function PublicRoute({ children }) {
  
  const { isLogged } = useAuth();

  return (!isLogged)
    ? children
    : <Navigate to={"/"} />
}

export default PublicRoute