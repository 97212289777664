import { useMemo } from "react";
import { useQuery } from "react-query";
import { UserService } from "src/api/user.service";
import { useAuth } from "src/hooks/useAuth";

export function useScheduleQueries() {
  const { session } = useAuth();
  const service = new UserService(session.token, session.tenant._id)

  // const service = new ScheduleService(session.token);
  let warehouseId = "";
  const {
    data: companiesReponse,
    isLoading: isCompaniesLoading,
    refetch: refetchCompanies,
  } = useQuery(["companies"], {
    queryFn: () => service.getUsersCompanies(warehouseId),
    enabled: false,
  });

  const getCompanies = (value) => {
    warehouseId = value;
    refetchCompanies();
  }

  const companies = useMemo(() => companiesReponse ?? [], [companiesReponse]);

  return {
    companies,
    isCompaniesLoading,
    getCompanies,
  };
}
