import { Clear } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  MenuItem,
  Skeleton,
  Stack,
  Typography
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { FormInput } from "src/components/form/FormInput";
import { FormInputMask } from "src/components/form/FormInputMask";
import { useAuth } from "src/hooks/useAuth";
import { InputStateField } from "src/pages/location/components/StateField";
import { countries } from "src/pages/shipments/constants/countries";
import { timezones } from "src/utils/timezones";
import { TenantFormProvider } from "../context/TenantFormProvider";
import { ModuleFields } from "./form/ModuleFields";
import { IntegrationFields } from "./form/IntegrationFields";

export function TenantForm(props) {
  const { session } = useAuth();

  const isBackOffice = session.permissions.IsBackOffice;

  const type = props.type ? props.type : "create";
  const titleType = type[0].toUpperCase() + type.slice(1);
  const title = type == "create" ? "Create New Tenant" : `${titleType} Tenant`;

  const navigate = useNavigate();
  const [isInitialized, setInitialized] = useState(props.initialized ?? true);
  useEffect(() => {
    console.log(props.useFormParams);
  }, [props.useFormParams]);

  if (props.isLoading) {
    return (
      <Skeleton
        variant="rounded"
        animation="wave"
        sx={{ maxWidth: "100%", height: "80vh", opacity: 0.6 }}
      ></Skeleton>
    );
  }

  return (
    <Box sx={{ maxWidth: "80%", margin: "auto", py: 3 }}>
      <TenantFormProvider
        onSave={props.onSave}
        reset={props.reset ? props.reset : false}
        useFormParams={props.useFormParams}
      >
        <Box
          sx={{
            width: "100%",
            display: {
              xs: "block",
              sm: "flex",
            },
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", pb: 2 }}>
            <Typography variant="h4" component="h1">
              {title}
            </Typography>
          </Box>
        </Box>
        <Stack gap={2}>
          <Card
            variant="outlined"
            sx={{
              py: "1.5rem",
              px: "1rem",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "0.75rem",
            }}
          >
            <CardHeader
              title={"Basic Info"}
              sx={{ "&.MuiCardHeader-root": { p: 0 } }}
            />
            <CardContent
              sx={{
                "&.MuiCardContent-root": { p: 0, py: "1rem" },
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "0.75rem",
              }}
            >
              <Grid container flex={1} columnGap={1}>
                <Grid itemxs={3} flex={1}>
                  <FormInput name="name" label={"Name"} fullWidth />
                </Grid>
                <Grid itemxs={3} flex={1}>
                  <FormInput name="title" label={"Title"} fullWidth />
                </Grid>
                <Grid itemxs={3} flex={1}>
                  <FormInput name="code" label={"code"} fullWidth />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <BillingAddressFields />

          <ContactsFields />

          <ModuleFields/>
          <IntegrationFields/>
        </Stack>

        <Stack gap={1}>
          <LoadingButton
            variant="contained"
            fullWidth
            type="submit"
            loading={
              props.isSubmissionLoading ? props.isSubmissionLoading : false
            }
            sx={{ py: "0.8rem", mt: "2rem" }}
          >
            {type} Tenant
          </LoadingButton>
        </Stack>
      </TenantFormProvider>
    </Box>
  );
}

function ContactsFields() {
  const {
    fields: fieldsContact,
    append: appendContact,
    remove: removeContact,
  } = useFieldArray({
    name: "billingInfo.contacts",
  });

  const { getValues, watch } = useFormContext();

  const countrie = useMemo(() => {
    const country = getValues("ctry");
    if (country === "CA" || country === "MX" || country === "USA")
      return country;
    else return "";
  }, [watch("ctry")]);

  return (
    <Card
      variant="outlined"
      sx={{
        py: "1.5rem",
        px: "1rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.75rem",
      }}
    >
      <CardHeader
        title={"Contacts"}
        sx={{ "&.MuiCardHeader-root": { p: 0 } }}
      />
      <Box>
        {fieldsContact.map((contact, index) => {
          return (
            <Box key={index} sx={{ display: "flex", gap: 1 }}>
              <FormInput
                name={`billingInfo.contacts[${index}].name`}
                label="Name"
                required
                type="text"
                sx={{ flexGrow: 1 }}
              />
              <FormInputMask
                name={`billingInfo.contacts[${index}].phone`}
                required
                selectCountry={countrie}
                sx={{ flexGrow: 1 }}
              />
              <FormInput
                name={`billingInfo.contacts[${index}].email`}
                label="Email"
                type="email"
                sx={{ flexGrow: 1 }}
              />
              <Button
                sx={{ ml: 0, mr: 0 }}
                onClick={() => removeContact(index)}
              >
                <Clear />
              </Button>
            </Box>
          );
        })}
      </Box>
      <Button
        size="small"
        variant="text"
        align="left"
        onClick={() => appendContact({ name: "", phone: "", email: "" })}
      >
        + Add Contact
      </Button>
    </Card>
  );
}

function BillingAddressFields() {
  const { session } = useAuth();

  return (
    <Card
      variant="outlined"
      sx={{
        py: "1.5rem",
        px: "1rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.75rem",
      }}
    >
      <CardHeader
        title={"Billing Address"}
        sx={{ "&.MuiCardHeader-root": { p: 0 } }}
      />
      <CardContent
        sx={{
          "&.MuiCardContent-root": { p: 0, py: "1rem" },
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "0.75rem",
        }}
      >
        <Stack spacing={2}>
          <FormInput
            name={"billingInfo.addr1"}
            label="Address 1"
            required
            type="text"
            sx={{ flexGrow: 1 }}
          />
          <FormInput
            name={"addr2"}
            label="Address 2"
            type="text"
            sx={{ flexGrow: 1 }}
          />
          <Box sx={{ display: "flex", gap: 2 }}>
            <FormInput
              name={"billingInfo.city"}
              label="City"
              type="text"
              required
              sx={{ flexGrow: 1 }}
            />
            <InputStateField prefix="billingInfo" />
          </Box>
          <Box sx={{ display: "flex", gap: 2 }}>
            <FormInput
              name={"billingInfo.zip"}
              label="Zip Code"
              required
              sx={{ flexGrow: 1 }}
            />
            <FormInput
              name={`billingInfo.ctry`}
              label="Country"
              select
              required
              sx={{ flexGrow: 1 }}
              defaultValue={countries[0].acronym}
            >
              {countries.map((ctry) => {
                return (
                  <MenuItem key={ctry.acronym} value={ctry.acronym}>
                    {ctry.name}
                  </MenuItem>
                );
              })}
            </FormInput>
          </Box>

          {/* TIMEZONE */}
          <FormInput
            sx={{ maxWidth: "100%" }}
            name="billingInfo.timezone"
            label="Timezone"
            defaultValue={
              timezones.find((t) => t.name == "America/Los_Angeles").name
            }
            select
            required
            disabled={!session.permissions.IsAdmin}
          >
            {timezones.map((tz) => {
              return (
                <MenuItem key={tz.name} value={tz.name}>
                  {tz.rawFormat}
                </MenuItem>
              );
            })}
          </FormInput>
        </Stack>
      </CardContent>
    </Card>
  );
}
