import { MenuItem } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormInput } from "src/components/form/FormInput";
import { canadaStates } from "src/pages/warehouses/constants/canadaStates";
import { mexicoStates } from "src/pages/warehouses/constants/mexicoState";
import { usaStates } from "src/pages/warehouses/constants/usaStates";

export const InputStateField = ({ prefix = '' }) => {
    const { watch, getValues, setValue } = useFormContext();

    const stateKey = [prefix,'state'].filter(value=> value).join('.');
    const countryKey = [prefix,'ctry'].filter(value=> value).join('.');

    const states = useMemo(() => {
        const country = getValues(countryKey);
        if (country === 'CA') return canadaStates;
        else if (country === 'MX') return mexicoStates;
        else return usaStates;
    }, [watch(countryKey)]);

    useEffect(() => {
        if (!states.some(state => state.acronym === getValues(stateKey))) setValue(stateKey, '');
    }, [states]);

    return (
        <FormInput
            sx={{ flexGrow: 1 }}
            name={stateKey}
            label="State"
            select
            required
        >
            {states.map((state) => {
                return (
                    <MenuItem
                        key={state.acronym}
                        value={state.acronym}
                    >
                        {state.name}
                    </MenuItem>
                );
            })}
        </FormInput>
    );
}