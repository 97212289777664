import { useState } from "react";

const { Grid, Card, Box, Typography, LinearProgress } = require("@mui/material");
const { useStorageQueries } = require("../hooks/useQueries");

export function ArticleCard({ links }) {
    const [isDownloadActive, setIsDownloadActive] = useState(null);

    const { downloadFileFromStorage, isLoadingFile } = useStorageQueries();

    function handleOpenFile(fileName) {
        downloadFileFromStorage({ fileName, action: "open" });
    }

    return <Grid container spacing={3} marginBottom={"42px"} marginTop={"2px"}>
        {
            links.map((link, index) => {
                const value = index === isDownloadActive;
                return <Grid item key={link.title}>
                    {
                        <Card
                            elevation={10}
                            sx={{
                                borderRadius: "12px",
                                padding: "24px",
                                height: "260px",
                                width: "260px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                            }}>
                            <Box>
                                <Typography fontWeight={"bold"} fontSize={"24px"}>{link.title}</Typography>
                                <Typography marginTop={"4px"} fontSize={"14px"}>{link.description}</Typography>
                            </Box>
                            {
                                isLoadingFile && value ? <LinearProgress /> : <Box
                                    onClick={() => {
                                        setIsDownloadActive(index);
                                        handleOpenFile(link.fileName)
                                    }}
                                    alignContent={"center"}
                                    sx={{
                                        backgroundColor: "black",
                                        color: "white",
                                        fontSize: "12px",
                                        textAlign: "center",
                                        cursor: "pointer",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        padding: "8px",
                                        borderRadius: "8px",
                                        justifyContent: "center",
                                        width: "132px",
                                        ":hover": {
                                            backgroundColor: "darkgrey",
                                            transition: "0.4s",
                                        }
                                    }}>
                                    READ MORE
                                </Box>
                            }
                        </Card>
                    }
                </Grid>;
            })
        }
    </Grid>
}