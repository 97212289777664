import config from "src/config.js";
import { BaseService } from "./base.service";

const baseUrl = config.apiUrl;
const prefix = "/warehouse";

const routeList = prefix;
const routeDetail = prefix + "/:warehouseId";
const routeCreate = prefix;
const routeUpdate = prefix + "/:warehouseId";
const routeDelete = prefix + "/:warehouseId";

export class WarehouseService extends BaseService {
  getWarehouses = async (filter) => {
    const uri = new URL(`${baseUrl}${routeList}`);
    if (filter) uri.searchParams.append("filter", filter);

    const response = await fetch(uri.toString(), this.header());
    return await response.json();
  }

  createWarehouse = async (data) => {
    const uri = new URL(config.apiUrl + routeCreate);

    const response = await fetch(
      uri.toString(), 
      this.header({
        method: 'POST',
        body: JSON.stringify(data)
      })
    );

    return await response.json();
  }

  updateWarehouse = async (warehouseId, data) => {
    const uri = new URL(config.apiUrl + routeUpdate.replace(':warehouseId', warehouseId));

    const response = await fetch(
      uri.toString(), 
      this.header({
        method: 'PUT',
        body: JSON.stringify(data)
      })
    );

    return await response.json();
  }

  warehouseDetails = async (warehouseId) => {
    const uri = new URL(baseUrl + routeDetail.replace(':warehouseId', warehouseId));

    const response = await fetch(uri.toString(), this.header());
    return await response.json();
  }

  deleteWarehouse = async (warehouseId) => {
    const uri = new URL(baseUrl + routeDelete.replace(':warehouseId', warehouseId));

    const response = await fetch(uri.toString(), this.header({ 
      method: 'DELETE'
    }));
  
    return await response.json();
  }
}

// const headers = (token) => {
//   return {
//     headers: {
//       "Content-Type": "application/json",
//       "Authorization": `Bearer ${token}`,
//     }
//   }
// }

// export const apiList = async (token) => {
//   const response = await fetch(baseUrl + routeList, headers(token));
//   return await response.json();
// };

// export const apiDetail = async (token, id) => {
//   const response = await fetch(baseUrl + routeDetail.replace(':warehouseId', id), headers(token));
//   return await response.json();
// };

// export const apiCreate = async (token, data) => {
//   const response = await fetch(baseUrl + routeCreate, {
//     method: 'POST',
//     ...headers(token),
//     body: JSON.stringify(data),
//   });

//   return await response.json();
// };

// export const apiUpdate = async (token, warehouseId, data) => {
//   const response = await fetch(baseUrl + routeUpdate.replace(':warehouseId', warehouseId), {
//     method: 'PUT',
//     ...headers(token),
//     body: JSON.stringify(data),
//   });

//   return await response.json();
// };

// export const apiDelete = async (token, warehouseId) => {
//   const response = await fetch(baseUrl + routeDelete.replace(':warehouseId', warehouseId), {
//     method: 'DELETE',
//     ...headers(token)
//   });

//   return await response.json();
// };

