import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/material";

export default function DeletionDialog({
  open,
  handleClose,
  handleDeleteEvent,
  handleDeleteGroup,
}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="deletion-dialog-title"
      aria-describedby="deletion-dialog-description"
      sx={{ minWidth: "15rem" }}
    >
      <DialogTitle id="deletion-dialog-title">
       Do you want to delete the event or the serie?
      </DialogTitle>
      <DialogActions sx={{ display: 'flex', flexDirection: 'column', gap: 1, pb: '1rem' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '80%',  }}>
          <Button
            onClick={() => {
              handleDeleteEvent();
              handleClose();
            }}
            sx={{ display: 'flex', width: '50%',  }}
            autoFocus
            // variant="outlined"
          >
            Delete Event
          </Button>
          <Button
            onClick={() => {
              handleDeleteGroup();
              handleClose();
            }}
            sx={{ display: 'flex', width: '50%',  }}
            autoFocus
            // variant="outlined"
          >
            Delete Serie
          </Button>
        </Box>
        <Button 
          onClick={handleClose} 
          variant="outlined" 
          color="error"
          sx={{ display: 'flex', width: "80%"  }}
        >Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
