import {
  Location
} from "./location";
import { Service } from "./service";

const cityStateZipREGEX = /^(.*),\s*([A-Z]{2})\s*([\d-]+)$/;
export class Order {
  static responseToShipment(orderResponse, ignoreShipmentNumber = false) {
    const [, city, state, zip] =
    orderResponse.CITY_ST_ZIP.match(cityStateZipREGEX);

    function getStop() {
      if (orderResponse.stop) {
        return {
          ...Location.toShipment(orderResponse.stop, "stop"),
        };
      } else {
        return {
          stops: [{
            destName: orderResponse.SHIP_TO_LOCATION ?? "",
            destAddr1: orderResponse.ADDRESS1 ?? "",
            destAddr2: orderResponse.ADDRESS2 ?? "",
            destCity: city,
            destState: state,
            destZip: zip,
          }, ],
        };
      }
    }

    function getPickup() {
      if (!orderResponse.pickup) {
        return {
          pickups: [{
            originName: orderResponse.VENDOR_NM ?? "",
          }],
        };
      }

      const isArray = Array.isArray(orderResponse.pickup);
      if (isArray && orderResponse.pickup.length) {
        return {
          ...Location.toShipment(orderResponse.pickup[0], "pickup"),
        };
      }

      return {
        ...Location.toShipment(orderResponse.pickup, "pickup"),
      };
    }

    const shipment = {
      shipmentNumber: !ignoreShipmentNumber ? [orderResponse.SHIPMENT_NUM ?? ""] : [],
      ...getPickup(),
      ...getStop(),
    };

    return {
      ...shipment,
      services: {
        ...Service.fromLocation(shipment.pickups[0], 'pickup'),
        ...Service.fromLocation(shipment.stops[0], 'stop'),
      },
    };
  }

  static getPONumber(order) {
    return order
    ? `${order?.PO_NUM}${order?.RELEASE_NUM != undefined ? "-" + order.RELEASE_NUM : ""
    }`
    : "";
  }
}