import PropTypes from "prop-types";
import { NavLink as RouterLink } from "react-router-dom";
import { Box, List, ListItemText } from "@mui/material";
import { StyledNavItem, StyledNavItemIcon } from "./styles";
import { useAuth } from "src/hooks/useAuth";

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  const { session } = useAuth();
  
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1, px: 0, }}>
        {data
          .filter(item => item.showWhen ? item.showWhen(session) : true)
          .map((item) => <NavItem key={item.title} item={item} />)}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item, theme }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        fontWeight: "bold",
        letterSpacing: 0.25,
        borderLeft: "2px solid white",
        "&.active, &.active&:hover": {
          borderLeft: "2px solid #637381",
          backgroundColor: "rgb(248 252 255)",
        },
        "&:hover": {
          backgroundColor: "transparent",
        },
        "&.active, &:hover": {
          color: "#454F5B",
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}
