import { LoadingButton } from "@mui/lab";
import {
  Alert,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import useLoginForm from "./useLoginForm";
import { Link } from "react-router-dom";
import { useState } from "react";

export default function LoginForm() {
  const {
    loginError,
    handleSubmit,
    formErrors,
    showPassword,
    setShowPassword,
    onSubmitLoginForm,
    isLoginLoading,
    emailValidator,
    passwordValidator
  } = useLoginForm();

  const [isHovered, setIsHovered] = useState(false);

  return (
    <form onSubmit={handleSubmit(onSubmitLoginForm)}>
      <Stack spacing={3}>
        <TextField
          {...emailValidator}
          name="username"
          label="Email address"
          error={Boolean(formErrors.username)}
          helperText={formErrors.username?.message}
        />

        <TextField
          {...passwordValidator}
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          error={Boolean(formErrors.password)}
          helperText={formErrors.password?.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end">
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: 2, mb: 4 }}>
        <Link style={{
          textDecoration: isHovered ? 'underline' : 'none',
          color: "#505050",
          fontSize: "16px",
        }}
          to="/forgot-password"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}>
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        disabled={isLoginLoading}>
        Login
      </LoadingButton>

      {loginError && (
        <Alert severity="error" sx={{ mt: 1 }}>
          {loginError}
        </Alert>
      )}
    </form>
  );
}
