import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContent, DialogContentText } from "@mui/material";

export default function DeletionDialog({ open, handleClose, handleDelete }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="deletion-dialog-title"
      aria-describedby="deletion-dialog-description"
      sx={{ minWidth: "20%" }}
      fullWidth maxWidth="sm"
    >
      <DialogTitle id="deletion-dialog-title">
          Delete Shipment
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure to delete this shipment?
        </DialogContentText>
        <DialogContentText sx={{ mt: '0.25rem' }}>
          This action cannot be undone
        </DialogContentText>
        <DialogActions
          sx={{ padding: 0, mt: '1rem' }}
        >
          <Button
            onClick={() => {
              handleDelete();
              setTimeout(handleClose, 300);
            }}
            sx={{ flex: 1 }}
            autoFocus
            variant="outlined"
            color="error"
          >
            Confirm Deletion
          </Button>
          <Button
            onClick={handleClose}

            sx={{ flex: 1 }}
          >
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>

    </Dialog>
  );
}
