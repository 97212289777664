export const weightUnits = [
  {
    label: "Pound",
    abreviation: "(LBS)",
  },
  {
    label: "Kilogram",
    abreviation: "(kg)",
  }
];

export const freightClasses = [
  "250",
  "150",
  "125",
  "175",
  "85",
  "110",
  "300",
  "100",
  "92.5",
  "70",
  "50",
  "60",
  "55",
  "65",
  "77.5",
  "200",
  "400",
  "500",
];

export const palletCountLabel = [
  "Bag",
  "Bottle",
  "Box",
  "Carton",
  "Crate",
  "Drum",
  "Each",
  "Feet",
  "Form",
  "Jar",
  "Packet",
  "Pail",
  "Pallet",
  "Piece",
  "Reel",
  "Tote",
  "Tub"
];

export const palletCountUnits = [
  "Bags",
  "Bottle",
  "Boxes",
  "Cartons",
  "Crates",
  "Drum",
  "Each",
  "Feet",
  "Forms",
  "Jars",
  "Packet",
  "Pail(s)",
  "Pallets",
  "Pieces",
  "Reels",
  "Totes",
  "Tub",
  "Pairs",
  "Dozens",
];

export const dimensionsUnits = [
  {
    label: 'Inch',
    abreviation: '(In)'
  },
  {
    label: 'Foot',
    abreviation: '(Ft)'
  },
  {
    label: 'Centimeters',
    abreviation: '(cm)'
  },

]