import {
  useEffect,
  useState
} from "react";
import {
  useFieldArray,
  useForm
} from "react-hook-form";
import dayjs from "dayjs";
import {
  Shipment
} from "src/models/shipment";
import {
  useSnackbar
} from "src/hooks/useSnackbar";
import { useAuth } from "src/hooks/useAuth";
import { Order } from "src/models/order";

export function useShipmentFormHook(props) {
  const useFormParams = props?.useFormParams;
  const defaultValues = useFormParams?.defaultValues;
  const { session } = useAuth()
  const [orders, setOrders] = useState({});
  const isCox = session?.tenant?.code === "COX";
  const isTrillion =  session?.tenant?.code === "TRI";

  const {
    setSnackbarMessage
  } = useSnackbar();

  const form = useForm({
    ...useFormParams,
    defaultValues,
    mode: "onChange",
  });

  const {
    formState: {
      errors,
      isSubmitSuccessful
    },
    reset,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    getValues,
  } = form;

  const addOrder = (order) => {
    if (!order) throw new Error("We couldn't find the PO");

    const poNumber = `${order.PO_NUM}-${order.RELEASE_NUM ?? 0}`;
    if (Object.keys(orders).includes(poNumber)) throw new Error("This PO is already selected.");

    setOrders((prev) => ({
      ...prev,
      [poNumber]: order
    }));

    const shipment = Order.responseToShipment(order);
    mapFields(shipment);    
  }

  const removeOrder = (poNumber) => {
    const { [poNumber]: _, ...values } = orders;
    setOrders(values);    
  }

  const getOrderByPONumber = (poNumber) => {
    return orders[poNumber];
  }

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isSubmitSuccessful) {
      props.reset && reset();
    }
  }, [isSubmitSuccessful, reset, errors]);

  const onSubmitHandler = async (values) => {
    setIsLoading(true);

    try {
      // MAP ITEMS
      const shipmentData = Shipment.toJson({
        ...values,
        isCox,
        isTrillion,
      });

      const data = await props.onSave(shipmentData);
      if (data?.errors) {
        updateErrors(data.errors, setError);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setSnackbarMessage({
        severity: 'error',
        message: error.message
      });
      setIsLoading(false);
    }
  };

  const {
    fields: itemsFields,
    append: appendItem,
    remove: removeItemFromIndex,
    update: updateItem,
  } = useFieldArray({
    control: form.control,
    name: "items",
  });

  const {
    fields: poNumberFields,
    append: appendPoNumber,
    remove: removePoNumberFromIndex,
  } = useFieldArray({
    control: form.control,
    name: "poNumber",
  });

  const {
    fields: orderNumberFields,
    append: appendOrderNumber,
    remove: removeOrderNumberFromIndex,
  } = useFieldArray({
    control: form.control,
    name: "ordersNumbers",
  });
  const {
    fields: shipmentNumbers,
    append: appendShipmentNumber,
    remove: removeShipmentFromIndex,
  } = useFieldArray({
    control: form.control,
    name: "shipmentNumber",
  });

  const mapFields = (params, prefix = "") => {
    if (!params) return;
    Object.entries(params).forEach(([key, value]) => {
      if (!value || value == "transfer") return;
      switch (key) {
        case "items":
          value.forEach((item) => {
            appendValidatedItem(item);
          });
          return;
        case "shipmentNumber":
          if (value?.toString().includes(",")) {
            const numbers = value.split(",");
            numbers.forEach((shipmentNumber) => {
              appendShipmentNumber(shipmentNumber);
            });
          } else {
            appendShipmentNumber(value);
          }

          return;
        default:
          break;
      }

      if (typeof value === "object")
        return mapFields(value, `${prefix + key}.`);

      if (key.match("poNumber")) {
        const poNumbers = value.split(",");
        return mapFields(poNumbers, `${prefix + key}.`);
      }

      if (
        key.match("targetDeliveryEarly") ||
        key.match("targetDeliveryLate") ||
        key.match("targetShipEarly") ||
        key.match("targetShipLate")
      ) {
        return form.setValue(prefix + key, dayjs(value));
      }
      console.log(prefix, key, value);
      form.setValue(prefix + key, value ?? false);
    });
  };

  const appendValidatedItem = (item) => {
    const items = form.getValues("items");

    let existingItem = items?.some((itemField) => {
      if (!itemField) return false;

      const isDuplicatedItemId =
        itemField.itemId && item?.itemId && itemField.itemId == item?.itemId;
      const isDuplicatedDescription =
        itemField.description &&
        item?.description &&
        itemField.description == item?.description;

      return isDuplicatedItemId && isDuplicatedDescription;
    });

    if (existingItem) return;
    else appendItem(item);
  };

  return {
    isLoading,
    form,
    handleSubmit,
    onSubmitHandler,
    clearErrors,
    items: {
      fields: itemsFields,
      remove: removeItemFromIndex,
      append: appendItem,
      update: updateItem,
    },
    poNumbers: {
      fields: poNumberFields,
      remove: removePoNumberFromIndex,
      append: appendPoNumber,
    },
    ordersNumbers: {
      fields: orderNumberFields,
      append: appendOrderNumber,
      remove: removeOrderNumberFromIndex,
    },

    mapFields,
    addOrder,
    removeOrder,
    getOrderByPONumber,
  };
}

export function updateErrors(errors, setError) {
  errors.forEach((error) => {
    const param = error.param.replace("[", ".").replace("]", "");
    setError(param, {
      type: "manual",
      message: error.msg
    });
  });
}