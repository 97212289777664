import { Box, Paper, Typography } from "@mui/material";
import { useSnackbar } from "src/hooks/useSnackbar";
import { Helmet } from "react-helmet-async";
import { WarehouseService } from "src/api/warehouse.service";
import { WarehouseForm } from "./WarehouseForm";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { useAuth } from "src/hooks/useAuth";

export function WarehousesPageCreate() {
    const { session } = useAuth();
    const { setSnackbarMessage } = useSnackbar();
    const navigate = useNavigate();

    const service = new WarehouseService(session.token, session.tenant._id);

    const { 
        mutate: createWarehouse, 
        isLoading: isCreationLoading 
    } = useMutation({
        mutationFn: async (values) => handleSave(values)
    })

    /**
     * Handles the save operation for creating a warehouse.
     * @param {Object} values - The data to be saved.
     * @returns {Promise<void>} - A promise that resolves when the save operation is complete.
     */
    const handleSave = async (values) => {
        await service.createWarehouse(values).then((data) => {
            if (data.errors) {
                setSnackbarMessage({
                    message: (<Box> Error submitting warehouse.</Box>),
                    severity: "error",
                });
            } else {
                setSnackbarMessage({
                    message: `Warehouse ${data.name} successfully created.`,
                    severity: "success",
                });
                navigate(`/warehouses/${data._id}`);
            }
        })
    };

    return (
        <Paper sx={{ maxWidth: { lg: "1000px", xs: "90%" }, margin: "auto", py: 3, }} elevation={3}>
            <Helmet>
                <title>Create a New Warehouse</title>
            </Helmet>
            <Box sx={{ maxWidth: "80%", margin: "auto" }}>
                <Typography variant="h4" component="h1" sx={{ mb: "2rem" }}>
                    Create New Warehouse
                </Typography>
                <WarehouseForm 
                    onSave={createWarehouse} 
                    isSubmissionLoading={isCreationLoading}
                />
            </Box>
        </Paper>
    );
}
