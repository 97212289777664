import { Backspace } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Tooltip,
  Typography,
} from "@mui/material";
import { useFieldArray } from "react-hook-form";
import { FormAccordion } from "src/components/form/FormAccordion";
import { useShipmentFormContext } from "src/pages/shipments/hooks/useForm";
import { ShipmentItemForm } from "./ItemFields";
import { usePackageForm } from "src/pages/shipments/context/PackageContext";

export function ItemsFieldsWrapper() {
  const { form } = useShipmentFormContext();
  const { packageIndex } = usePackageForm();
  const {
    fields,
    append,
    remove: removeItem,
  } = useFieldArray({
    control: form.control,
    name: `items.${packageIndex}.subItems`,
  });

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", py: 1 }}>
        <Typography fontWeight={600} fontSize={'1.2rem'}>Items</Typography>
        <Button
          type="button"
          size="small"
          onClick={() => append({})}
          sx={{ minWidth: "8rem", height: "2.5rem" }}
        >
          Add Item
        </Button>
      </Box>
      {!fields.length && (
        <Typography fontSize={"0.9rem"} color={"GrayText"}>
          No Items
        </Typography>
      )}
      {fields.map((item, index) => (
        <FormAccordion
          sx={{ background: "transparent" }}
          header={
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography>
                {`Item  ${index + 1} - ${
                  item.itemId ? item.itemId : "New Item"
                }`}
              </Typography>
              <Tooltip title="Remove Item">
                <Button onClick={() => removeItem(index)}>
                  <Backspace />
                </Button>
              </Tooltip>
            </Box>
          }
          key={`${item.itemId}${index}`}
        >
          <ShipmentItemForm
            key={`${item.itemId}${index}`}
            itemIndex={index}
            packageIndex={packageIndex}
          />
        </FormAccordion>
      ))}
    </Box>
  );
}
