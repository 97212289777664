export const Actions = [
  {
    name: "Approve / Deny Shipments",
    action: "approve_deny_shipments",
  },
  {
    name: "Book Shipments",
    action: "book_shipments",
  },
  {
    name: "Set Pending Shipments",
    action: "set_pending_shipments",
  }
];

export const AuthActions = (userSession) => {
    const actions = userSession.user?.actions ?? [];
    const actionNames = actions.map(action => action.name);
  
    return {
      ApproveDenyShipments: actionNames.includes("approve_deny_shipments"),
      BookShipments: actionNames.includes("book_shipments"),
      SetPendingShipments: actionNames.includes("set_pending_shipments"),
    };
  };