import { createContext, useEffect } from "react"
import { useAuth } from "src/hooks/useAuth";
import { useChangeNoteQueries } from "src/pages/support/hooks/useChangeNoteQueries";

export const SystemContext = createContext();

export const SystemProvider = ({ children }) => {
    const { session } = useAuth();
    const { getLastChangeNotes, lastChangeNotes } = useChangeNoteQueries();

    useEffect(() => {
        if (!!session?.token) getLastChangeNotes();
    }, [session]);

    return (
        <SystemContext.Provider
            value={{
                notes: lastChangeNotes
            }}
        >
            {children}
        </SystemContext.Provider>
    );
}