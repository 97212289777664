import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
import { bgBlur } from '../../../utils/cssStyles';
import Logo from '../../../components/logo';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

// import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';
import { TenantSelector } from 'src/components/tenant-selector';

// ----------------------------------------------------------------------

const NAV_WIDTH = 220;

const HEADER_DESKTOP = 64;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  [theme.breakpoints.down('lg')]: {
    background: 'white',
  },
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: '50px',
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <MenuOpenIcon />
        </IconButton>

        <Box sx={{ py: 1, display: { lg: 'none', sm: 'inline-flex'} }}>
          <Logo />
        </Box>

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >

          <TenantSelector/>
          <Box>
            <NotificationsPopover />
            <AccountPopover />
          </Box>
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}