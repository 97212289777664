import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { FormAccordion } from "src/components/form/FormAccordion";
import { FormInput } from "src/components/form/FormInput";
import { Clear } from "@mui/icons-material";
import { useLocationFormContext } from "../hooks/useLocationFormContext";

export function OperationHours(props) {
  const { appendHolidayHours } = useLocationFormContext();
  return (
    <>
      <Stack spacing={2}>
        <WeekDaysHours disabled={props.disabled} />
      </Stack>
      <Stack spacing={1}>
        <HolidaysHours />
        <Button
          size="small"
          variant="text"
          align="left"
          onClick={() =>
            appendHolidayHours({
              day: null,
              operation: { start: null, end: null, closed: false },
            })
          }
        >
          + Add Special/Holiday Hours
        </Button>
      </Stack>
    </>
  );
}

// Return the weekdays form
function WeekDaysHours(props) {
  const { control, watch } = useFormContext();

  const weekdays = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  return (
    <Card
      variant="outlined"
      sx={{
        py: "1.5rem",
        px: "1rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.75rem",
        "&.MuiCard-root": { pt: 0 },
      }}
    >
      <CardContent
        sx={{
          "&.MuiCardContent-root": { p: 0 },
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <FormAccordion
          header={
            <Box display={"flex"} gap={1}>
              <Typography
                component="h4"
                fontWeight={600}
              >
                Normal Operation Hours
              </Typography>
            </Box>
          }
          defaultExpanded={true}
          sx={{
            p: 0,
            "& .MuiAccordionDetails-root": { p: 0 },
            "& .MuiAccordionSummary-root": { p: 0 },
          }}
          elevation={0}
        >
          {weekdays.map((day) => {
            const closed = watch(`operationHours.${day}.closed`);

            return (
              <Box key={day}>
                {day == "sunday" && (
                  <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                    <Box sx={{ flex: 1 }}>Day</Box>
                    <Box sx={{ flex: 1 }}>Start</Box>
                    <Box sx={{ flex: 1 }}>End</Box>
                    <Box sx={{ flex: 1 }}></Box>
                  </Box>
                )}
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Box sx={{ flex: 1 }}>
                    <Typography
                      component="h5"
                      sx={{ mt: 1, textTransform: "capitalize" }}
                    >
                      {day}
                    </Typography>
                  </Box>
                  <FormInput
                    name={`operationHours.${day}.start`}
                    //label="Start"
                    disabled={closed || props.disabled}
                    required={!closed}
                    type="time"
                    sx={{ flex: 1 }}
                  />
                  <FormInput
                    name={`operationHours.${day}.end`}
                    //label="End"
                    disabled={closed || props.disabled}
                    required={!closed}
                    type="time"
                    sx={{ flex: 1 }}
                  />
                  <Box sx={{ flex: 1 }}>
                    <Controller
                      control={control}
                      name={`operationHours.${day}.closed`}
                      render={({ field }) => (
                        <FormControlLabel
                         disabled={props.disabled}
                          control={
                            <Checkbox {...field} checked={!!field.value} />
                          }
                          label="Closed?"
                        />
                      )}
                    />
                  </Box>
                </Box>
              </Box>
            );
          })}
        </FormAccordion>
      </CardContent>
    </Card>
  );
}

// Return the weekdays form
const HolidaysHours = (props) => {
  const { fieldsHolidayHours, removeHolidayHours } = useLocationFormContext();

  const { control, watch } = useFormContext();
  return (
    <Card
      variant="outlined"
      sx={{
        py: "1.5rem",
        px: "1rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.75rem",
        "&.MuiCard-root": { pt: 0 },
      }}
    >
      <CardContent
        sx={{
          "&.MuiCardContent-root": { p: 0 },
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <FormAccordion
          header={
            <Box display={"flex"} gap={1}>
              <Typography
                component="h4"
                fontWeight={600}
              >
                Special/Holiday Operation Hours
              </Typography>
            </Box>
          }
          sx={{
            p: 0,
            "& .MuiAccordionDetails-root": { p: 0 },
            "& .MuiAccordionSummary-root": { p: 0 },
          }}
          defaultExpanded={true}
          elevation={0}
        >
          {fieldsHolidayHours?.length === 0 && (
            <Typography variant="body2" color="text.secondary">
              No special or holiday hours defined yet
            </Typography>
          )}
          {fieldsHolidayHours?.map((holiday, index) => {
            const closed = watch(
              `operationHours.others[${index}].operation.closed`
            );
            return (
              <Box sx={{ display: "flex", gap: 2 }} key={index}>
                <FormInput
                  name={`operationHours.others[${index}].day`}
                  //label="Date"
                  required
                  type="date"
                  sx={{ flex: 1 }}
                  InputProps={{ inputProps: { size: "small" } }}
                />
                <FormInput
                  name={`operationHours.others[${index}].operation.start`}
                  //label="Start"
                  disabled={closed || props.disabled}
                  required={!closed}
                  type="time"
                  sx={{ flex: 1 }}
                />
                <FormInput
                  name={`operationHours.others[${index}].operation.end`}
                  //label="Start"
                  disabled={closed || props.disabled}
                  required={!closed}
                  type="time"
                  sx={{ flex: 1 }}
                />
                <Box sx={{ flex: 0.5 }}>
                  <Controller
                    control={control}
                    disabled={props.disabled}
                    name={`operationHours.others[${index}].operation.closed`}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field.value} />}
                        label="Closed?"
                      />
                    )}
                  />
                </Box>
                <Box sx={{ flex: 0 }}>
                  <Button
                    sx={{ ml: 0, mr: 0 }}
                    onClick={() => removeHolidayHours(index)}
                  >
                    <Clear />
                  </Button>
                </Box>
              </Box>
            );
          })}
        </FormAccordion>
      </CardContent>
    </Card>
  );
};
