import { LoadingButton } from "@mui/lab";
import { useProfileForm } from "./useProfileForm";
import { FormProvider } from "react-hook-form";
import { AllRoles } from "src/models/roles";
import { Box, Stack, MenuItem } from "@mui/material";
import { useEffect } from "react";
const { FormInput } = require("src/components/form/FormInput");

export function ProfileForm({ useFormParams, onSave, showPassword, reset }) {
    const {
        isLoading,
        form,
        handleSubmit,
        onSubmitHandler,
        clearErrors,
    } = useProfileForm({ useFormParams, onSave, reset });

    useEffect(() => {
        if (useFormParams) {
            Object.entries(useFormParams).forEach(([key, value]) => {
                form.setValue(key, value);
            });
        }
    }, [useFormParams]);

    return <FormProvider {...form}>
        <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={(event) => {
                clearErrors()
                handleSubmit(onSubmitHandler)(event)
            }}
        >
            <Stack spacing={2}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <FormInput
                        name={`firstname`}
                        label="First Name"
                        required
                        type="text"
                        sx={{ flexGrow: 1 }}
                    />
                    <FormInput
                        name={`lastname`}
                        label="Last Name"
                        required
                        type="text"
                        sx={{ flexGrow: 1 }}
                    />
                </Box>
                <FormInput
                    name={`email`}
                    label="Email"
                    required
                    type="text"
                    sx={{ flexGrow: 1 }}
                />
                {
                    showPassword && <FormInput
                        name={`password`}
                        label="Password"
                        required
                        type="password"
                        sx={{ flexGrow: 1 }}
                    />
                }
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <FormInput
                        sx={{ flexGrow: 1 }}
                        name="role.name"
                        label="Role name"
                        defaultValue={AllRoles[0]}
                        select
                        required
                    >
                        {AllRoles.map((role) => {
                            return (
                                <MenuItem
                                    key={role}
                                    value={role}
                                >{role}</MenuItem>
                            );
                        })}
                    </FormInput>
                    <FormInput
                        name="role.relationship"
                        label="Role relationship"
                        type="text"
                        sx={{ flexGrow: 1 }}
                    />
                </Box>
                <FormInput name="vendor" label="Vendor" type="text" />
                <FormInput name="shipFromLocation" label="Ship From Location" type="text" />
                <FormInput name="locationHours" label="Location Hours" type="text" />
                <FormInput name="locationPhone" label="Location Phone" type="text" />
                <FormInput name="locationOwner" label="Location Owner" type="text" />
            </Stack>

            <LoadingButton
                variant="contained"
                fullWidth
                type="submit"
                loading={isLoading}
                sx={{ py: "0.8rem", mt: "1rem" }}
            >
                Register
            </LoadingButton>
        </Box>
    </FormProvider>;
}