import {
  DescriptionOutlined,
  ListAltOutlined,
  TimelapseRounded
} from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Tab
} from "@mui/material";
import { useState } from "react";
import { DetailsDrawer } from "src/components/drawer/DetailsDrawer";
import { WarehouseBasicInfo } from "./BasicInfo";
import { OperationHoursDetails } from "./OperationHoursDetails";
import { DocksDetails } from "./DocksDetails";

const tabs = [
  {
    title: "Basic Info",
    icon: <DescriptionOutlined />,
  },
  {
    title: "Docks",
    icon: <ListAltOutlined />,
  },
  {
    title: "Operation Hours",
    icon: <TimelapseRounded />,
  }
];

export function WareshouseDetailsDrawer({
  title = "Testing",
  selectedItem: warehouse,
  onVisibilityChanged,
  onEditTapped,
  columns,
}) {
  const [selectedTabIndex, setSelectedTabIndex] = useState("0");
  const onTabSelected = (event, tabIndex) => {
    setSelectedTabIndex(tabIndex);
  };

  return (
    <DetailsDrawer
      title={`${warehouse.name}`}
      selectedItem={warehouse}
      onVisibilityChanged={onVisibilityChanged}
      onEditTapped={onEditTapped}
      rootRoute={'warehouses'}
    >
      <TabContext value={selectedTabIndex}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
          }}
        >
          <WareshouseDrawerTabs onChange={onTabSelected} />
        </Box>
        <TabPanel
          value={"0"}
          sx={{
            maxWidth: "880px",
            display: "flex",
            flexDirection: "column",
            gap: 2,
            "&.MuiTabPanel-root": { padding: 0 },
          }}
        >
          <WarehouseBasicInfo
            warehouse={warehouse}
            setSelectedTabIndex={setSelectedTabIndex}
          />
        </TabPanel>

        <TabPanel value={"1"} sx={{ maxWidth: "880px" }}>
          <DocksDetails warehouse={warehouse} />
        </TabPanel>

        <TabPanel
          value={"2"}
          sx={{ maxWidth: "880px", "&.MuiTabPanel-root": { padding: 0 } }}
        >
          <OperationHoursDetails warehouse={warehouse}/>
        </TabPanel>
      </TabContext>
    </DetailsDrawer>
  );
}

function WareshouseDrawerTabs({ onChange }) {
  return (
    <TabList
      onChange={onChange}
      variant="scrollable"
      scrollButtons={true}
      allowScrollButtonsMobile
    >
      {tabs.map((item, index) => {
        return (
          <Tab
            icon={item.icon}
            iconPosition="start"
            label={item.title}
            value={index.toString()}
            key={item.title}
          />
        );
      })}
    </TabList>
  );
}

export function valueGetter(value, caseEmptyValue = "--") {
  return value ? value : caseEmptyValue;
}
