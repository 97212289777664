import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'src/hooks/useAuth';

function PrivateRoute({ children }) {

  const { isLogged } = useAuth();
  
  return (isLogged)
    ? <Outlet />
    : <Navigate to={"/login"} />
}

export default PrivateRoute