export const userColumns = [
  {
    field: "firstname",
    headerName: "First Name",
    minWidth: 150,
  },
  {
    field: "lastname",
    headerName: "Last Name",
    minWidth: 150,
  },
  {
    field: "email",
    headerName: "Email",
    minWidth: 200,
  },
  {
    field: "role.name",
    headerName: "Role",
    minWidth: 200,
    renderCell: (params) => (
      <div>{params.row.role ? params.row.role.name : ''}</div>
    ),
    valueGetter: (params) => params.row.role?.name ?? '',
  },
  {
    field: "role.relationship",
    headerName: "Relationship",
    minWidth: 300,
    renderCell: (params) => {
      const role = params.row.role;

      if (role) {
        if (role?.name === 'WarehouseManager' || role?.name === 'Contractor') {
          return <>{role.warehouses.map(w => w.name).join(', ')}</>
        } else if (role?.name === 'SupplierManager') {
          return <>{role.vendors.map(v => v.name).join(', ')}</>
        }
      }

      return <>{'--'}</>;
    },
    valueGetter: (params) => {
      const role = params.row.role;

      if (role) {
        if (role?.name === 'WarehouseManager' || role?.name === 'Contractor') {
          return role.warehouses.map(w => w.name).join(', ');
        } else if (role?.name === 'SupplierManager') {
          return role.vendors.map(v => v.name).join(', ');
        }
      }

      return '';
    },
  },
  {
    field: "contact",
    headerName: "Contact",
    minWidth: 300,
    renderCell: (params) => {
      return (
        <div>{params.row.role ? params.row.contact.map(r => `${r.type}: ${r.value}`).join(', ') : ''}</div>
      )
    },
    valueGetter: (params) => {
      return (
        params.row.role ? params.row.contact.map(r => `${r.type}: ${r.value}`).join(', ') : ''
      )
    },
  },
  {
    field: "location",
    headerName: "Default Location",
    minWidth: 200,
  },
  {
    field: "tenants",
    headerName: "Tenants",
    minWidth: 200,
    renderCell: (params) => {
      const tenants = params.value;      
      return tenants.map((tenants) => `${tenants.name}`).join(", ") ;
    },
  },
];
