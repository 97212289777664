import { z } from "zod";
import { Item } from "./item";

export class Stop {
  constructor(stopData) {
    this.type = stopData.type;
    this.destName = stopData.destName;
    this.destAddr1 = stopData.destAddr1;
    this.destAddr2 = stopData.destAddr2;
    this.destCity = stopData.destCity;
    this.destState = stopData.destState;
    this.destZip = stopData.destZip;
    this.destContact = stopData.destContact;
    this.destPhone = stopData.destPhone;
    this.destEmail = stopData.destEmail;
    this.targetDeliveryEarly = stopData.targetDeliveryEarly;
    this.targetDeliveryLate = stopData.targetDeliveryLate;
    this.items = stopData.items.map((itemData) => new Item(itemData));
  }
  static get createSchema() {
    return z.object({
      type: z.enum(["pickup", "delivery"]).default("delivery"),
      destName: z.string().min(1, "Required Field"),
      destAddr1: z.string().min(1, "Required Field"),
      destAddr2: z.string().min(1, "Required Field"),
      destCity: z.string().min(1, "Required Field"),
      destState: z.string().min(1, "Required Field"),
      destZip: z.string().min(1, "Required Field"),
      destCtry: z.string().min(1, "Required Field"),
      destContact: z.string().min(1, "Required Field"),
      destPhone: z.string().min(1, "Required Field"),
      destEmail: z.string().email(),
      targetDeliveryEarly: z.coerce.date(),
      targetDeliveryLate: z.coerce.date(),
      actualDelivery: z.coerce.date(),
      items: z.array(Item.createSchema),
    });
  }
}
