export const hazmatClasses = [
    1,
    1.1,
    1.2,
    1.3,
    1.4,
    1.5,
    1.6,
    2,
    2.1,
    2.2,
    2.3,
    3,
    4,
    4.1,
    4.2,
    4.3,
    5,
    5.1,
    5.2,
    6,
    7,
    8,
    9,
];