import { LoadingButton } from "@mui/lab";
import { useShipmentQueries } from "../hooks/useShipmentQueries";
import { useEffect } from "react";
import { DeleteOutlineOutlined } from "@mui/icons-material";

export function DeleteFileButton({ shipmentId, fileType, onFileDeleted }) {
    const { isDeletingFile, deleteFile, isFileDeleted, onFileDeletedData } = useShipmentQueries();

    useEffect(() => {
        if (isFileDeleted && onFileDeletedData) {
            onFileDeleted(onFileDeletedData);
        }
    }, [onFileDeletedData]);

    return (
        <LoadingButton
            loading={isDeletingFile}
            type="button"
            sx={{
                display: "flex",
                px: 1,
                minWidth: "fit-content",
                gap: 2,
                borderRadius: "0.5rem",
                fontWeight: 800,
                boxShadow: 1,
            }}
            onClick={() => deleteFile({shipmentId, fileType})}
        >
            <DeleteOutlineOutlined />
        </LoadingButton>
    );
}