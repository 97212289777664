import { LoadingButton } from "@mui/lab";
import { useShipmentQueries } from "../hooks/useShipmentQueries";
import { useEffect } from "react";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useSnackbar } from "src/hooks/useSnackbar";

export function DeletePackageSlipFileButton({ shipmentId, itemId,  onFileDeleted }) {                
    const { isDeletingPackageSlipFile, deletePackageSlipFile, isFilePackageSlipDeleted, onFileDeletedPackageSlipData } = useShipmentQueries();
    const { setSnackbarMessage } = useSnackbar();

    useEffect(() => {
      if (isFilePackageSlipDeleted && onFileDeletedPackageSlipData) {
          onFileDeleted(onFileDeletedPackageSlipData);
      }
    }, [onFileDeletedPackageSlipData]);

    const handleDelete = () => {
        deletePackageSlipFile({
            shipmentId,            
            itemId,      
            onDeleted: (data) => {
              if (data.errors){
                setSnackbarMessage({
                  message: <Box> Error deleting package slip file.</Box>,
                  severity: "error",                  
                });
               } else {
                setSnackbarMessage({
                  message: <Box> Success to delete package slip file </Box>,
                  severity: "success",
                });          
                onFileDeleted(data)
              }
            },
            onFailed: (err) =>
              setSnackbarMessage({
                message: (
                    <Box>
                      {" "}
                      Error to delete package slip: {err.message}
                    </Box>
                  ),
                severity: "error",
              }),
          });
    }                  

    return (
        <LoadingButton
            loading={isDeletingPackageSlipFile}
            type="button"
            sx={{
                display: "flex",
                px: 1,
                minWidth: "fit-content",
                gap: 2,
                borderRadius: "0.5rem",
                fontWeight: 800,
                boxShadow: 1,
            }}
            onClick={() => {handleDelete()}}
        >
            <DeleteOutlineOutlined />
        </LoadingButton>
    );
}