import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { StandardDialog } from "src/components/dialog/Dialog";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactMarkdown from "react-markdown";
import { Constants } from "src/utils";
import { useSystem } from "src/hooks/useSystem";
import { useAuth } from "src/hooks/useAuth";
import { useSnackbar } from "src/hooks/useSnackbar";

export default function DialogLastChange(props) {
  const { setSnackbarMessage } = useSnackbar();
  const { notes } = useSystem();
  const { session } = useAuth();
  const [state, setState] = useState({
    open: false,
    initialized: false,
    checked: false,
  });

  const initialize = () => {
    if (!notes || !notes.created) return;

    const isSupplierManager = session.permissions.IsSupplierManager;
    const isCox = session?.tenant?.code === "COX";
    if (!isSupplierManager || !isCox) return;

    const seemAt = localStorage.getItem(Constants.LAST_CHANGE_SEEM_AT);
    const seemAtDate = new Date(seemAt);
    const isLastNoteSeem = seemAtDate >= new Date(notes.created?.at);

    if (!isLastNoteSeem) {
      setState({ ...state, open: true });
    }
  }

  useEffect(() => { initialize() }, []);
  useEffect(() => { initialize() }, [notes]);

  const onDone = () => {
    if (state.checked) {
      localStorage.setItem(Constants.LAST_CHANGE_SEEM_AT, new Date().toISOString());
      props.onDone();
      setState({ ...state, open: false });
      return;
    }
    
    setSnackbarMessage({
      message: 'Check the box to keep moving!',
      severity: 'warning'
    });
  }

  const handleChange = (event) => {
    setState({ ...state, checked: event.target.checked });
  };

  return (
    <StandardDialog isOpen={state.open}>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <Box
            component={"img"}
            src="/assets/last-changes.svg"
            alt="Descrição do SVG"
            width={"10%"}
          />
          <Box>
            <Typography sx={{ fontSize: "1.6rem", padding: 0 }}>
              Hey {props.userName}, check out our last update!
            </Typography>
            <Typography sx={{ fontSize: "1rem", padding: 0 }}>
              Keep up to date with the last modifications.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ m: 3, pb: 2 }}>
          <Typography variant="h4">{notes?.title}</Typography>
          <Typography variant="h6">{notes?.subtitle ?? ''}</Typography>
          <ReactMarkdown>{notes?.content}</ReactMarkdown>

          <Box sx={{ display: "flex", ml: "80%" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.checked}
                  onChange={handleChange}
                  name="sawThisChange"
                  color="primary"
                />
              }
              sx={{ whiteSpace: "nowrap" }}
              label="I see this changes"
            />
            <Button variant="contained" onClick={onDone} >
              Done
            </Button>
          </Box>
        </Box>
      </Box>
    </StandardDialog>
  );
}
