import { Container, Paper } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { TenantForm } from "./components/TenantForm";
import { useTenantQueries } from "./hooks/useTenantQueries";
import { useEffect } from "react";

export function UpdateTenantPage() {
  const {
    tenant,
    getTenant,
    isLoadingTenant,
    isRefetchingTenant,
    updateTenant,
    isUpdateLoading,
  } = useTenantQueries();

  useEffect(() => {
    getTenant();
  }, []);

  return (
    <Paper
      sx={{
        maxWidth: {
          lg: "1000px",
          xs: "90%",
        },
        margin: "auto",
      }}
      elevation={3}
    >
      <Helmet>
        <title>Update Tenant</title>
      </Helmet>
      <TenantForm
        type="Update"
        onSave={updateTenant}
        isSubmissionLoading={isUpdateLoading}
        isLoading={isLoadingTenant || isRefetchingTenant}
        useFormParams={{ defaultValues: tenant }}
      />
    </Paper>
  );
}
