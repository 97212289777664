export class Compare {
    static dayInMoth(v1, v2) {
        if (!v1 || !v2) return 1
        const dateV1 = new Date(v1);
        const dateV2 = new Date(v2);

        if (isNaN(dateV1) || isNaN(dateV1)) return 1

        return dateV1.getTime() - dateV2.getTime();
    };
    
}