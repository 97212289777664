import React, { useState } from 'react';
import { Box, Button, Container, IconButton, InputAdornment, LinearProgress, Typography } from '@mui/material';
import { ForgotPasswordFormProvider } from './context/ForgotPasswordFormContext';
import { useForgotPasswordNoteQueries } from './hooks/useForgotPasswordQueries';
import Iconify from 'src/components/iconify';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormInput } from 'src/components/form/FormInput';

export default function ResetPassword() {
    const {
        handleResetPassword,
        isResettingPassword,
        passwordChanged,
    } = useForgotPasswordNoteQueries();

    const [showPassword, setShowPassword] = useState(false);

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const email = query.get('email');
    const token = query.get('token');
    const navigate = useNavigate();

    return <Container maxWidth="sm" sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        flexDirection: 'column',
    }}>
        <Box>
            {
                passwordChanged ? <>
                    <Typography variant="body1" color="textSecondary" gutterBottom>
                        Your password has been successfully changed. You can now go to the login page to sign in with your new password.
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        type="submit"
                        sx={{ mt: 2, width: '100%' }}
                        onClick={() => {
                            navigate('/login');    
                        }}
                    >
                        Go to Login
                    </Button>
                </> : <>
                    <Typography variant="h4" gutterBottom>
                        Set a New Password
                    </Typography>
                    <Typography variant="body1" color="textSecondary" gutterBottom>
                        Enter your new password below to complete the reset process. Make sure your new password is strong and secure. If you didn't request a password reset, please contact support immediately.            </Typography>
                    <ForgotPasswordFormProvider
                        onSend={(value) => handleResetPassword(token, email, value.password)}
                        useFormParams={{ defaultValues: null }}>
                        <FormInput
                            name="password"
                            label="Password"
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end">
                                            <Iconify
                                                icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                        />
                        {
                            isResettingPassword ? <LinearProgress sx={{marginTop: "24px"}}/> : <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                type="submit"
                                sx={{ mt: 2, width: '100%' }}
                            >
                                Change password
                            </Button>
                        }
                    </ForgotPasswordFormProvider>
                </>
            }
        </Box>
    </Container>
}