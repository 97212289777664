import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

export function useScheduleForm(props) {
  const useFormParams = props?.useFormParams;
  const form = useForm({
    ...useFormParams,
    defaultValues: useFormParams?.defaultValues
      ? {
          ...formatScheduleToFormDefaultValues(useFormParams.defaultValues),
        }
      : {},
    mode: "onChange",
  });

  function formatScheduleToFormDefaultValues(schedule) {
    return {
      ...schedule,
      warehouseId: schedule?.warehouse?._id,
      appointmentTypeId: schedule?.appointmentType?._id,
      dispositionId: schedule?.disposition,
      start: schedule.date.slice(0, 16)
        .split("T")
        .map((val, index) => (index == 0 ? val : schedule.start))
        .join("T")+'Z',

      end: schedule.date.slice(0, 16)
        .split("T")
        .map((val, index) => (index == 0 ? val : schedule.end))
        .join("T")+'Z',
        
      // end: schedule.date
      //   .slice(0, 11)
      //   .concat(schedule.date.slice(11).replace("00:00", schedule.end)),
      recurring: schedule.recurring
        ? {
            ...schedule.recurring,
            daysOfWeek: schedule.recurring?.daysOfWeek?.map((day) => ({
              value: day,
            })),
          }
        : {},
    };
  }

  const {
    formState: { errors, isSubmitSuccessful },
    reset,
    handleSubmit,
    setError,
    clearErrors,
  } = form;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isSubmitSuccessful) {
      props.reset && reset();
    }
  }, [isSubmitSuccessful, reset, errors]);

  const onSubmitHandler = async (values) => {
    setIsLoading(true);

    const scheduleData = { ...values };

    try {
      const data = await props.onSave(scheduleData);
      if (data.errors) {
        updateErrors(data.errors, setError);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    form,
    handleSubmit,
    onSubmitHandler,
    clearErrors,
  };
}

function updateErrors(errors, setError) {
  errors.forEach((error) => {
    const param = error.param.replace("[", ".").replace("]", "");
    setError(param, { type: "manual", message: error.msg });
  });
}
