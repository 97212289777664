export class BaseService {
  constructor(token, tenantId) {
    this.token = token;
    this.tenant = tenantId;
  }
  
  header(params, inferContentType) {
    const headers = params?.headers ? params.headers : {};
    const contentType = inferContentType ? {} : {"Content-Type": "application/json"}

    return {
      ...params,
      headers: {
        Authorization: `Bearer ${this.token}`,
        tenant: this.tenant ?? null,
        ...contentType,
        ...headers
      },
    };
  }
}
