import { useState } from "react";
import { useQuery } from "react-query";
import { CarrierService } from "src/api/carrier.service";
import { useAuth } from "src/hooks/useAuth";

export const useCarrierQueries = () => {
    const { session } = useAuth();
    const service = new CarrierService(session.token, session.tenant._id);
    const [paginationParams, setPaginationParams] = useState({ page: 1, limit: 15, filters: [], search: ""});

    const {
        isLoading: isCarriersLoading,
        data: carriers,
        refetch: getCarriers,
    } = useQuery({
        queryKey: ["carriers"],
        queryFn: () => {
            return service.getCarriers();
        },
        enabled: false,
    });

    const {
        isLoading: isCarriersPaginatedLoading,
        data: carriersPaginated,
        refetch: getCarriersPaginated
    } = useQuery({
        queryKey: ["carriersPaginated", paginationParams],
        queryFn: ({queryKey}) => {
            const [_key, params] = queryKey;
            return service.getCarriersPaginated(params);
        },
        enabled: true,
    });

    const handleFetchWithNewParams = (newParams) => {
        setPaginationParams((prev) => ({
          ...prev,
          ...newParams,
        }));
        getCarriersPaginated();
      };

    return {
        isCarriersLoading,
        carriers,
        getCarriers,

        isCarriersPaginatedLoading,
        carriersPaginated,
        getCarriersPaginated,

        handleFetchWithNewParams,
    }
}