import config from "src/config.js";
import { BaseService } from "./base.service";

const baseUrl = config.apiUrl;
const baseEndpoint = "/schedule";
const getScheduleEndpoint = "/schedule/{id}";
const byGroupIDEndpoint = "/schedule/by-group/{groupId}";
const approveScheduleEndpoint = "/schedule/approve/{id}/dock/{dockId}";
const rejectScheduleEndpoint = "/schedule/reject/{id}";
const updateDispositionEndpoint = "/schedule/{id}/disposition";

export class ScheduleService extends BaseService {
  async getAll(filter) {
    const uri = new URL(baseUrl + baseEndpoint);
    if (filter) uri.searchParams.append("filter", filter);

    const response = await fetch(uri.toString(), this.header());
    // return responseMapper(await response.json());
    return await response.json();
  }

  async updateDisposition(scheduleId, disposition) {
    const uri = `${baseUrl}/schedule/${scheduleId}/disposition`
    const response = await fetch(
      uri.toString(),
      this.header({
        method: "PUT",
        body: JSON.stringify(disposition),
      })
    );
    const data = await response.json();

    if (response.ok) {
      return data;
    } else {
      throw new Error(data.message);
    }
  }
  async getById(scheduleId) {
    const response = await fetch(
      baseUrl + getScheduleEndpoint.replace("{id}", scheduleId),
      this.header()
    );
    // return responseMapper(await response.json());
    return await response.json();
  }

  async create(schedule) {
    const uri = new URL(`${baseUrl}${baseEndpoint}`);
    const response = await fetch(
      uri.toString(),
      this.header({
        method: "POST",
        body: JSON.stringify(schedule),
      })
    );

    if (!response.ok) {
      throw new Error(`Error while creating shipment ${response.statusText}`);
    }

    return await response.json();
  }

  async update(schedule) {
    const uri = new URL(`${baseUrl}${baseEndpoint}/${schedule._id}`);
    const response = await fetch(
      uri.toString(),
      this.header({
        method: "PUT",
        body: JSON.stringify(schedule),
      })
    );
    const data = await response.json();

    if (response.ok) {
      return data;
    } else {
      throw new Error(data.message);
    }
  }

  async approveOrReject(schedule, status, dockId) {
    const path = (
      status === "approved" ? approveScheduleEndpoint : rejectScheduleEndpoint
    )
      .replace("{id}", schedule)
      .replace("{dockId}", dockId);
    const uri = new URL(`${baseUrl}${path}`);

    const response = await fetch(
      uri.toString(),
      this.header({
        method: "PATCH",
      })
    );
    return await response.json();
  }

  async upload(scheduleId, files) {
    const URI =
      baseUrl + getScheduleEndpoint.replace("{id}", scheduleId) + "/upload";

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file, file.path);
    });

    const response = await fetch(
      URI,
      this.header({
      method: "POST",
      body: formData,
      }, true)
    );

    if (!response.ok) {
      const errorMessage = response.message;
      throw new Error(`Error uploading files: ${errorMessage}`);
    }
    return response.json();
  }

  async delete(schedule) {
    const uri = new URL(
      baseUrl + getScheduleEndpoint.replace("{id}", schedule)
    );
    const response = await fetch(
      uri.toString(),
      this.header({
        method: "DELETE",
      })
    );
    return await response.json();
  }

  async deleteGroup(groupId, date) {
    const uri = new URL(
      (baseUrl + byGroupIDEndpoint).replace("{groupId}", groupId)
    );
    const response = await fetch(
      uri.toString(),
      this.header({
        method: "DELETE",
        body: JSON.stringify({ date }),
      })
    );
    return await response.json();
  }

  async appointmentTypes() {
    const response = await fetch(`${baseUrl}/appointment-type`, this.header());
    return await response.json();
  }
}
