import { useEffect, useRef, useState } from "react";
import useResponsive from "src/hooks/useResponsive";

/**
 * Listens to column-related changes on grid, and saves column settings to local storage.
 *
 * For Column visibility to work, add the following to grid props:
 *  `initialState={{ columns: { columnVisibilityModel: {} } }}`
 */
export function useDataGridSettings(apiRef, key, gridLayoutSetup) {
  const initialized = useRef(false);

  const isMobile = useResponsive('down','sm');
  const getStorageKey = () =>
    `${key}-DataGrid-${isMobile ? "mobile" : "desktop"}-settings`;

  const [storageKey, setStorageKey] = useState(getStorageKey());

  useEffect(() => {
    setStorageKey(getStorageKey());
    initialized.current = false;
  }, [isMobile]);

  useEffect(() => {
    const ref = apiRef.current;
    if (!ref?.subscribeEvent) return;

    // Restore state on first ref load
    if (!initialized.current) {
      initialized.current = true;

      const localStorageSettings = localStorage.getItem(storageKey);
      // MIGRATING SETUP - TO REMOVE
      const oldStorageKey = `${key}-DataGrid-settings`;
      const oldDesktopLocalStorageSettings = localStorage.getItem(oldStorageKey);

      const raw = localStorageSettings ? localStorageSettings 
        : isMobile && gridLayoutSetup?.mobile ? gridLayoutSetup?.mobile
        : !isMobile && oldDesktopLocalStorageSettings ? oldDesktopLocalStorageSettings // HANDLING OLD SETUP - TO REMOVE
        : !isMobile && gridLayoutSetup?.desktop ? gridLayoutSetup?.desktop
        : null;

      if (raw) {
        const parsed = parseOrNull(raw);
        if (parsed) {
          try {
            // console.log(`Restoring grid state for ${key}`, parsed)
            parsed.pagination.paginationModel.page = 0; // PREVENT TO RETRIEVE PAGE STATE
            delete parsed.preferencePanel; 
            
            if(parsed?.filter) 
              parsed.filter = ['']; 

            ref.restoreState(parsed);
          } catch (e) {
            console.warn(`Failed to restore grid state`, e);
          }
        }
      }
    }

    const subscriptions = [];

    const subscribe = (event) => {
      subscriptions.push(ref.subscribeEvent(event, () => save(ref)));
    };

    subscribe("columnResizeStop");
    subscribe("columnOrderChange");
    subscribe("pinnedColumnsChange");
    subscribe("columnVisibilityModelChange");
    subscribe("paginationModelChange");
    subscribe("filterModeChange");

    return () => {
      subscriptions.forEach((unsubscribe) => {
        unsubscribe();
      });
    };
  }, [apiRef, storageKey]);

  const save = (ref) => {
    const state = ref.exportState();
    if (state) {
      // console.log(`Storing grid state for ${key}`)
      localStorage.setItem(storageKey, JSON.stringify(state));
    }
  };
}

function parseOrNull(raw) {
  if (!raw) return null;

  if (typeof raw === "string") {
    try {
      return JSON.parse(raw);
    } catch (e) {
      console.warn(`Failed to parse: ${raw.substring(0, 50)}`);
      return null;
    }
  } else if(typeof raw == 'object')
    return raw;

  return null;
}
