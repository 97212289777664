import { valueGetter } from "src/components/drawer/DetailsDrawer";
import { RatingStars } from "../components/CarrierDetailsDrawer";

export const CarrierColumns = () => {
  return [
    {
      field: "name",
      headerName: "Name",
      minWidth: 250,
    },
    {
      field: "address1",
      headerName: "Address",
      minWidth: 250,
    },
    {
      field: "address2",
      headerName: "Address Line 2",
      minWidth: 200,
    },
    {
      field: "city",
      headerName: "City",
      minWidth: 130,
    },
    {
      field: "state_zip_country",
      headerName: "State, Zip, Country",
      minWidth: 250,
      valueGetter: (params) => {
        const carrier = params.row;
        return `${carrier.state}, ${carrier.postalCode}, ${carrier.country}`;
      },
    },
    {
      field: "doingBusinessAs",
      headerName: "Doing Businnes As",
      minWidth: 200,
    },
    {
      field: "rating",
      headerName: "Rating",
      minWidth: 200,
      renderCell: (params) => {
        return <RatingStars rating={params.row.rating} />;
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 100,
    },
  ];
};
