import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Order } from "src/models/order";
import { useShipmentFormContext } from "src/pages/shipments/hooks/useForm";

export const SelectPickupField = ({ options, value, onChange }) => {
    const { mapFields } = useShipmentFormContext();

    const replace = (pickup) => {
        let order = structuredClone(options);

        order.pickup = pickup;

        const shipmentAux = Order.responseToShipment(order, true);
        mapFields(shipmentAux);
    }
    return <FormControl fullWidth margin="normal" >
        <InputLabel id="select-pickup">Select the pickup</InputLabel>
        <Select
            labelId="select-pickup"
            value={value ?? {}}
            label="Select the pickup"
            onChange={(event) => {
                replace(event.target.value);
                onChange(event);
            }}
        >
            {options.map((pickup, index) => {
                return <MenuItem value={pickup} key={index}>{pickup.addr1}</MenuItem>
            })}
        </Select>
    </FormControl>;
}