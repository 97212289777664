import { createContext } from "react";
import { FormProvider } from "react-hook-form";
import { useChangeNoteFormHook } from "../hooks/useChageNoteFormHook";
import { Box } from "@mui/material";

export const ChangeNoteFormContext = createContext(null);

export function ChangeNoteFormProvider(props) {
    const useFormParams = props.useFormParams;
    const onSave = props.onSave;
    const reset = props.reset || false;
    const onClose = props.onClose;

    const {
        isLoading,
        form,
        handleSubmit,
        onSubmitHandler,
        clearErrors
    } = useChangeNoteFormHook({ useFormParams, onSave, reset, onClose });

    return (
        <ChangeNoteFormContext.Provider
            value={{
                isLoading,
                form,
                handleSubmit,
                onSubmitHandler,
                clearErrors
            }}>
            <FormProvider {...form}>
                <Box
                    component="form"
                    autoComplete="off"
                    onSubmit={(event) => {
                        clearErrors();
                        handleSubmit(onSubmitHandler)(event);
                    }}>
                    {props.children}
                </Box>
            </FormProvider>
        </ChangeNoteFormContext.Provider>
    );
}