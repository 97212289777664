import { Link } from "@mui/material";
import { Auth } from "src/models/roles";
import { ItemDetails } from "./components/ItemDetails";
import { fDateString } from "src/utils/formatTime";

export const shipmentDetailColumns = (session) => {

  let poNumberLabel = "PO Number";
  if (session?.tenant?.code === "COX") {
    poNumberLabel = "COX PO Number";
  }

  return [
    {
      key: "poNumber",
      label: poNumberLabel,
    },
    {
      key: "primaryReference",
      label: "Primary Reference",
    },
    {
      key: "mode",
      label: "Mode",
      renderValue: (value) => value.mode?.toUpperCase(),
    },
    {
      key: "status",
      label: "Status",
    },
    {
      key: "quantity",
      label: "Pallets",
    },
    {
      key: "weight",
      label: "Weight",
    },
    {
      key: "shipper",
      label: "Shipper",
      renderValue: (value) => value.pickups[0]?.originName,
    },
    {
      key: "originLocation",
      label: "Origin",
      renderValue: (value) => value.pickups[0]?.originAddr1,
    },
    {
      key: "originTargetShipEarly",
      label: "Target ship early",
      renderValue: (value) => fDateString(value.pickups[0]?.targetShipEarly),
    },
    {
      key: "originTargetShipLate",
      label: "Target ship early",
      renderValue: (value) => fDateString(value.pickups[0]?.targetShipLate),
    },
    {
      key: "receiver",
      label: "Receiver",
      renderValue: (value) => value.stops[0]?.destName,
    },
    {
      key: "destinationLocation",
      label: "Destination",
      renderValue: (value) => value.stops[0]?.destAddr1,
    },
    {
      key: "destTargetDeliveryEarly",
      label: "Target delivery early",
      renderValue: (value) => fDateString(value.stops[0]?.targetDeliveryEarly),
    },
    {
      key: "destTargetDeliveryLate",
      label: "Target delivery late",
      renderValue: (value) => fDateString(value.stops[0]?.targetDeliveryLate),
    },
    {
      field: "items",
      label: "Items",
      renderValue: (value) => {
        const items = value?.pickups[0]?.items || null;
        return items.map((item) => <ItemDetails item={item} />);
      },
    },
    {
      key: "trackingUrl",
      label: "Tracking URL",
      renderValue: (value, user) =>
        value.trackingUrl ? (
          <Link href={value.trackingUrl}>{value.trackingUrl}</Link>
        ) : (
          "Unavaliable"
        ),
    },
    {
      key: "bolFile.url",
      label: "BOL",
      renderValue: (value, user) => {
        if (value.bolFile)
          return <Link href={value.bolFile?.url}>Download BOL</Link>;
        if (Auth(user).IsBackOffice) {
          return <Link href={`shipments/${value._id}/bol`}>Upload BOL</Link>;
        }

        return <></>;
      },
    },
    {
      key: "podFile.url",
      label: "POD",
      renderValue: (value, user) => {
        if (value.podFile)
          return <Link href={value.podFile?.url}>Download POD</Link>;
        if (Auth(user).IsBackOffice) {
          return <Link href={`shipments/${value._id}/pod`}>Upload POD</Link>;
        }

        return <></>;
      },
    },
  ];
};