import {
  AttachFileOutlined,
  CloudUpload,
  CloudUploadOutlined,
  TaskAltOutlined,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useShipmentQueries } from "../hooks/useShipmentQueries";

export function UploadInput({ shipmentId, fileType, uploadCallback }) {
  const [selectedFile, setSelectedFile] = useState(null);

  const fileInputRef = useRef(null);
  const openFileSelection = () => fileInputRef.current.click();

  const handleFileChange = (event) => {
    console.log(event.target.files[0]);
    setSelectedFile(event.target.files[0]);
  };

  const { isUploadLoading, uploadFile, isUploadSuccess, updatedShipment } =
    useShipmentQueries();

  const handleUpload = async () => {
    await uploadFile({ shipmentId, file: selectedFile, fileType });
  };

  useEffect(() => {
    if (isUploadSuccess && updatedShipment) {
      uploadCallback(updatedShipment);
    }
  }, [updatedShipment]);

  return (
    <Box
      component="form"
      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
    >
      {/* Hidden file input */}
      <input
        accept=".jpg,.jpeg,.png,.pdf"
        id="file-upload"
        type="file"
        style={{ display: "none" }}
        onChange={handleFileChange}
        ref={fileInputRef}
      />
      {/* IconButton to trigger file input */}
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        {!selectedFile && (
          <label htmlFor="file-upload">
            <Tooltip title={`Select ${fileType} File`}>
              <LoadingButton
                loading={isUploadLoading}
                type="button"
                sx={{
                  display: "flex",
                  width: "10rem",
                  px: 1,
                  gap: 2,
                  borderRadius: "0.5rem",
                  fontWeight: 800,
                  boxShadow: 1,
                }}                
                onClick={() => fileInputRef.current.click()}
              >
                <CloudUploadOutlined />
                <Typography
                  variant="button"
                  sx={{ width: "100%", textAlign: "left" }}
                >
                  Upload {fileType}
                </Typography>
              </LoadingButton>
            </Tooltip>
          </label>
        )}
        {selectedFile && !isUploadSuccess && (
          <Box sx={{ display: "flex", gap: 1 }}>
            <Tooltip title={`Select Another File`}>
              <Button
                sx={{ px: "0.5rem", minWidth: "fit-content" }}
                onClick={openFileSelection}
              >
                {/* <Backspace /> */}
                <AttachFileOutlined />
              </Button>
            </Tooltip>
            <Tooltip title={`Upload ${selectedFile.name}`}>
              <LoadingButton
                loading={isUploadLoading}
                type="button"
                sx={{
                  display: "flex",
                  width: "10rem",
                  px: 1,
                  gap: 2,
                  borderRadius: "0.5rem",
                  fontWeight: 800,
                  boxShadow: 1,
                }}
                onClick={handleUpload}
              >
                <CloudUpload />
                <Typography
                  variant="button"
                  sx={{ width: "100%", textAlign: "left" }}
                >
                  Send File
                </Typography>
              </LoadingButton>
            </Tooltip>
          </Box>
        )}
        {isUploadSuccess && (
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              sx={{
                display: "flex",
                width: "10rem",
                px: 1,
                gap: 2,
                borderRadius: "0.5rem",
                fontWeight: 800,
                boxShadow: 1,
                "&.Mui-disabled": {
                  color: (theme) => theme.palette.primary.main,
                },
              }}
              disabled
            >
              <TaskAltOutlined />
              <Typography
                variant="button"
                sx={{ width: "100%", textAlign: "left" }}
              >
                {fileType} Uploaded
              </Typography>
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}
