import { Box, Container, Link, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';
import LoginForm from './components/login-form/LoginForm';
import Logo from 'src/components/logo/Logo';

// ----------------------------------------------------------------------

const StyledRoot = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  return (
    <>
      <Helmet>
        <title> Login | Load Manager </title>
      </Helmet>

      <StyledRoot elevation={3}>
        <Container maxWidth="sm">
          <StyledContent>
            <Logo sx={{ width: '10rem' }} disabledLink />
            <Typography variant="h4" gutterBottom>
              Sign in
            </Typography>
            <LoginForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
