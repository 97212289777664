import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import VideoPlayerDialog from "./VideoDialog";
import { VideoTutorialItem } from "./VideoTutorialItem";
const { useStorageQueries } = require("../hooks/useQueries");

export function VideoTutorialsGrid({ videos }) {
    const { getVideoUrl, videoUrl: url } = useStorageQueries();

    const InitialState = {
        isShowVideo: false,
        isInitialized: false,
    }
    const [state, setState] = useState(InitialState);

    const toggleVideoPlayer = (show) => {
        setState({ ...state, isShowVideo: show });
    }

    const initialize = () => {
        setState({ ...state, isInitialized: true });
    }

    useEffect(() => {
        if (state.isInitialized) toggleVideoPlayer(true);
        else initialize();

    }, [url]);

    return <Grid
        container
        spacing={2.5}
        marginBottom={"42px"}
        marginTop={"2px"}
        direction={{
            xs: "column",
            md: "row",
        }}
    >
        {
            videos.map((video, index) =>
                video.videoPath && <Grid item xs={4} key={index}>
                    <VideoTutorialItem
                        onItemTapped={() => getVideoUrl(video.videoPath)}
                        title={video.title}
                        thumbnail={video.thumbnail}
                    />
                </Grid>
            )
        }
        <VideoPlayerDialog
            onClose={() => toggleVideoPlayer(false)}
            open={state.isShowVideo ?? false}
            videoPath={url ?? ''}
            isLoading={false}
        />
    </Grid>
}
