import { Button, CircularProgress } from "@mui/material";
import { Box, Paper, Typography } from "@mui/material";
import { useSnackbar } from "src/hooks/useSnackbar";
import { Helmet } from "react-helmet-async";
import { useEffect } from "react";
import { VendorsForm } from "./VendorsForm";
import { useParams } from "react-router-dom";
import { Clear } from "@mui/icons-material";
import { useAuth } from "src/hooks/useAuth";
import { useVendorQueries } from "./hooks/useVendorQueries";

export function UpdateVendorsPage() {
  const { Snackbar } = useSnackbar();
  const { vendorId } = useParams();
  const { session } = useAuth();
  const {
    handleDelete,
    isDeletingVendor,
    updateVendor,
    isUpdatingLoading,
    getVendorById,
    vendor,
  } = useVendorQueries();

  const auth = session.permissions;  

  useEffect(() => {
    getVendorById();
  }, []);

  const handleRemove = async (values) => {
    handleDelete(values);
  };

  const handleSave = async (values) => {
    updateVendor(values);
  };

  return (
    <Paper
      sx={{ maxWidth: { lg: "1000px", xs: "90%" }, margin: "auto", py: 3 }}
      elevation={3}
    >
      <Helmet>
        <title>Edit vendor</title>
      </Helmet>
      <Box sx={{ maxWidth: "80%", margin: "auto" }}>
        <Typography variant="h4" component="h1" sx={{ mb: "2rem" }}>
          Edit vendor{vendor ? `: ${vendor.name} ` : ""}
          <Box sx={{ display: "inline-block", float: "right" }}>
            {auth.IsBackOffice && (
              <Button
                size="small"
                variant="text"
                color="error"
                onClick={() => handleRemove(vendorId)}
                disable={isDeletingVendor}
                startIcon={
                  isDeletingVendor ? <CircularProgress size={20} /> : <Clear />
                }
              >
                {isDeletingVendor ? "Removing..." : "Remove"}
              </Button>
            )}
          </Box>
        </Typography>

        <VendorsForm
          reset={false}
          useFormParams={{ defaultValues: vendor }}
          onSave={handleSave}
          isLoading={isUpdatingLoading}
        />
      </Box>
      {/* DISPLAY SUCESS OR ERRORS MESSAGE */}
      <Snackbar />
    </Paper>
  );
}
