import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./useAuth";

function useSessionInterceptor() {
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const originalFetch = window.fetch;

    window.fetch = async function (url, options) {
      const response = await originalFetch(url, options);
      if (response?.status === 401) {
        console.log(
          "Detected a 401 response. Logging out was triggered automatically."
        );
        logout();
        navigate('/login');
      }
      return response;
    };
  }, []);
}

export default useSessionInterceptor;
