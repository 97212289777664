import { Navigate, Route, Routes } from "react-router-dom";
import DashboardLayout from "src/layouts/dashboard/DashboardLayout";
import LoginPage from "src/pages/login/LoginPage";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import ShipmentsPage from "src/pages/shipments/ShipmentsPage";
import useSessionInterceptor from "src/hooks/useSessionInterceptor";
import { UsersPage } from "src/pages/users/UsersPage";
import { Page404 } from "src/pages/Page404";
import { CreateShipmentPage } from "src/pages/shipments/CreateShipmentPage";
import { CreateUserPage } from "src/pages/users/CreateUserPage";
import { UpdateUserPage } from "src/pages/users/UpdateUserPage";
import { UpdateShipmentPage } from "src/pages/shipments/UpdateShipmentPage";
import { ProfilePage } from "src/pages/profile/ProfilePage";
import { ShipmentsCalendarPage } from "src/pages/schedule/ScheduleCalendar";
import { WarehousesPage } from "src/pages/warehouses/WarehousesPage";
import { WarehousesPageCreate } from "src/pages/warehouses/WarehousesPageCreate";
import { WarehousesPageUpdate } from "src/pages/warehouses/WarehousesPageUpdate";
import { TenantProvider } from "src/context/tentant/TenantProvider";
import { SetTenantPage } from "src/pages/tenant/SetTenantPage";
import { useAuth } from "src/hooks/useAuth";
import { SupportPage } from "src/pages/support/SupportPage";
import { ContactUs } from "src/pages/support/contact-us/ContactUsDialog";
import BackofficeShipmentsPage from "src/pages/shipments/BackofficeShipmentsPage";
import { LocationsPage } from "src/pages/location/LocationsPage";
import { CreateLocationPage } from "src/pages/location/CreateLocationPage";
import { UpdateLocationPage } from "src/pages/location/UpdateLocationPage";
import { OrdersPage } from "src/pages/order/OrdersPage";
import { ChangeNotesPage } from "src/pages/support/ChangeNotesPage";
import { ReportsPage } from "src/pages/reports/ReportsPage";
import { ReportDashboardPage } from "src/pages/reports/ReportDashboardPage";
import { VendorsPage } from "src/pages/vendors/VendorsPage";
import { UpdateVendorsPage } from "src/pages/vendors/UpdateVendorsPage";
import { CreateVendorsPage } from "src/pages/vendors/CreateVendorsPage";
import { CarriersPage } from "src/pages/carriers/CarriersPage";
import ForgotPassword from "src/pages/forgot-password/ForgotPassword";
import ResetPassword from "src/pages/forgot-password/ResetPassword";
import { PendingShipments } from "src/pages/shipments/PendingShipments";
import { ClosedShipments } from "src/pages/shipments/ClosedShipments";
import { TenantsPage } from "src/pages/tenant/TenantsPage";
import { CreateTenantPage } from "src/pages/tenant/CreateTenantPage";
import { UpdateTenantPage } from "src/pages/tenant/UpdateTenantPage";

export const getDefaultPath = (auth) => auth?.IsContractor || auth?.IsWarehouseManager ? "/schedule/by-date" : "/shipments";

function AppRouter() {
  const { session } = useAuth();
  useSessionInterceptor();

  const auth = session?.permissions;

  return (
    <TenantProvider>
      <Routes>
        <Route index element={<Navigate to={getDefaultPath(auth)} />} />
        <Route path="login" element={
          // Public routes only available if user is not logged-in
          <PublicRoute>
            <LoginPage />
          </PublicRoute>
        } />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route path="set-tenant" element={<SetTenantPage />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="/" element={<DashboardLayout />}>
            {/* <Route path="/app" element={<DashboardAppPage />} /> */}
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/schedule/by-date" element={<ShipmentsCalendarPage />} />

            <Route path="/shipments" element={auth?.IsBackOffice || auth?.IsLTLManager ? <BackofficeShipmentsPage /> : < ShipmentsPage />} />
            <Route path="/shipments/:shipmentId" element={auth?.IsBackOffice || auth?.IsLTLManager ? <BackofficeShipmentsPage /> : < ShipmentsPage />} />
            <Route path="/shipments/:shipmentId/edit" element={<UpdateShipmentPage />} />
            <Route path="/shipments/create" element={<CreateShipmentPage />} />

            <Route path="/users" element={<UsersPage />} />
            <Route path="/users/:userId" element={<UsersPage />} />
            <Route path="/users/:userId/edit" element={<UpdateUserPage />} />
            <Route path="/users/create" element={auth?.IsBackOffice ? <CreateUserPage /> : <Page404 />} />

            <Route path="/vendors" element={<VendorsPage />} />
            <Route path="/vendors/:vendorId" element={<VendorsPage />} />
            <Route path="/vendors/:vendorId/edit" element={<UpdateVendorsPage />} />
            <Route path="/vendors/create" element={auth?.IsBackOffice ? <CreateVendorsPage /> : <Page404 />} />            

            <Route path="/warehouses" element={<WarehousesPage />} />
            <Route path="/warehouses/:warehouseId" element={<WarehousesPage />} />
            <Route path="/warehouses/:warehouseId/edit" element={auth?.IsBackOffice || auth?.IsWarehouseManager ? <WarehousesPageUpdate /> : <Page404 />} />
            <Route path="/warehouses/create" element={auth?.IsBackOffice ? <WarehousesPageCreate /> : <Page404 />} />

            <Route path="/locations" element={auth?.IsBackOffice ? <LocationsPage /> : <Page404 />} />
            <Route path="/locations/:locationId" element={auth?.IsBackOffice ? <LocationsPage /> : <Page404 />} />
            <Route path="/locations/:locationId/edit" element={auth?.IsBackOffice ? <UpdateLocationPage /> : <Page404 />} />
            <Route path="/locations/create" element={auth?.IsBackOffice ? <CreateLocationPage /> : <Page404 />} />

            <Route path="/orders" element={auth?.IsBackOffice || auth?.IsSupplierManager ? <OrdersPage /> : <Page404 />} />
            <Route path="/orders/:orderId" element={auth?.IsBackOffice || auth?.IsSupplierManager ? <OrdersPage /> : <Page404 />} />

            <Route path="/reports" element={<ReportsPage />} />
            <Route path="/reports/dashboard/:dashboardId" element={<ReportDashboardPage />} />

            <Route path="/carriers" element={auth?.IsBackOffice ? <CarriersPage /> : <Page404 />}/>
            <Route path="/carriers/:orderId" element={auth?.IsBackOffice ? <CarriersPage /> : <Page404 />}/>

            <Route path="/support" element={<SupportPage />} />
            <Route path="/changelog" element={<ChangeNotesPage />} />

            <Route path="/pending-shipments" element={<PendingShipments />} />
            <Route path="/pending-shipments/:shipmentId" element={<PendingShipments />} />

            <Route path="/closed-shipments" element={<ClosedShipments />} />
            <Route path="/closed-shipments/:shipmentId" element={<ClosedShipments />} />

            <Route path="/tenants" element={auth?.IsBackOffice ? <TenantsPage /> : <Page404 />} />
            <Route path="/tenants/:tenantId" element={auth?.IsBackOffice ? <TenantsPage /> : <Page404 />} />
            <Route path="/tenants/:tenantId/edit" element={auth?.IsBackOffice ? <UpdateTenantPage /> : <Page404 />} />
            <Route path="/tenants/create" element={auth?.IsBackOffice ? <CreateTenantPage /> : <Page404 />} />
          </Route>
        </Route>
      </Routes>
    </TenantProvider>
  );
}

export default AppRouter;