import { CloseOutlined, Delete } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import {
  FormSteps,
  NextStepButton,
  PreviousStepButton,
  StepsHandlers,
} from "src/components/form/FormSteps";
import { PackageFormProvider, usePackageForm } from "../context/PackageContext";
import { useShipmentFormContext } from "../hooks/useForm";
import { PackageForm } from "./form/PackageFields/PackageForm";
import { OrderUtils } from "src/utils";

export const PackageDialog = ({
  isOpen,
  onClose,
  onUpdated,
  packageIndex,
  isEdditing,
}) => {

  const { getOrderByPONumber } = useShipmentFormContext();
  const { watch, setValue, getValues } = useFormContext();
  const title = useMemo(() => {
    const item = watch(`items.${packageIndex}`);
    return isEdditing
      ? `Update Package ${item?.itemId ? " - " + item?.itemId : ""}`
      : `Create package ${item?.itemId ? " - " + item?.itemId : ""}`;
  }, [watch]);

  const poNumber = watch(`items.${packageIndex}.poNumber`);
  const subItem = watch(`items.${packageIndex}.subItems`);
  useEffect(() => {
    if (poNumber && !subItem) {
      const items = getValues(`items.${packageIndex}.subItems`);
      if (!items || !items.length) {
        const poNumbers = getValues(`items.${packageIndex}.poNumber`);
        poNumbers?.forEach((poNumber, index) => {
          const order = getOrderByPONumber(poNumber);
          if (order) {
            const item = {
              sku: order.ITEM_NUM,
              description: order.ITEM_DESC,
              quantityUOM: OrderUtils.toShipmentUOM(order.UOM),
            };
            setValue(`items.${packageIndex}.subItems.${index}`, item);
          }
        });
      }
    }
  }, [poNumber]);

  console.log(poNumber)

  return (
    <PackageFormProvider packageIndex={packageIndex}>
      <Dialog
        open={isOpen}
        onClose={onClose}
        fullWidth
        fullScreen
        maxWidth="md"
        sx={{
          mt: "16px",
          mx: {
            xs: "0",
            md: "7rem",
          },
        }}
        PaperProps={{ sx: { borderRadius: "1rem 1rem 0 0" } }}
      >
        <AppBar sx={{ position: "relative", py: "0.7rem" }}>
          <Toolbar
            sx={{
              display: "flex",
              gap: 2,
              flexDirection: {
                xs: "column",
                sm: "row",
              },
              justifyContent: {
                xs: "flex-start",
                sm: "space-between",
              },
              alignItems: { xs: "flex-start", sm: "center" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "fit-content",
                }}
              >
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={onClose}
                  aria-label="close"
                >
                  <CloseOutlined />
                </IconButton>
                <Typography fontWeight={600} fontSize={"1.2rem"}>
                  {title}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <FormSteps />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: {
                  xs: "100%",
                  sm: "fit-content",
                },
              }}
            >
              <PreviousStepButton
                buttonProps={{
                  sx: { color: "white" },
                }}
              />
              <NextStepButton
                buttonProps={{
                  sx: { color: "white" },
                }}
                onLastStepTapped={onUpdated}
                reset
              />
            </Box>
          </Toolbar>
        </AppBar>
        <DialogContent
          style={{
            overflow: "auto",
            padding: 0,
          }}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            height={"100%"}
            alignItems={"center"}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              bgcolor={"#f5f5f5"}
              height={"100%"}
              justifyContent={"space-between"}
              width={"100%"}
              padding={"2rem"}
              gap={2}
            >
              <PackageForm
                packageIndex={packageIndex}
                onUpdated={onUpdated}
                isEdditing={isEdditing}
              />
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </PackageFormProvider>
  );
};

function DeletePackageButton({ deletionCallback }) {
  const {
    items: { remove },
  } = useShipmentFormContext();
  const { packageIndex } = usePackageForm();
  return (
    <Tooltip title="Delete package">
      <IconButton
        onClick={() => {
          remove(packageIndex);
          deletionCallback();
        }}
      >
        <Delete />
      </IconButton>
    </Tooltip>
  );
}
