import { DataTable } from "src/components/datagrid";
import { desktopSetup, mobileSetup } from "../constants/dataGridSetup";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useAuth } from "src/hooks/useAuth";
import { useTenant } from "src/hooks/useTenant";

const { LinearProgress } = require("@mui/material");
const {
  default: CustomToolbar,
} = require("src/components/datagrid/widgets/CustomToolbar");
const {
  shipmentDetailColumns,
} = require("src/pages/dashboard/shipmentColumns");
const { ShipmentDetailsDrawer } = require("./details-drawer/backoffice/DetailsDrawer");

export function ShipmentsDataGrid({ isLoadingData, shipments, columns, ...props }) {
  const navigate = useNavigate();
  const { session } = useAuth();
  const { tenant}  = useTenant();

  if (props.isInvalidShipment) {
    const left = ["actions", "poNumber", "primaryReference"]

    if (tenant?.modules?.spotmarket) left.push("metadata.rate");

    desktopSetup.pinnedColumns = {
      left: left,
      right: []
    };

    mobileSetup.pinnedColumns = {
      left: left,
      right: []
    };
  }

  return (
    <DataTable
      loadData={shipments}
      columns={columns}
      className="datagrid"
      density="compact"
      disableDensitySelector
      loading={isLoadingData}
      // DRAWER PROPS
      customDrawerComponent={ShipmentDetailsDrawer}
      detailColumns={shipmentDetailColumns(session)}
      onEditTapped={(value) => navigate(`/shipments/${value._id}/edit`)}
      onDrawerClosed={() => navigate('/shipments')}
      slots={{
        toolbar: () => <CustomToolbar columns={columns} rows={shipments} title="Shipments" CustomToolbarActions={props.customToolbarActions}/>,
        loadingOverlay: LinearProgress,
      }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
          printOptions: { disableToolbarButton: true },
          quickFilterProps: { debounceMs: 500 },
          loadData: shipments,
          CustomToolbarActions: props.customToolbarActions,
        },
      }}
      modelKey="shipments"
      modelGridSetup={{
        mobile: mobileSetup,
        desktop: desktopSetup,
      }}

      {...props}
    />
  );
}
