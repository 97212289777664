import { DeleteRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  InputLabel,
  List,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useEffect, useMemo } from "react";
import { Controller } from "react-hook-form";
import { FormInput } from "src/components/form/FormInput";
import { useShipmentFormContext } from "src/pages/shipments/hooks/useForm";

export function PackagePOMapper({ unrestricted }) {
  const {
    items: { remove, update, append, fields: items },
    form: { control, watch },
  } = useShipmentFormContext();

  const poNumbers = useMemo(() => {
    const poS = watch("poNumber");
    return poS;
  }, [watch("poNumber")]);

  useEffect(() => {
    if (poNumbers.length == 1) append({ poNumber: poNumbers });
  }, []);

  return (
    <Box
      sx={{
        py: 1,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        // gap: "0.75rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <FormLabel>Please, set up your packages</FormLabel>
      </Box>
      <Box>
        {!items || !items.length ? (
          <Typography fontSize={"0.9rem"} color={"GrayText"}>
            No Packages
          </Typography>
        ) : (
          <>
            <List sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {items.map((item, index) => (
                <Box display={"flex"} key={index} gap={1}>
                  <FormInput
                    label="Package ID"
                    name={`items.${index}.itemId`}
                    type="text"
                    fullWidth
                  />
                  <FormControl fullWidth>
                    <InputLabel id={`items.${index}.poNumber`}>
                      PO Numbers:
                    </InputLabel>
                    <Controller
                      control={control}
                      name={`items.${index}.poNumber`}
                      render={({ field }) => {
                        const selectedValues = Array.isArray(field.value)
                          ? field.value
                          : [];

                        return (
                          <Select
                            multiple
                            {...field}
                            label={"PO Numbers:"}
                            name={`items.${index}.poNumber`}
                            fullWidth
                            value={selectedValues}
                            onChange={(event) => {
                              const value = Array.isArray(event.target.value)
                                ? event.target.value
                                : [event.target.value];
                              field.onChange(value);
                            }}
                          >
                            {Array.isArray(poNumbers) &&
                              poNumbers.length > 0 ? (
                              poNumbers.map((option) => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem key="" value="">
                                No PO Numbers available
                              </MenuItem>
                            )}
                          </Select>
                        );
                      }}
                    />
                  </FormControl>
                  <Button onClick={() => remove(index)} color="error">
                    <DeleteRounded />
                  </Button>
                </Box>
              ))}
            </List>
          </>
        )}
        {(unrestricted || !!watch(`items.${items.length - 1}`)?.itemId) && (
          <Button
            type="button"
            size="small"
            onClick={() => append({})}
            sx={{ width: '100%', py: 1 }}
          >
            Add Package
          </Button>
        )}
      </Box>
    </Box>
  );
}
