import { Box, Paper, Typography } from "@mui/material";
import { useSnackbar } from "src/hooks/useSnackbar";
import { Helmet } from "react-helmet-async";
import { VendorsForm } from "./VendorsForm";
import { useNavigate } from "react-router-dom";
import { useAuth } from "src/hooks/useAuth";
import { ErrorMessage } from "src/components/ErrorMessage";
import { VendorService } from "src/api/vendor.service";
import { useVendorQueries } from "./hooks/useVendorQueries";

export function CreateVendorsPage() {
    const { Snackbar } = useSnackbar();
    const { createVendor, isCreatingVendor } = useVendorQueries();

    /**
     * Handles saving a user.
     * @param {Object} values - The values of the user to be saved.
     * @returns {Promise<void>} - A promise that resolves when the user is saved.
     */
    const handleSave = (values) => {
        createVendor(values);
    };

    return (
        <Paper sx={{ maxWidth: { lg: "1000px", xs: "90%" }, margin: "auto", py: 3, }} elevation={3}>
            <Helmet>
                <title>Create a New Vendor</title>
            </Helmet>
            <Box sx={{ maxWidth: "80%", margin: "auto" }}>
                <Typography variant="h4" component="h1" sx={{ mb: "2rem" }}>
                    Create New Vendor
                </Typography>
                <VendorsForm
                    onSave={handleSave}
                    isLoading={isCreatingVendor}
                />
            </Box>
            {/* DISPLAY SUCESS OR ERRORS MESSAGE */}
            <Snackbar />
        </Paper>
    );
}
