import { Box, MenuItem, Stack, Typography } from "@mui/material";
import { FormInput } from "src/components/form/FormInput";
import { dimensionsUnits, palletCountUnits, weightUnits } from "../../units";
import { usePackageForm } from "src/pages/shipments/context/PackageContext";
import { FreightClassUtils } from "src/utils/freight";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";

export function PackageSpecificationForm() {
  const { state, packageIndex } = usePackageForm();

  const { getValues, setValue } = useFormContext();

  const calcFreightClass = (event) => {
    setValue(event.target.name, event.target.value);

    const item = getValues(`items.${packageIndex}`);

    const isDimensionSpecified =
      item?.actualLength && item?.actualWidth && item?.actualHeight;
    const isWeightSpecified = item?.actualWeight ?? false;
    const isQtySpecified = item?.actualQty ?? false;

    const isSpecificationSpecified =
      isDimensionSpecified && isWeightSpecified && isQtySpecified;
    if (isSpecificationSpecified) {
      const freightClass = FreightClassUtils.calc(
        item.actualWeight,
        item.actualWeightUOM,
        item.actualLength,
        item.actualWidth,
        item.actualHeight,
        item.actualDimensionsUOM,
        item.actualQty
      );

      setValue(`items.${packageIndex}.class`, freightClass);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        my: "1rem",
      }}
    >
      {/* DIMENSIONS */}
      <Typography variant="caption" fontSize={"1.2rem"} fontWeight={600}>
        Specification
      </Typography>

      <Box>
        <Typography variant="caption" fontSize={"1rem"}>
          {`The dimension for one ${state.quantityLabel}`}
        </Typography>
        <Box marginTop={"0.5rem"} />
        <Stack
          spacing={1}
          direction={{
            xs: "column",
            md: "row",
          }}
        >
          <FormInput
            name={`items.${packageIndex}.actualLength`}
            label="Length"
            type="number"
            min={0}
            onWheel={(event) => event.target.blur()}
            onChange={calcFreightClass}
            required
            fullWidth
          />
          <FormInput
            name={`items.${packageIndex}.actualWidth`}
            label="Width"
            type="number"
            min={0}
            onWheel={(event) => event.target.blur()}
            onChange={calcFreightClass}
            required
            fullWidth
          />
          <FormInput
            name={`items.${packageIndex}.actualHeight`}
            label="Height"
            type="number"
            min={0}
            onWheel={(event) => event.target.blur()}
            onChange={calcFreightClass}
            required
            fullWidth
          />
          <Box>
            <FormInput
              name={`items.${packageIndex}.actualDimensionsUOM`} // TODO - CHECK WITH SCHEMA
              label="Unit"
              defaultValue={
                getValues("dimensionUOM")
                  ? getValues("dimensionUOM")
                  : dimensionsUnits[0].label
              }
              select
              required
              onChange={calcFreightClass}
              SelectProps={{
                MenuProps: {
                  PaperProps: { sx: { maxHeight: "10rem" } },
                },
              }}
              sx={{ width: "100%" }}
              fullWidth
            >
              {dimensionsUnits.map((option) => (
                <MenuItem key={option.label} value={option.label}>
                  {`${option.abreviation} - ${option.label}`}
                </MenuItem>
              ))}
            </FormInput>
          </Box>
        </Stack>
      </Box>

      {/* WEIGHT */}
      {state.isShowWeight && (
        <Box>
          <Typography variant="caption" fontSize={"1rem"}>
            {`The total weight of all ${state.quantityLabel}`}
          </Typography>
          <Box marginTop={"0.5rem"} />
          <Stack
            spacing={1}
            direction={{
              xs: "column",
              md: "row",
            }}
          >
            <FormInput
              name={`items.${packageIndex}.actualWeight`}
              label="Total Weight"
              type="number"
              required
              fullWidth
              onChange={calcFreightClass}
              onWheel={(event) => event.target.blur()}
              min={0}
            />
            <FormInput
              // sx={{ width: "45%" }}
              name={`items.${packageIndex}.actualWeightUOM`}
              label="Unit"
              onChange={calcFreightClass}
              defaultValue={
                getValues("weightUOM")
                  ? getValues("weightUOM")
                  : weightUnits[0].abreviation
              }
              select
              required
              fullWidth
            >
              {weightUnits.map((unit) => {
                return (
                  <MenuItem
                    key={unit.label}
                    value={unit.abreviation}
                  >{`${unit.abreviation} - ${unit.label}`}</MenuItem>
                );
              })}
            </FormInput>
          </Stack>
        </Box>
      )}

      {/* PALLET COUNT */}
      {state.isShowQuantity && (
        <Box>
          <Typography variant="caption" fontSize={"1rem"}>
            {`The total count of ${state.quantityLabel}`}
          </Typography>
          <Box marginTop={"0.5rem"} />
          <Stack
            spacing={1}
            direction={{
              xs: "column",
              md: "row",
            }}
          >
            <FormInput
              name={`items.${packageIndex}.actualQtyUOM`}
              label="Unit"
              onChange={calcFreightClass}
              defaultValue={
                getValues("quantityUOM")
                  ? getValues("quantityUOM")
                  : palletCountUnits[12]
              }
              select
              required
              SelectProps={{
                MenuProps: {
                  PaperProps: { sx: { maxHeight: "10rem" } },
                },
              }}
              fullWidth
            >
              {palletCountUnits.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </FormInput>
            <FormInput
              fullWidth
              name={`items.${packageIndex}.actualQty`}
              label={`${state.quantityLabel} Count`}
              type="number"
              required
              onChange={calcFreightClass}
              onWheel={(event) => event.target.blur()}
              min={0}
            />
          </Stack>
        </Box>
      )}

      {/* PIECE COUNT */}
      {state.isShowQuantity && (
        <Box>
          <Typography variant="caption" fontSize={"1rem"}>
            {`How many pieces are on the ${state.quantityLabel}`}
          </Typography>
          <Box marginTop={"0.5rem"} />
          <Stack
            spacing={1}
            direction={{
              xs: "column",
              md: "row",
            }}
          >
            <FormInput
              name={`items.${packageIndex}.actualPiecesUOM`}
              label="Piece  Unit"
              defaultValue={
                getValues("quantityUOM")
                  ? getValues("quantityUOM")
                  : palletCountUnits[13]
              }
              select
              required
              SelectProps={{
                MenuProps: {
                  PaperProps: { sx: { maxHeight: "10rem" } },
                },
              }}
              fullWidth
            >
              {palletCountUnits.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </FormInput>
            <FormInput
              fullWidth
              name={`items.${packageIndex}.actualPieces`}
              label={`Piece Count`}
              type="number"
              required
              onWheel={(event) => event.target.blur()}
              min={0}
            />
          </Stack>
        </Box>
      )}

      
    </Box>
  );
}
