import { FormControlLabel, Switch } from '@mui/material';
import PropTypes from 'prop-types';

export function VisibilitySwitch({label, isVisible, setVisibility, children}) {

  const handleToggle = () => {
    setVisibility(isVisible);
  };

  return (
    <>
      <FormControlLabel control={<Switch checked={!isVisible} onChange={handleToggle} />} label={label} />
    {!isVisible && children}
    </>
  );
}

VisibilitySwitch.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
