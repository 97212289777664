import { Box, Card, CardContent, Stack, Typography } from "@mui/material";

export function DocksDetails({ warehouse }) {
  return (
    <Stack gap={2}>
      {warehouse.docks &&
        warehouse.docks.map((dock) => (
          <Card
            variant="outlined"
            key={dock._id}
            sx={{
              py: "0.5rem",
              px: "1rem",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "0.75rem",
            }}
          >
            <CardContent
              sx={{
                "&.MuiCardContent-root": {
                  p: 0,
                },
              }}
            >
              <Box
                key={dock._id}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  py: "0.25rem",
                }}
              >
                <Typography fontWeight={600}>Dock ID</Typography>
                <Typography>{dock.dockId}</Typography>
              </Box>
              <Box
                key={dock._id}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  py: "0.25rem",
                }}
              >
                <Typography fontWeight={600}>Type</Typography>
                <Typography>{dock.type}</Typography>
              </Box>
              {dock.disabled && <Typography variant="caption" color='error'>Out of Service</Typography>}
            </CardContent>
          </Card>
        ))}
    </Stack>
  );
}
