export const columns = [
  {
    field: "name",
    headerName: "Name",
    minWidth: 250,
  },
  {
    field: "location",
    headerName: "Location",
    minWidth: 230,
  },
  {
    field: "city",
    headerName: "City",
    minWidth: 150,
  },
  {
    field: "state",
    headerName: "State",
    minWidth: 50,
  },
  {
    field: "zip",
    headerName: "Zip",
    minWidth: 70,
  },
  {
    field: "docks",
    headerName: "# Docks",
    minWidth: 70,
    align: 'center',
    renderCell: (params) => params.row.docks?.length,
    valueGetter: (params) => params.row.docks?.length ?? ''
  },
];
