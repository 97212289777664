import { Button, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { SupportService } from "src/api/support.service";
import { useAuth } from "src/hooks/useAuth";
import { ContactUs } from "src/pages/support/contact-us/ContactUsDialog";

export function ContactButton(props) {
  const [open, setOpen] = useState(false);
  
  const onHandleClose = () => {
    setOpen(false);
  };

  const handleOpenSupport = () => {
    setOpen(true);
  };

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        sx={{ maxWidth: '220px', textTransform: "capitalize", ...props?.sx }}
        onClick={handleOpenSupport}
        {...props}
      >
        Contact Us
      </Button>
      <Modal open={open} onClose={onHandleClose}>
        <ContactUs handleClose={onHandleClose} isSupportOnly={props.isSupportOnly} />
      </Modal>
    </>
  );
}

