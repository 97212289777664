import { Box, Typography } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import { ReportService } from "src/api/report.service";
import { useAuth } from "src/hooks/useAuth";
import { useSnackbar } from "src/hooks/useSnackbar";

export function useReportQueries() {
  const { session } = useAuth();

  const service = new ReportService(session.token, session.tenant._id);
  const { setSnackbarMessage } = useSnackbar();

  const {
    refetch: getReports,
    isLoading: isLoadingReports,
    isRefetching: isRefetchingReports,
    data: reports,
  } = useQuery({
    queryKey: "avaliable-reports",
    queryFn: () => {
      return service.getAvailableReports().catch((error) => {
        setSnackbarMessage({
          message: (
            <Box>
              <Typography mb={1}>Error listing reports</Typography>
              <Typography>{error.message}</Typography>
            </Box>
          ),
          severity: "error",
        });
      });
    },
    enabled: false
  });

  const {
    refetch: getUserReports,
    isLoading: isLoadingUserReports,
    isRefetching: isRefetchingUserReports,
    data: userReports,
  } = useQuery({
    queryKey: "userReports",
    queryFn: () => {
      return service.getUserReports().catch((error) => {
        setSnackbarMessage({
          message: (
            <Box>
              <Typography mb={1}>Error listing user reports</Typography>
              <Typography>{error.message}</Typography>
            </Box>
          ),
          severity: "error",
        });
      });
    },
    enabled: false
  });

  let dashboard = '';
  const {
    refetch: refetchReportUrl,
    isRefetching: isLoadingReportURL,
    data: reportURL,
  } = useQuery('report-dashboard', {
    queryFn: () => service
      .getReportAccessURL(dashboard)
      .catch((error) => {
        setSnackbarMessage({
          message: (
            <Box>
              <Typography mb={1}>Error to get dashboard: {dashboard} report</Typography>
              <Typography>{error.message}</Typography>
            </Box>
          ),
          severity: "error",
        });
      }),
    enabled: false,
  });

  const getReportUrl = (value) => {
    dashboard = value;
    refetchReportUrl();
  }

  return {
    getReports,
    isLoading: isLoadingReports || isRefetchingReports,
    reports,

    getUserReports,
    isLoadingUserReports: isLoadingUserReports || isRefetchingUserReports,
    userReports,

    getReportUrl,
    isLoadingReportURL,
    reportURL,
  };
}
