import { Star, StarBorder } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Switch,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StandardDialog } from "src/components/dialog/Dialog";
import {
  valueGetter
} from "src/components/drawer/DetailsDrawer";

export const CarrierDetailsDrawers = ({
  selectedItem: carrier,
  onVisibilityChanged,
}) => {
  const navigate = useNavigate();

  const [state, setState] = useState({
    selectedTab: 0,
    isOpen: false,
  });

  const onTabSelected = (event, tabIndex) => {
    setState({ ...state, selectedTab: tabIndex });
  };

  const toggleDrawer = (isVisible) => {
    setState({ ...state, isOpen: isVisible });
    onVisibilityChanged(isVisible);

    navigate("/carriers");
  };

  useEffect(() => {
    setState({ ...state, isOpen: !!carrier });
  }, [carrier]);

  return (
    <StandardDialog isOpen={state.isOpen} onClose={() => toggleDrawer(false)}>
      <Card
        sx={{
          py: "0.5rem",
          px: "1rem",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "0.75rem",
          bgcolor: "transparent",
          boxShadow: "none",
        }}
      >
        <CardHeader
          title={
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h5" sx={{}}>
                {carrier.name}
              </Typography>
              <Box>
                <Box
                  sx={{
                    display: { xs: "flex" },
                    gap: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  <Typography fontWeight={600}>Status: </Typography>
                  <Typography>{valueGetter(carrier.status)}</Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1, width: "100%" }}>
                  <Typography fontWeight={600}>{`Rating: `}</Typography>
                  <RatingStars rating={carrier.rating} />
                </Box>

              </Box>
            </Box>
          }
          subheader={`Doing bussined as: ${carrier.doingBusinessAs}`}
          sx={{
            "&.MuiCardHeader-root": {
              p: 0,
            },
          }}
        />
        <Divider />
        <CardContent
          sx={{
            "&.MuiCardContent-root": {
              p: 0,
            },
          }}
        >
          <Typography
            variant="caption"
            sx={{ fontSize: "1.2rem", fontWeight: 600 }}
          >
            Location:
          </Typography>
          <Box
            sx={{
              display: { md: "flex" },
              gap: "0.5rem",
              mb: "4px",
              mt: "8px",
            }}
          >
            <Typography fontWeight={600}>Address 1:</Typography>
            <Typography>{valueGetter(carrier.address1)}</Typography>
          </Box>
          <Box sx={{ display: { md: "flex" }, gap: "0.5rem", mb: "4px" }}>
            <Typography fontWeight={600}>Address 2:</Typography>
            <Typography>{valueGetter(carrier.address2)}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: { md: "flex" }, gap: "0.5rem" }}>
              <Typography fontWeight={600}>City:</Typography>
              <Typography>{valueGetter(carrier.city)}</Typography>
            </Box>
            <Box sx={{ display: { md: "flex" }, gap: "0.5rem" }}>
              <Typography fontWeight={600}>State:</Typography>
              <Typography>{valueGetter(carrier.state)}</Typography>
            </Box>
            <Box sx={{ display: { md: "flex" }, gap: "0.5rem" }}>
              <Typography fontWeight={600}>Zip:</Typography>
              <Typography>{valueGetter(carrier.postalCode)}</Typography>
            </Box>
            <Box sx={{ display: { md: "flex" }, gap: "0.5rem" }}>
              <Typography fontWeight={600}>Country:</Typography>
              <Typography>{valueGetter(carrier.country)}</Typography>
            </Box>
          </Box>

          <Divider sx={{ my: "12px" }} />

          <Typography
            variant="caption"
            sx={{ fontSize: "1.2rem", fontWeight: 600 }}
          >
            Contact:
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              mt: "8px",
            }}
          >
            <Box sx={{ display: { md: "flex" }, gap: "0.5rem", mb: "4px" }}>
              <Typography fontWeight={600}>Primary Phone: </Typography>
              <Typography>{valueGetter(carrier.phone)}</Typography>
            </Box>
            <Box sx={{ display: { md: "flex" }, gap: "0.5rem", mb: "4px" }}>
              <Typography fontWeight={600}>Alternate Phone:</Typography>
              <Typography>{valueGetter(carrier.alternatePhone)}</Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: { md: "flex" }, gap: "0.5rem", mb: "4px" }}>
              <Typography fontWeight={600}>Primary Fax:</Typography>
              <Typography>{valueGetter(carrier.fax)}</Typography>
            </Box>
            <Box sx={{ display: { md: "flex" }, gap: "0.5rem", mb: "4px" }}>
              <Typography fontWeight={600}>Primary Email: </Typography>
              <Typography>{valueGetter(carrier.email)}</Typography>
            </Box>
          </Box>

          <Divider sx={{ my: "12px" }} />

          <Typography
            variant="caption"
            sx={{ fontSize: "1.2rem", fontWeight: 600 }}
          >
            Addional Information:
          </Typography>
          <Grid
            container
            sx={{
              alignItems: "center",
              mt: "8px",
            }}
          >
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  display: { xs: "flex" },
                  gap: "0.5rem",
                  alignItems: "center",
                }}
              >
                <Typography fontWeight={600}>FSMA-Complient: </Typography>
                <Switch checked={carrier.status} />
                {/* <Typography>{valueGetter(carrier.status)}</Typography> */}
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  display: { xs: "flex" },
                  gap: "0.5rem",
                  alignItems: "center",
                }}
              >
                <Typography fontWeight={600}>Tax ID: </Typography>
                <Typography>{valueGetter(carrier.taxId)}</Typography>
              </Box>
            </Grid>
            {carrier.equipmentTypes && (
              <Grid item xs={12} md={5}>
                <Box
                  sx={{
                    display: { xs: "flex" },
                    gap: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  <Typography fontWeight={600}>Equipment Type: </Typography>
                  <Typography>{valueGetter(carrier.equipmentTypes)}</Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </StandardDialog>
  );
};

export function RatingStars({ rating }) {
  rating = isNaN(rating) ? 0 : rating;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "0.125rem",
      }}
    >
      {Array.from({ length: rating }).map((v, index) => (
        <Star key={index} />
      ))}
      {Array.from({ length: 5 - rating }).map((v, index) => (
        <StarBorder key={1 + index} />))}
    </Box>
  );
}
