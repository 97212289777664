import config from "src/config";
import {
  BaseService
} from "./base.service";

const baseUrl = '/tenants';
export class TenantService extends BaseService {
  baseUrl = `${config.apiUrl}/tenants`;

  getTenants = async (filter) => {
    const uri = new URL(`${this.baseUrl}`);
    if (filter) uri.searchParams.append("filter", filter);

    const response = await fetch(uri.toString(), this.header());
    return await response.json();
  };

  getTenant = async (tenantId) => {
    const uri = new URL([this.baseUrl, tenantId].join('/'));

    const response = await fetch(uri.toString(), this.header());
    return await response.json();
  };

  createTenant = async (data) => {
    const uri = new URL(this.baseUrl);

    const response = await fetch(
      uri.toString(),
      this.header({
        method: 'POST',
        body: JSON.stringify(data)
      })
    );

    const tenantResponseJson = await response.json();

    if (!response.ok) {
      const errorMessage = getErrorMessage(tenantResponseJson);
      throw new Error(`Error while creating tenant ${errorMessage}`);
    }
    return tenantResponseJson;
  }

  deleteTenant = async (tenantId) => {
    const uri = new URL([this.baseUrl, tenantId].join('/'));

    const response = await fetch(
      uri.toString(),
      this.header({
        method: 'DELETE',
      })
    );

    return await response.json();
  }

  updateTenant = async (tenantId, data) => {
    const uri = new URL([this.baseUrl, tenantId].join('/'));

    const response = await fetch(
      uri.toString(),
      this.header({
        body: JSON.stringify(data),
        method: "PUT"
      })
    );

    return await response.json();
  };
}

function getErrorMessage(error) {
  let errorMessage;

  if (error.message)
    errorMessage = error.message;
  if (error.msg)
    errorMessage = error.msg;
  if (error.messages) {
    if (Array.isArray(error.messages)) {
      errorMessage = error.errors.join(', ');
    }
  }
  if (error.errors && Array.isArray(error.errors)) {
    errorMessage = error.errors.map(error => error.msg).join(', ');
  }

  return errorMessage;
}