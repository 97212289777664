import { Box, Paper, Typography } from "@mui/material";
import { useLocationQueries } from "./hooks/useLocationQueries";
import { Helmet } from "react-helmet-async";
import { LocationForm } from "./form/LocationForm";
import { useSnackbar } from "src/hooks/useSnackbar";
import { useNavigate } from "react-router-dom";
import { Location } from "src/models/location";

export function CreateLocationPage() {
    const { create, isCreating } = useLocationQueries();
    const { setSnackbarMessage } = useSnackbar();
    const navigate = useNavigate();

    const onSave = async (location) => {
        const value = Location.toJson(location);
        create({
            location: value,
            onCreated: (data) => {
                if (data.errors) setSnackbarMessage({
                    message: (<Box> Error submitting location.</Box>),
                    severity: "error",
                });
                else {
                    setSnackbarMessage({
                        message: `Location ${data.name} successfully created`,
                        severity: "success",
                    });
                    navigate(`/locations/${data.id}`);
                }
            },
            onFailed: (message) => {
                setSnackbarMessage({
                    message: (<Box> {`${message}`}</Box>),
                    severity: "error",
                })
            },
        });
    }

    return (
        <Paper sx={{ maxWidth: { lg: "1000px", xs: "90%" }, margin: "auto", py: 3, }} elevation={3}>
            <Helmet><title>Create Location</title></Helmet>
            <Box sx={{ maxWidth: '80%', margin: 'auto' }}>
                <Typography variant="h4" component="h1" sx={{ mb: "2rem" }}>
                    Create Location
                </Typography>
                <LocationForm
                    onSave={onSave}
                    isSubimissionLoading={isCreating}
                />
            </Box>
        </Paper>
    );
}