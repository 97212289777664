import {
  Box,
  Drawer,
  Grid,
  Link,
  Stack,
  Typography,
  Paper
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Logo from "../../../components/logo";
import NavSection from "../../../components/nav-section";
import Scrollbar from "../../../components/scrollbar";
import useResponsive from "../../../hooks/useResponsive";
import CreateShipmentButton from "./CreateShipmentButton";
import navConfig from "./config";
import { ContactButton } from "src/components/contact-button";
import { useAuth } from "src/hooks/useAuth";

// import navSection from "../../../components/nav-section/navSection.css";

// ----------------------------------------------------------------------

const NAV_WIDTH = 220;

const StyledAccount = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: `${alpha(theme.palette.grey[100], 0.82)}`,
}));



// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const { session: { user } } = useAuth();

  const isDesktop = useResponsive("up", "lg");

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <>
      <Box sx={{ py: 2.5, display: 'flex', flexDirection: 'column' }}>
        <Logo />
        <Paper elevation={2} sx={{ mx: 2.5 }}>
          <Link underline="none">
            <StyledAccount>
              <Box sx={{ flex: 1, textAlign: "center" }}>
                <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                  {user.role.name.replace(/([a-z])([A-Z])/g, '$1 $2')}
                </Typography>
              </Box>
            </StyledAccount>
          </Link>
        </Paper>
        <CreateShipmentButton />
        <NavSection data={navConfig} />
      </Box>
    </>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
        justifyContent: "space-between",
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              borderRightStyle: "none",
            },
          }}
        >
          <Scrollbar
            sx={{
              height: 1,
              "& .simplebar-content": {
                height: 1,
                display: "flex",
                flexDirection: "column",
                backgroundColor: "white",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {renderContent}
              <Stack spacing={3} sx={{ p: 3, bgcolor: "background.white" }}>
                <Grid
                  container
                  // justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Stack>
                      <Typography variant="h5">How can we help you?</Typography>
                    </Stack>
                  </Grid>
                </Grid>
                <ContactButton/>
              </Stack>
            </Box>
          </Scrollbar>
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          <Scrollbar
            sx={{
              height: 1,
              "& .simplebar-content": {
                height: 1,
                display: "flex",
                flexDirection: "column",
                backgroundColor: "white",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {renderContent}
              <Stack spacing={2} sx={{ p: 3, bgcolor: "background.default" }}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  className="helpWidget"
                >
                  <Grid item>
                    <Stack>
                      <Typography variant="h5">How can we help you?</Typography>
                    </Stack>
                  </Grid>
                </Grid>
                <ContactButton/>
              </Stack>
            </Box>
          </Scrollbar>
        </Drawer>
      )}
    </Box>
  );
}
