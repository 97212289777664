import { LoadingButton } from "@mui/lab";
import { useShipmentQueries } from "../hooks/useShipmentQueries";
import { useEffect } from "react";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useSnackbar } from "src/hooks/useSnackbar";

export function DeleteFileAttachmentButton({ shipmentId,  onFileDeleted }) {                
    const { isDeletingAttachmentFile, deleteAttachmentFile, isFileAttachmentDeleted, onFileDeletedAttachmentData } = useShipmentQueries();
    const { setSnackbarMessage } = useSnackbar();

    useEffect(() => {
      if (isFileAttachmentDeleted && onFileDeletedAttachmentData) {
          onFileDeleted(onFileDeletedAttachmentData);
      }
    }, [onFileDeletedAttachmentData]);

    const handleDelete = () => {
        deleteAttachmentFile({
            shipmentId,                
            onDeleted: (data) => {
              if (data.errors){
                setSnackbarMessage({
                  message: <Box> Error deleting attachment file.</Box>,
                  severity: "error",
                });
               } else {
                setSnackbarMessage({
                  message: <Box> Success to delete attachment file </Box>,
                  severity: "success",
                });          
                onFileDeleted(data)
              }
            },
            onFailed: (err) =>
              setSnackbarMessage({
                message: (
                    <Box>
                      {" "}
                      Error to delete attachment file: {err.message}
                    </Box>
                  ),
                severity: "error",
              }),
          });
    }                  

    return (
        <LoadingButton
            loading={isDeletingAttachmentFile}
            type="button"
            sx={{
                display: "flex",
                px: 1,
                minWidth: "fit-content",
                gap: 2,
                borderRadius: "0.5rem",
                fontWeight: 800,
                boxShadow: 1,
            }}
            onClick={() => {handleDelete()}}
        >
            <DeleteOutlineOutlined />
        </LoadingButton>
    );
}