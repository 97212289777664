import config from "src/config";
import {
  BaseService
} from "./base.service";

export class ShipmentCarrierService extends BaseService {
  baseUrl = `${config.apiUrl}/shipmentCarrier`;

  async candidateCarrier( rate = null, mc = null, eta = null, emptyOn = null,shipmentId) {    
    const uri = new URL(
      `${this.baseUrl}/:shipmentId/carrier`.replace(
        ":shipmentId",
        shipmentId
      )
    );
  
   const fields = { rate, mc, eta, emptyOn }
    const body = {
      shipmentId
    }

    for (const [key, value] of Object.entries(fields)) {
      switch (key) {
        case 'rate':
          if(value !== null) body.rate = value;
          break;
        case 'mc':
          if(value !== null) body.mc = value;
          break;
        case 'eta':
          if(value !== null) body.eta = value;
          break;
        case 'emptyOn':
          if(value !== null) body.emptyOn = value;          
          break;
      
      }
    }        
      
    const response = await fetch(uri.toString(), this.header({
      method: "POST",
      body: JSON.stringify(body)
    }));
  
    const data = await response.json();
    return data;
  }
  
  async getCandidatesCarriers(shipmentId) {
    const uri = new URL(`${this.baseUrl}/:shipmentId/carriers`.replace(":shipmentId", shipmentId));
    const response = await fetch(uri.toString(), this.header());
    const data = await response.json();
    return data
  }

  async confirmCarrier(carrierId, shipmentId) {    
    const uri = new URL(
      `${this.baseUrl}/:shipmentId/carrier/confirm`.replace(
        ":shipmentId",
        shipmentId
      )
    );
   
    const body = {
      shipmentId
    }  
    body.carrierId = carrierId
      
    const response = await fetch(uri.toString(), this.header({
      method: "POST",
      body: JSON.stringify(body)
    }));
  
    const data = await response.json();
    return data;
  }
}