import { InitialForm } from "./InitialForm/CommonInitialForm";
import { useForm } from "react-hook-form";

export const InitialFormBuilder = ({ onDone }) => {
  const { setValue } = useForm();

  const onChange = (state) => {
    setValue("mode", state.isPalletized && !state.isMoreThan5Pallets ? "LTL" : "TL");

    if (state.isMoreThan5Pallets) setValue("mode", "TL");
  }

  return <InitialForm
    onDone={onDone}
    onChange={onChange}
  />
};
