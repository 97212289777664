import { useState } from "react";
import DeletionDialog from "../components/DeletionConfirmationDialog";

export function useDeletion(handleDelete) {
  const [isDeletionDialogOpen, setIsDeletionDialogOpen] = useState(false);

  const handleOpenClickDeletionDialog = () => {
    setIsDeletionDialogOpen(true);
  };

  const handleCloseDeletionDialog = () => {
    setIsDeletionDialogOpen(false);
  };
  
  return {     
    handleOpenClickDeletionDialog,

    DeletionDialog: 
      <DeletionDialog
        open={isDeletionDialogOpen}
        handleClose={handleCloseDeletionDialog}
        handleDelete={handleDelete}
      />
  }

}