import { useNavigate, useParams } from "react-router-dom";
import { useOrderQueries } from "./hooks/useOrderQueries";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { OrderColumns, OrderDetailColumns } from "./components/OrderColumns";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import { DataTable } from "src/components/datagrid";
import CustomToolbar from "src/components/datagrid/widgets/CustomToolbar";
import { OrderService } from "src/api/orders.service";
import { useAuth } from "src/hooks/useAuth";
import OrderDetailsDrawer from "./components/OrderDetailsDrawer";
import { AddOutlined, CreateOutlined } from "@mui/icons-material";

export function OrdersPage() {
  const { isOrdersLoading, orders, getOrders } = useOrderQueries();
  const { orderId } = useParams();
  const { session } = useAuth();

  const [selectedItemsIds, setSelectedItemsIds] = useState([]);

  const toggleCreateShipmentModalVisibility = async () => {
    return `/shipments/create?orders=${selectedItemsIds ? selectedItemsIds.join(',') : ''}`;
  };

  const [shipmentUrl, setShipmentUrl] = useState('');

  useEffect(() => {
    const generateUrl = async () => {
      const url = await toggleCreateShipmentModalVisibility();
      setShipmentUrl(url);
    };

    if (selectedItemsIds.length > 1) {
      generateUrl();
    }
  }, [selectedItemsIds]);

  useEffect(() => {
    getOrders();
  }, []);

  const CustomToolbarActions = () => {
    return <>
      {selectedItemsIds && selectedItemsIds.length > 1 && (
        <Button
          href={shipmentUrl}
          sx={{ maxHeight: "fit-content" }}
        >
          <AddOutlined sx={{ width: '1.2rem', height: '1.2rem', mr: '0.5rem' }} />
          <Typography sx={{ fontSize: '0.8rem', fontWeight: '800'}}>Create Shipment Selection</Typography>
        </Button>
      )}
    </>;
  }

  return (
    <>
      <Helmet>
        <title>Orders</title>
      </Helmet>
      <Container
        maxWidth="false"
        sx={{ width: "fit-content", maxWidth: "100%" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              mb: 1,
              textAlign: "center",
              display: "flex",
            }}
          >
            Orders
          </Typography>
        </Box>
        <Grid container item xs={12} justifyContent={"center"}>
          <Card>
            <section className="dataTable">
              <DataTable
                selectedItemId={orderId}
                loading={isOrdersLoading}
                loadData={orders ?? []}
                columns={OrderColumns(session)}
                className="datagrid"
                customDrawerComponent={OrderDetailsDrawer}
                pagination
                autoHeight
                density="compact"
                disableDensitySelector
                initState={{
                  columns: {
                    columnVisibilityModel: { id: false },
                  },
                }}
                slots={{
                  toolbar: () => <CustomToolbar
                    columns={OrderColumns}
                    rows={orders}
                    title="Orders"
                    trailing={CustomToolbarActions}
                  />,
                  loadingOverlay: LinearProgress,
                }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    printOptions: { disableToolbarButton: true },
                    quickFilterProps: { debounceMs: 500 },
                    loadData: orders ?? [],
                  },
                }}
                detailsDrawerTitle="Orders"
                detailColumns={OrderDetailColumns(session)}
                modelKey={"orders"}
                modelGridSetup={{}}
                disableMultipleRowSelection={true}
                onRowSelectionModelChange={(selectedItemsIds, details) => {
                  setSelectedItemsIds(selectedItemsIds);
                  console.log(details);
                }}
              />
            </section>
          </Card>
        </Grid>
      </Container>
    </>
  );
}
