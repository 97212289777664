import { colors } from "@mui/material";
import { formatDateWithHour } from "src/utils/formatTime";
const moment = require('moment')

export class Schedule {

  // USED ON FullCalendar API
  // TODO - extended props
  static event(schedule) {
    const start = formatDateWithHour(schedule.date, schedule.start);
    const end = formatDateWithHour(schedule.date, schedule.end);

    const getEventColor = () => {
      switch (schedule.status) {
        case 'approved':
          return '#378006'
        case 'pending':
          return '#ffc400'
        case 'rejected':
          return '#ff3d00'
      }
    }
    
    let title = schedule.title || schedule.owner?.firstname || schedule.warehouse?.name || schedule.company;
    if (schedule.reference?.trim().length > 0) {
      title += ` - ${schedule.reference}`;
    }

    return {
      title: title,
      start,
      end,
      backgroundColor: getEventColor(),
      extendedProps: { ...schedule, eventType: 'appointment' }
    };
  }

  static shipment(shipment) {
    // get the parameters for the event
    const obj = shipment.isInbound ? shipment.stops : shipment.pickups;

    const start = obj.find(() => true)[shipment.isInbound ? 'estArrivalTime' : 'estArrivalTime'];
    const end = obj.find(() => true)[shipment.isInbound ? 'estArrivalTime' : 'estArrivalTime'];

    const getEventColor = () => {
      if (shipment.isInbound) 
        return "blue";
      else 
        return "purple";
    }

    let title = `${shipment.primaryReference} / ${shipment.isInbound ? "IN" : "OUT"} - ${shipment.mode}`;

    return {
      title: title,
      start,
      end,
      backgroundColor: getEventColor(),
      extendedProps: { ...shipment, eventType: 'shipment' }
    };
  }
}     