import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import React from "react";
import { useTenant } from "src/hooks/useTenant";

export function TenantSelector() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const { tenants, tenant, setTenant } = useTenant();

  const handleMenuItemClick = (tenant) => {
    setTenant(tenant);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        ref={anchorRef}
        size="small"
        variant="text"
        aria-controls={open ? "split-button-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-label="create items"
        aria-haspopup="menu"
        onClick={handleToggle}
        disabled={tenants.length == 1}
        endIcon={tenants.length > 1 && <ArrowDropDownIcon fontSize="small" />}
      >
        {tenant.name}
      </Button>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper
              sx={{
                borderRadius: "0.5rem",
              }}
            >
              <Typography sx={{ px: 2, pt: 1 }} color={"GrayText"}>
                Select the Tenant
              </Typography>

              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {tenants?.map((tenant, index) => (
                    <MenuItem
                      key={tenant.code}
                      value={tenant._id}
                      onClick={() => handleMenuItemClick(tenant)}
                      sx={{
                        py: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 1,
                      }}
                    >
                      <Typography>{tenant.name}</Typography>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
