import {
  Box,
  Button,
  Checkbox,
  Stack,
  Tooltip,
  MenuItem,
  FormControlLabel,
  Grid,
} from "@mui/material";
import { FormInput } from "src/components/form/FormInput";
import BackspaceRounded from "@mui/icons-material/BackspaceRounded";
import { Controller, useFieldArray } from "react-hook-form";
import { useWarehouseForm } from "../../hooks/useForm";
import { BuildRounded } from "@mui/icons-material";

export function WarehouseDockFields({ dockIndex = 0 }) {
  const { removeDock, form } = useWarehouseForm();

  const dockTypes = [
    "Flush docks",
    "Dock levelers",
    "Rail docks",
    "Drive-in docks",
    "Drive-through docks",
    "Truck wells",
    "Lift docks",
    "Mobile Dock",
    "Other",
  ];

  const {
    append: appendDockMaintenanceWindow,
  } = useFieldArray({
    control: form.control,
    name: `docks.${dockIndex}.maintenanceWindow`,
  });

  return (
    <Stack spacing={2} mb={1} >
      <Box
        className="maintenance"
        sx={{
          widthL: "100%",
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: {
            md: "row",
            xs: "column",
          }

        }}
      >
        <Grid container columnGap={1} justifyContent='space-between'>
          <Grid item xs={2.5}>
            <FormInput
              label="Dock ID"
              name={`docks.${dockIndex}.dockId`}
              type="text"
              sx={{ width: "100%"}}
            />
          </Grid>
          <Grid item xs={2.5}>
            <FormInput
              label="Door Number"
              name={`docks.${dockIndex}.door`}
              type="number"
              sx={{ width: "100%"}}
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              // sx={{ flex: 1 }}
              name={`docks.${dockIndex}.type`}
              sx={{ width: '100%' }}
              label="Type"
              select
              required
            >
              {dockTypes.map((type, index) => (
                <MenuItem key={index} value={type}>
                  {type}
                </MenuItem>
              ))}
            </FormInput>
          </Grid>

        </Grid>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: '40px'
          }}
        >
          <Controller
            sx={{ flex: 1 }}
            name={`docks.${dockIndex}.disabled`}
            control={form.control}
            defaultValue={false}
            label="Disabled"
            render={({ field }) => (
              <Tooltip title={"Disabled Dock"}>
                <FormControlLabel
                  sx={{
                    "&.MuiFormControlLabel-root": { m: 0 },
                  }}
                  slotProps={{ typography: { fontSize: "0.8rem" } }}
                  control={
                    <Checkbox
                      {...field}
                      checked={!!field.value}
                      sx={{ p: 0 }}
                    />
                  }
                  label="Out of Service?"
                />
              </Tooltip>
            )}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& button": {
                minWidth: "fit-content",
                px: "0.5rem",
              },
            }}
          >
            <Tooltip title={"Remove Dock"}>
              <Button
                size="small"
                onClick={() => removeDock(dockIndex)}
                type="button"
                sx={{
                  p: 0,
                  width: "fit-content",
                }}
              >
                <BackspaceRounded />
              </Button>
            </Tooltip>
            <Tooltip title={"Add Maintenance Window"}>
              <Button
                size="small"
                onClick={() =>
                  appendDockMaintenanceWindow({ start: "", end: "" })
                }
                type="button"
                sx={{
                  p: 0,
                  width: "fit-content",
                }}
              >
                <BuildRounded />
              </Button>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
}
