import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  MenuItem,
  InputLabel,
  Select,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useSnackbar } from "src/hooks/useSnackbar";

import Typography from "@mui/material/Typography";
import { LocationDetails } from "src/components/location-details";
import { Location } from "src/models/location";
import { fToNow } from "src/utils/formatTime";
import { LoadingButton } from "@mui/lab";
import { useState, useEffect } from "react";
import { ScheduleService } from "src/api/schedule.service";
import { StatusChip } from "./StatusChip";
import DeletionDialog from "./DeletionConfirmationDialog";
import { useAuth } from "src/hooks/useAuth";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import GetAppIcon from "@mui/icons-material/GetApp";


export function ScheduleDetails({
  schedule: calendarEvent,
  handleRefreshCalendar,
  onVisibilityChanged,
}) {
  const { extendedProps } = calendarEvent;
  const [schedule, setSchedule] = useState(extendedProps);

  const { setSnackbarMessage } = useSnackbar();

  const { session } = useAuth();
  const service = new ScheduleService(session.token, session.tenant._id);

  const auth = session.permissions;


  const [isLoading, setIsLoading] = useState(false);
  const [dockId, setDockId] = useState(schedule.dockId ?? "");
  const [disposition, setDisposition] = useState(schedule.disposition === "none" ? "" : schedule.disposition);

  const [isDeletionDialogOpen, setIsDeletionDialogOpen] = useState(false);

  const handleOpenClickDeletionDialog = () => {
    setIsDeletionDialogOpen(true);
  };

  useEffect(() => {
    if (schedule.disposition != disposition && !!disposition) {
      new Promise(async (resolve, reject) => {
        try {
          setSnackbarMessage({
            open: true,
            message: "Setting new disposition...",
            severity: "info",
          });

          const result = await service.updateDisposition(schedule._id, {
            "disposition": disposition
          });

          setSnackbarMessage({
            open: true,
            message: "Disposition updated successfully",
            severity: "success",
          });

          setSchedule(schedule => ({ ...schedule, disposition: result.disposition }));
          handleRefreshCalendar();
          resolve(result);
        } catch (error) {
          setSnackbarMessage({
            open: true,
            message: error.message,
            severity: "error",
          });
          reject(error);
        }
      })
    }
  }, [disposition]);


  const handleCloseDeletionDialog = () => {
    setIsDeletionDialogOpen(false);
  };

  const handleApprove = async () => await handleStatusChange("approved");
  const handleReject = async () => await handleStatusChange("rejected");
  const handleStatusChange = async (status) => {
    try {
      setIsLoading(true);
      
      // Call api service
      await service.approveOrReject(schedule._id, status, dockId);

      // Refresh calendar
      handleRefreshCalendar();
      onVisibilityChanged(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteEvent = async () => {
    try {
      setIsLoading(true);
      // Call api service
      await service.delete(schedule._id);

      // Refresh calendar
      handleRefreshCalendar();
      onVisibilityChanged(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteGroup = async () => {
    try {
      setIsLoading(true);
      // Call api service
      await service.deleteGroup(schedule.groupId, schedule.date);

      // Refresh calendar
      handleRefreshCalendar();
      onVisibilityChanged(false);
    } finally {
      setIsLoading(false);
    }
  };

  const allowApproveOrReject = () =>
    schedule.status === "pending" &&
    (auth.IsBackOffice || auth.IsWarehouseManager);

  const scheduleTitle = schedule.company;

  return (
    <><FormControl sx={{ marginTop: '1rem', minWidth: '100%' }}>
      <Select
        value={disposition ?? ""}
        onChange={(event) => setDisposition(event.target.value)}
        displayEmpty
      >
        {!schedule.disposition && (
          <MenuItem value="" disabled>
            There isn't a disposition yet
          </MenuItem>
        )}
        <MenuItem value={'on time'}>On time</MenuItem>
        <MenuItem value={'late'}>Late</MenuItem>
        <MenuItem value={'no show'}>No Show</MenuItem>
      </Select>
    </FormControl><Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        // width: "30vw",
      }}
    >
        <Box
          sx={{ my: "1rem", display: "flex", flexDirection: "column", gap: 2 }}
        >
          <Card
            variant="outlined"
            sx={{
              py: "0.5rem",
              px: "1rem",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "0.75rem",
            }}
          >
            <CardHeader
              title={`${!scheduleTitle ? "N/A" : scheduleTitle}`}
              sx={{
                "&.MuiCardHeader-root": {
                  p: 0,
                },
              }} />

            <Divider />
            <CardContent
              sx={{
                "&.MuiCardContent-root": {
                  p: 0,
                },
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography fontSize={"0.9rem"}>Status:</Typography>
                <StatusChip status={schedule.status} />
              </Box>

              {schedule.reference?.length > 0 && (
                <Box>
                  <Typography fontSize={"0.9rem"}>Reference:</Typography>
                  <Typography variant="subtitle1">
                    {schedule.reference}
                  </Typography>
                </Box>
              )}
              {schedule.note?.length > 0 && (
                <Box>
                  <Typography fontSize={"0.9rem"}>Note:</Typography>
                  <Typography variant="subtitle1">{schedule.note}</Typography>
                </Box>
              )}

              <Divider />

              <Card elevation={0}>
                <CardHeader
                  sx={{
                    "&.MuiCardHeader-root": {
                      p: 0,
                      mb: "0.5rem",
                    },
                  }}
                  subheader={`Appointment ${fToNow(calendarEvent.start)}`} />
                <CardContent
                  sx={{
                    "&.MuiCardContent-root": {
                      p: 0,
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                    },
                  }}
                >
                  {schedule.appointmentType && (
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography>Type:</Typography>
                      <Typography>
                        <strong>{schedule.appointmentType.type}</strong> (
                        {schedule.appointmentType.minutes} min)
                      </Typography>
                    </Box>
                  )}
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography>Date:</Typography>
                    <Typography>{schedule.date.slice(0, 10)}</Typography>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography>Start:</Typography>
                    <Typography>{schedule.start}</Typography>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography>End:</Typography>
                    <Typography>{schedule.end}</Typography>
                  </Box>
                  {schedule.company && (
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography>Company:</Typography>
                      <Typography>{schedule.company}</Typography>
                    </Box>
                  )}
                  {schedule.status === "approved" && (
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography>Approved By:</Typography>
                      <Typography>{schedule.approved.by.email}</Typography>
                    </Box>
                  )}
                </CardContent>
              </Card>
            </CardContent>
          </Card>
          {schedule?.attachments?.length > 0 && (
            <Card
              variant="outlined"
              sx={{
                py: "0.5rem",
                px: "1rem",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "0.75rem",
              }}
            >
              <CardHeader
                title="Attachments"
                sx={{
                  "&.MuiCardHeader-root": {
                    p: 0,
                  },
                }} />
              <List dense>
                {schedule.attachments.map((file, index) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <InsertDriveFileIcon fontSize="12" color="primary" />
                    <ListItem
                      key={index}
                      primary="Inbox"
                      disableGutters
                      sx={{ paddingLeft: "0.3rem" }}
                    >
                      <ListItemText
                        primary={`${file.name.substring(0, 20)}.${file.name
                          .split(".")
                          .pop()}`} />
                      <IconButton
                        aria-label="download"
                        onClick={() => window.open(file.url, "_blank")}
                      >
                        <GetAppIcon fontSize="small" />
                      </IconButton>
                    </ListItem>
                  </div>
                ))}
              </List>
            </Card>
          )}

          <LocationDetails
            location={new Location().fromWarehouse(schedule.warehouse)}
            type={"Warehouse"} />

          {schedule.warehouse.docks.length > 0 && (
            <Card
              variant="outlined"
              sx={{
                py: "0.5rem",
                px: "1rem",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "0.75rem",
              }}
            >
              <CardHeader
                title="Warehouse Dock"
                sx={{
                  "&.MuiCardHeader-root": {
                    p: 0,
                  },
                }} />
              {allowApproveOrReject() && (
                <CardContent
                  sx={{
                    "&.MuiCardContent-root": {
                      p: 0,
                    },
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  <Typography fontSize={"0.9rem"}>
                    Confirm the Dock for this appointment:
                  </Typography>
                  <FormControl>
                    <InputLabel id="dockId">Dock</InputLabel>
                    <Select
                      name="dockId"
                      value={dockId}
                      label="Dock"
                      onChange={(e) => setDockId(e.target.value)}
                    >
                      {schedule.warehouse.docks.map((dock) => (
                        <MenuItem
                          key={dock.dockId}
                          value={dock.dockId}
                          disabled={dock.disabled}
                        >
                          {dock.dockId} / {dock.type}{" "}
                          {dock.disabled && "(Out of Service)"}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </CardContent>
              )}
              {!allowApproveOrReject() && (
                <CardContent
                  sx={{
                    "&.MuiCardContent-root": {
                      p: 0,
                    },
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  {schedule.warehouse.docks.find(
                    (d) => d.dockId === schedule.dockId
                  ) && (
                      <Typography fontSize={"0.9rem"}>
                        {schedule.warehouse.docks.find(
                          (d) => d.dockId === schedule.dockId
                        ).dockId}{" "}
                        /{" "}
                        {schedule.warehouse.docks.find(
                          (d) => d.dockId === schedule.dockId
                        ).type}
                      </Typography>
                    )}
                  {!schedule.warehouse.docks.find(
                    (d) => d.dockId === schedule.dockId
                  ) && <Typography fontSize={"0.9rem"}>Not assigned</Typography>}
                </CardContent>
              )}
            </Card>
          )}
        </Box>

        {allowApproveOrReject() && (
          <Box mt={2} sx={{ display: "flex", gap: 2 }}>
            <LoadingButton
              variant="contained"
              fullWidth
              type="button"
              onClick={handleApprove}
              loading={isLoading}
              sx={{ py: "0.8rem", mt: "1rem", flex: 1 }}
            >
              Approve
            </LoadingButton>
            <LoadingButton
              variant="outlined"
              fullWidth
              type="button"
              onClick={handleReject}
              loading={isLoading}
              sx={{ py: "0.8rem", mt: "1rem", flex: 1 }}
            >
              Reject
            </LoadingButton>
          </Box>
        )}

        {schedule.status === "approved" && (
          <Box mt={2} sx={{ display: "flex", gap: 2 }}>
            <LoadingButton
              variant="outlined"
              fullWidth
              type="button"
              onClick={schedule.groupId
                ? handleOpenClickDeletionDialog
                : handleDeleteEvent}
              loading={isLoading}
              sx={{ py: "0.8rem", mt: "1rem", flex: 1 }}
            >
              Delete Appointment
            </LoadingButton>
          </Box>
        )}

        <DeletionDialog
          open={isDeletionDialogOpen}
          handleClose={handleCloseDeletionDialog}
          handleDeleteEvent={handleDeleteEvent}
          handleDeleteGroup={handleDeleteGroup} />
      </Box></>
  );
}
