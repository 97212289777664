import {
    capitalize
} from "lodash";

export function getPONumberLabel(tenant) {
    if (tenant.code === 'SPI') return 'Alfred ID';
    if (tenant.code === 'COX') return 'COX PO Number';
    else return 'PO Number';
}

export function getEquipmentFromShipment(shipment) {
    if (shipment.mode === 'LTL') return 'LTLVan';

    if (!shipment.equipment) return undefined;

    const tmp = shipment.equipment;
    delete tmp._id;

    return capitalize(Object.keys(tmp).find(value => tmp[value]));
}

export function getUploadablePackageSlips(shipment) {
    const result = [];

    const checkNeedsUpload = (item) => item.packageSlip && !item.packageSlip.url;;
    const addPackageSlip = (item, index) => result.push({
        itemId: item.itemId,
        index,
        file: item.packageSlip,
    });

    const digestItems = (items, clean) => items.forEach((item, index) => {
        const isPackageSlipNotUploaded = checkNeedsUpload(item);
        if (isPackageSlipNotUploaded) {
            if (!clean) addPackageSlip(item, index);
            delete item.packageSlip;
        }
    });

    if (shipment.items) digestItems(shipment.items);
    else {
        digestItems(shipment.pickups[0].items);
        digestItems(shipment.stops[0].items);
    }

    return result;
}

export function getUploadableAttachment(shipment) {
    const result = [];
    const checkNeedsUpload = (shipment) => shipment.attachment && !shipment.attachment.url;;
    const isPackageSlipNotUploaded = checkNeedsUpload(shipment);

    if (isPackageSlipNotUploaded) {
        result.push({
            file: shipment.attachment
        });
        delete shipment.attachment;
    }

    return result;
}

export function getProNumber(shipment) {
    let trackingNumber = null;
    if (shipment.mode === 'LTL') {
        trackingNumber = shipment.tracking?.ProNumber;
    } else {
        const integration = shipment.integrations?.find(integration => integration?.metadata?.qeId);
        trackingNumber = integration?.metadata?.qeId;
    }
    return trackingNumber;
}