import {
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useAuth } from "src/hooks/useAuth";
import { useReportQueries } from "./hooks/useReportQueries";
import { useEffect } from "react";
import { ReportCardLoading } from "./components/ReportCardLoading";
import { ReportCard } from "./components/ReportCard";

export function ReportsPage() {
  const { session } = useAuth();

  const { userReports, isLoadingUserReports, getUserReports } = useReportQueries();
  useEffect(() => { getUserReports() }, [])

  return (
    <>
      <Helmet>
        <title> Reports | {session.tenant.title} </title>
      </Helmet>
      <Container maxWidth="false">
        <Typography
          variant="h4"
          sx={{ mb: 1, mt: 3, textAlign: "center", display: "flex" }}
        >
          Reports
        </Typography>
        <Box sx={{ my: 4, display: { md: "none" } }} />
        <Grid container gap={2}>
          {isLoadingUserReports ? (
            Array.from({ length: 5 }).map((index) => <ReportCardLoading key={index} />)
          ) : userReports?.length ? (
            userReports?.map((report, index) =><ReportCard key={report.type + index} report={report}/>)
          ) : (
            <Paper
              sx={{
                py: "0.5rem",
                px: "1rem",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant="overline" color="GrayText">
                {" "}
                No Reports avaliable for this user
              </Typography>
            </Paper>
          )}
        </Grid>
      </Container>
    </>
  );
}