import config from "src/config";
import {
    BaseService
} from "./base.service";

const baseUrl = config.apiUrl;
const prefix = "/vendors";
const getAllVendors = `${baseUrl}/relationships${prefix}`;
const vendorById =  `${baseUrl}${prefix}/:vendorId`;
const deleteVendor =  `${baseUrl}${prefix}/:vendorId`;
const updateVendor =  `${baseUrl}${prefix}/:vendorId`;
const createVendor = `${baseUrl}${prefix}`;

export class VendorService extends BaseService {

    getAll = async (unrestricted) => {
        const uri = new URL(getAllVendors, window.location.origin)

        if (unrestricted) {
          uri.searchParams.append('unrestricted', 'true');
        }        

        const response = await fetch(uri.toString(), this.header());
        const data = response.json()

        if (response.ok)
            return data

        if (response.status === 401)
            throw new Error(data?.message || response.statusText);
    }

    async getVendorById(vendorId) {        
      const uri = new URL(vendorById.replace(":vendorId", vendorId));
      const response = await fetch(uri.toString(), this.header());        
      return await response.json();
      }
       
    async createVendor(data) {
        const uri = new URL(createVendor);
        const response = await fetch(
          uri.toString(), 
          this.header({
            method: "POST",
            body: JSON.stringify(data),
          })
        );
        
        return await response.json();
      }   

      updateVendor = async (vendorId, data) => {
        const uri = new URL(updateVendor.replace(':vendorId', vendorId));  
        data._id = vendorId;
        const response = await fetch(
          uri.toString(), 
          this.header({
            method: 'PUT',
            body: JSON.stringify(data)
          })
        );

        console.log(data)
        return await response.json();        
      }

     async deleteVendor(vendorId) {
      const uri = new URL(deleteVendor.replace(':vendorId', vendorId));
      console.log(vendorId)
        const response = await fetch(
          uri.toString(),
          this.header({
            method: "DELETE",
            body: JSON.stringify({vendorId})
          })
      );      

       return await response;
     }
}