import {
  ContactPageOutlined,
  DescriptionOutlined,
  TimelapseRounded,
} from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { TabList } from "@mui/lab";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LocationDetails } from "src/components/location-details";
import { ContactsDetails } from "src/pages/location/components/ContactDetails";

import { CloseOutlined } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  Grid,
  IconButton,
  Stack,
  Tab,
  Toolbar,
  Typography,
} from "@mui/material";
import { TenantModules } from "./Modules";
import { CopyButton, TenantIntegrations } from "./Integrations";
import { TenantDetailsProvider } from "../context/TenantDetailsProvider";
import { BillingInfo } from "./BillingInfo";
import { TenantContactDetails } from "../ContactDetails";

const tabs = [
  {
    title: "Basic Info",
    icon: <DescriptionOutlined />,
  },
  {
    title: "Billing Address",
    icon: <TimelapseRounded />,
  },
  {
    title: "Contacts",
    icon: <ContactPageOutlined />,
  },
];

export const TenantDetailsDrawer = ({
  selectedItem: tenant,
  onVisibilityChanged,
  onEditTapped,
}) => {
  const navigate = useNavigate();

  const [state, setState] = useState({
    selectedTab: "0",
    isOpen: false,
  });

  const onTabSelected = (event, tabIndex) => {
    setState({ ...state, selectedTab: tabIndex });
  };

  const toggleDrawer = (isVisible) => {
    setState({ ...state, isOpen: isVisible });
    onVisibilityChanged(isVisible);

    navigate("/tenants");
  };

  useEffect(() => {
    setState({ ...state, isOpen: !!tenant });
    console.log(tenant);
  }, [tenant]);

  return (
    <TenantDetailsProvider tenant={tenant}>
      <Dialog
        title={tenant.name}
        open={state.isOpen}
        onClose={() => toggleDrawer(false)}
        fullWidth
        fullScreen
        sx={{
          mt: "2rem",
          mx: {
            xs: "0",
            md: "7rem",
          },
        }}
        PaperProps={{ sx: { borderRadius: "1rem 1rem 0 0", maxWidth: "sm" } }}
      >
        <AppBar sx={{ position: "relative", py: "0.7rem" }}>
          <Toolbar
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => toggleDrawer(false)}
                  aria-label="close"
                >
                  <CloseOutlined />
                </IconButton>
                <Box>
                  <Typography fontWeight={600} fontSize={"1.2rem"}>
                    {tenant.name}
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "0.5rem",
                      alignItems: "baseline",
                    }}
                  >
                    <Typography variant="caption">Code:</Typography>
                    <Typography sx={{ fontWeight: 600 }} variant="caption">
                      {tenant?.code}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <IconButton
              onClick={() => onEditTapped(tenant)}
              sx={{ p: 2, color: "white" }}
            >
              <EditIcon color="inhiret" />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Card
          sx={{
            py: "0.5rem",
            px: "1rem",
            display: "flex",
            flexDirection: "column",
            gap: "0.75rem",
            m: 2,
          }}
        >
          <CardHeader
            component={() => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", xl: "row" },
                  justifyContent: "space-between",
                  py: "0.25rem",
                  gap: "0.6rem",
                }}
              >
                <Box>
                  <Typography
                    variant="h1"
                    fontWeight={600}
                    sx={{
                      "&.MuiTypography-h1": {
                        fontSize: "1.5rem",
                      },
                    }}
                  >
                    Tenant Details
                  </Typography>
                </Box>
              </Box>
            )}
            sx={{
              "&.MuiCardHeader-root": {
                p: 0,
              },
            }}
          />
          <CardContent
            sx={{
              "&.MuiCardContent-root": {
                p: 0,
              },
            }}
          >
            <Grid container rowGap={2}>
              <Grid item xs={6}>
                <BillingInfo/>
              </Grid>
              <Grid item xs={6}>
                <TenantContactDetails/>
              </Grid>
              <Grid item xs={12}>
                <TenantModules modules={tenant?.modules} />
              </Grid>
              <Grid item xs={12}>
                <TenantIntegrations/>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Dialog>
    </TenantDetailsProvider>
  );
};

function TenantDetailsTabs({ onChange }) {
  return (
    <TabList
      onChange={onChange}
      variant="scrollable"
      scrollButtons={true}
      allowScrollButtonsMobile
    >
      {tabs.map((item, index) => {
        return (
          <Tab
            icon={item.icon}
            iconPosition="start"
            label={item.title}
            value={index.toString()}
            key={item.title}
          />
        );
      })}
    </TabList>
  );
}
