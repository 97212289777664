const { Card, CardHeader, Button, Link, Skeleton, Box } = require("@mui/material");

export const ReportCardLoading = () => {
    return <Card
        variant="outlined"
        sx={{
            borderRadius: "12px",
            padding: "24px",
            height: "180px",
            width: "260px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
        }}
    >
        <Box>
            <Skeleton variant="text" width={'12rem'} />
            <Skeleton variant="text" width={'12rem'} />
        </Box>
        <Skeleton variant="rectangular" width={'12rem'} />
    </Card>;
}