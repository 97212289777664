import {
  linearProgressClasses
} from "@mui/material";

/**
 * Support Links - 3 Cases
 * 1. Storage File to be Downloaded:
 *  - By `fileName`
 * 2. TODO - Support Page & External contact button
 *  - By `url`
 * */
export const supportVideoTutorialLinks = (session) => {
  const isWarehouseManager = session.permissions.IsWarehouseManager || session.permissions.IsBackOffice;

  const links = [{
    title: "How to Create Shipment",
    videoPath: `Users/Knowledge Base/videos/${isWarehouseManager ? 'Create Shipment Warehouse Manager.mp4' : 'Create Shipment Supplier.mp4'}`,
    thumbnail: `${isWarehouseManager ? 'https://usaprloadmanageruseasta.blob.core.windows.net/public/thumbnails/6%20CREATE%20SHIPMENT%20SUPPLIER.png' : 'https://usaprloadmanageruseasta.blob.core.windows.net/public/thumbnails/6%20CREATE%20SHIPMENT%20SUPPLIER.png'}`
  }];


  if (session.permissions.IsWarehouseManager || session.permissions.IsBackOffice) {
    links.push(...[{
        title: "How to Create Appointment",
        videoPath: isWarehouseManager ? `Users/Knowledge Base/videos/Create Appointment Warehouse Manager.mp4` : null,
        thumbnail: 'https://usaprloadmanageruseasta.blob.core.windows.net/public/thumbnails/3%20CREATE%20APPOINTMENT%20MANAGE.png'
      },
      {
        title: "How to Update Warehouse",
        videoPath: `Users/Knowledge Base/videos/Updating Warehouse.mp4`,
        thumbnail: 'https://usaprloadmanageruseasta.blob.core.windows.net/public/thumbnails/5%20UPDATING%20WAREHOUSE.png'
      },
      {
        title: "How to Use Calendar",
        videoPath: `Users/Knowledge Base/videos/Calendar.mp4`,
        thumbnail: 'https://usaprloadmanageruseasta.blob.core.windows.net/public/thumbnails/4%20CALENDAR.png'
      },
    ]);
  }

  links.push(...[{
      title: "How to Log In",
      videoPath: `Users/Knowledge Base/videos/How to log in.mp4`,
      thumbnail: 'https://usaprloadmanageruseasta.blob.core.windows.net/public/thumbnails/1%20HOW%20TO%20LOGIN.png'
    },
    {
      title: "How to Change Password",
      videoPath: `Users/Knowledge Base/videos/Change Password.mp4`,
      thumbnail: 'https://usaprloadmanageruseasta.blob.core.windows.net/public/thumbnails/2%20HOW%20TO%20CHANGE%20PASSWORD.png'
    },
  ])


  return links;
}


export const supportDocsLinks = (session) => {
  const isWarehouseManager = session.permissions.IsWarehouseManager || session.permissions.IsBackOffice;
  
  const links = [{
    title: "How to Create Shipment",
    fileName: "Users/Knowledge Base/How To Create a Shipment 1.3.pdf",
    description: "Required fields and specification on how to create a Shipment",
  }];

  if (isWarehouseManager) {
    links.push({
      title: "How to Create Appointment",
      fileName: "Users/Knowledge Base/How To Create an Appointment.pdf",
      description: "Required fields and specification on how to create a Appointments, recurrences and calendar views.",
    });
  }

  return links;
};