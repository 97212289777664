import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import { valueGetter } from "./DetailsDrawer";
import { ChevronRight } from "@mui/icons-material";

export function WarehouseBasicInfo({ warehouse, setSelectedTabIndex }) {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        gap: "0.75rem",
        padding: "1rem",
      }}
    >
      {/* BASIC INFO */}
      <Card
        variant="outlined"
        sx={{
          py: "0.5rem",
          px: "1rem",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "0.75rem",
        }}
      >
        {/* <CardHeader
        component={()=>(
          <>
            <Typography variant="h6" fontWeight={600}>Location</Typography>
            <Divider/>
          </>
        )}
        /> */}
        <CardContent
          sx={{
            "&.MuiCardContent-root": {
              p: 0,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              py: "0.25rem",
            }}
          >
            <Typography fontWeight={600}>Address</Typography>
            <Typography>{valueGetter(warehouse.location)}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              py: "0.25rem",
            }}
          >
            <Typography fontWeight={600}>City</Typography>
            <Typography>{valueGetter(warehouse.city)}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              py: "0.25rem",
            }}
          >
            <Typography fontWeight={600}>State</Typography>
            <Typography>{valueGetter(warehouse.state)}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              py: "0.25rem",
            }}
          >
            <Typography fontWeight={600}>Timezone</Typography>
            <Typography>{valueGetter(warehouse.timezone)}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              py: "0.25rem",
            }}
          >
            <Typography variant="caption">
              Is {warehouse.daylightSaving ? "" : " not "} Daylight Saving{" "}
            </Typography>
          </Box>
        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{
          py: "0.5rem",
          px: "1rem",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "0.75rem",
        }}
      >
        <CardHeader
          title={`${warehouse.docks?.length} Docks`}
          action={(
            <Button onClick={()=> setSelectedTabIndex('1')} ><ChevronRight/></Button>
          )}
          sx={{
            "&.MuiCardHeader-root": {
              p: 0,
            },
          }}
        />
      </Card>

      <Typography fontWeight={600}>Contacts</Typography>
      <Stack sx={{
        gap: 2
      }}>
        {warehouse.contacts &&
          warehouse.contacts.map((contact, index) => {
            return (
              <Card
                key={index}
                variant="outlined"
                sx={{
                  py: "0.5rem",
                  px: "1rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.75rem",
                }}
              >
                <CardHeader
                  sx={{
                    "&.MuiCardHeader-root": {
                      p: 0,
                    },
                  }}
                />
                <CardContent
                  sx={{
                    "&.MuiCardContent-root": {
                      p: 0,
                    },
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        py: "0.25rem",
                      }}
                    >
                      <Typography fontWeight={600}>Name</Typography>
                      <Typography>{valueGetter(contact.name)}</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        py: "0.25rem",
                      }}
                    >
                      <Typography fontWeight={600}>E-mail</Typography>
                      <Typography>{valueGetter(contact.email)}</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        py: "0.25rem",
                      }}
                    >
                      <Typography fontWeight={600}>Phone</Typography>
                      <Typography>{valueGetter(contact.phone)}</Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            );
          })}
      </Stack>

      {/* ATTACHMENTS */}
      {/* <ShipmentAttachments /> */}
    </Box>
  );
}
