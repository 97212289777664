import config from "src/config.js";
import { BaseService } from "./base.service";

const baseUrl = config.apiUrl;
const prefix = "/users";
const getAllUsers = prefix;
const userById = prefix + "/:userId";
const createUser = "/auth/signup";

export class UserService extends BaseService {
  async getUsers() {
    const response = await fetch(baseUrl + getAllUsers, this.header());

    return await response.json();
  }

  async getUsersCompanies(warehouseId) {
    const uri = new URL(baseUrl + "/users/companies");
    uri.searchParams.append("warehouseId", warehouseId);
    const response = await fetch(uri.toString(), this.header());

    return await response.json();
  }
  async getUserById(userId) {
    const response = await fetch(
      baseUrl + userById.replace(":userId", userId),
      this.header()
    );

    return await response.json();
  }

  async postUser(data) {
    const response = await fetch(
      baseUrl + createUser,
      this.header({
        method: "POST",
        body: JSON.stringify(data),
      })
    );

    return await response.json();
  }

  async patchUser(userId, data) {
    const response = await fetch(
      baseUrl + userById.replace(":userId", userId),
      this.header({
        method: "PATCH",
        body: JSON.stringify(data),
      })
    );

    return await response.json();
  }

  async deleteUser(userId) {
    const response = await fetch(
      baseUrl + userById.replace(":userId", userId),
      this.header({
        method: "DELETE",
      })
    );

    return await response;
  }
}
