import { Container, Paper, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { TenantForm } from "./components/TenantForm";
import { useTenantQueries } from "./hooks/useTenantQueries";

export function CreateTenantPage() {
  const {
    createTenant,
    isCreationLoading,
  } = useTenantQueries();

  return (
    <Paper
      sx={{
        maxWidth: {
          lg: "1000px",
          xs: "90%",
        },
        margin: "auto",
      }}
      elevation={3}
    >
      <Helmet>
        <title>Create Tenant</title>
      </Helmet>
      <TenantForm
        type="Create"
        onSave={createTenant}
        isSubmissionLoading={isCreationLoading}
      />
    </Paper>
  )
}