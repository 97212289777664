export class SubItem {
  constructor(subItemData) {
    this.itemId = subItemData.itemId;
    this.actualQty = subItemData.actualQty;
    this.actualQtyUOM = subItemData.actualQtyUOM;
    this.actualWeight = subItemData.actualWeight;
    this.actualWeightUOM = subItemData.actualWeightUOM;
    this.actualWeightCountType = subItemData.actualWeightCountType;
    this.actualLength = subItemData.actualLength;
    this.actualWidth = subItemData.actualWidth;
    this.actualHeight = subItemData.actualHeight;
    this.actualDimensionsUOM = subItemData.actualDimensionsUOM;
    this.actualValue = subItemData.actualValue;
    this.description = subItemData.description;
    this._id = subItemData._id;
  }
}
