import { KeyboardArrowDown } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from "@mui/material";

export function FormAccordion({ title, fontWeight, fontSize, defaultExpanded = true, children, ...props }) {
  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      {...props}
      sx={{
        width: "100%",
        "&.Mui-expanded": { margin: 0 },
        "&.MuiAccordion-root::before": { display: "none" },
        border: props.border,
        ...props.sx
      }}
    >
      <AccordionSummary expandIcon={<KeyboardArrowDown/>} >
        {
          props.header ? props.header :
            <Typography fontWeight={fontWeight} fontSize={fontSize}>{title}</Typography>
        }
      </AccordionSummary>
      <AccordionDetails sx={{ ...(props?.AccordionDetailsProps?.sx || {}) }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}