import { Box, Typography } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import { ChangeNoteService } from "src/api/changeNote.service";
import { useAuth } from "src/hooks/useAuth";
import { useSnackbar } from "src/hooks/useSnackbar";

export function useChangeNoteQueries() {
    const { session } = useAuth();
    const service = new ChangeNoteService(session?.token, session?.tenant?._id);
    const { setSnackbarMessage } = useSnackbar();

    const { mutate: newNote, isLoading: isCreationLoading } = useMutation({
        mutationFn: (values) =>
            service
                .newNote(values)
                .then((data) => {
                    setSnackbarMessage({
                        message: `New note created`,
                        severity: "success",
                    });

                    return data;
                }).catch((error) => {
                    setSnackbarMessage({
                        message: (
                            <Box>
                                <Typography mb={1}>Error creating note</Typography>
                                <Typography>{error.message}</Typography>
                            </Box>
                        ),
                        severity: "error",
                    });
                }),
    });

    const { mutate: updateNote, isLoading: isUpdatingLoading } = useMutation({
        mutationFn: (values) =>
            service
                .updateNote(session.user.id, values)
                .then((data) => {
                    setSnackbarMessage({
                        message: `Note updated`,
                        severity: "success",
                    });
                    getChangeNotes();

                    return data;
                }).catch((error) => {
                    setSnackbarMessage({
                        message: (
                            <Box>
                                <Typography mb={1}>Error update note</Typography>
                                <Typography>{error.message}</Typography>
                            </Box>
                        ),
                        severity: "error",
                    });
                }),
    });

    const {
        isLoading: isLoadingChangeNotes,
        isRefetching: isRefetchingChangeNotes,
        data: changeNotes,
        refetch: getChangeNotes,
    } = useQuery({
        queryFn: async () => {
            return service.getAllNotes();
        },
        enabled: false
    });

    const {
        isLoading: isLoadingLastChangeNotes,
        isRefetching: isRefetchingLastChangeNotes,
        data: lastChangeNotes,
        refetch: getLastChangeNotes,
    } = useQuery({
        queryFn: async () => {
            return service.getLastChangeNote();
        },
        enabled: false
    });

    const {
        isLoading: isDeletingNote,
        mutate: deleteNote,
    } = useMutation({
        mutationFn: (idNote) => {
            service
                .deleteNote(idNote)
                .then((data) => {
                    if (data) {
                        setSnackbarMessage({
                            message: 'Note was deleted with success!',
                            severity: 'success',
                        });
                        getChangeNotes();
                        return data;
                    }
                })
                .catch((error) => {
                    setSnackbarMessage({
                        message: (
                            <Box>
                                <Typography mb={1}>Error to deleting note</Typography>
                                <Typography>{error.message}</Typography>
                            </Box>
                        ),
                        severity: "error",
                    });
                })
        }
    });

    const handleDelete = (idNote) => {
        deleteNote(idNote);
    }

    return {
        newNote,
        isCreationLoading,

        isLoadingChangeNotes,
        isRefetchingChangeNotes,
        changeNotes,
        getChangeNotes,

        isDeletingNote,
        handleDelete,

        updateNote,
        isUpdatingLoading,

        isLoadingLastChangeNotes,
        isRefetchingLastChangeNotes,
        lastChangeNotes,
        getLastChangeNotes,
    }
}