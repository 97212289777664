import React from 'react';
import { Box, Button, Container, LinearProgress, Typography } from '@mui/material';
import { ForgotPasswordFormProvider } from './context/ForgotPasswordFormContext';
import { useForgotPasswordNoteQueries } from './hooks/useForgotPasswordQueries';
import { FormInput } from 'src/components/form/FormInput';

export default function ForgotPassword() {
    const {
        forgotPassword,
        isSendingEmailResetPassword,
    } = useForgotPasswordNoteQueries();

    return <Container maxWidth="sm" sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        flexDirection: 'column',
    }}>
        <Box>
            <Typography variant="h4" gutterBottom>
                Forgot Your Password?
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
                Don't worry! Just enter your email address and we'll send you a link to reset your password.
            </Typography>
            <ForgotPasswordFormProvider
                onSend={forgotPassword}
                useFormParams={{ defaultValues: null }}
            >
                <FormInput
                    label="Enter your email"
                    name={"email"}
                    type="email"
                    required
                    fullWidth
                />
                {
                    isSendingEmailResetPassword ? <LinearProgress sx={{marginTop: "24px"}} /> : <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        type="submit"
                        sx={{ mt: 2, width: '100%' }}
                    >
                        Send Reset Link
                    </Button>
                }
            </ForgotPasswordFormProvider>
            <Typography variant="body2" color="textSecondary" mt={3}>
                If you don’t receive an email, please check your spam folder or contact support.
            </Typography>
        </Box>
    </Container>
}