import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

export function useProfileForm(props) {
  const useFormParams = props?.useFormParams;

  const form = useForm({defaultValues: useFormParams && useFormParams});

  const {
    formState: { errors, isSubmitSuccessful },
    reset,
    handleSubmit,
    setError,
    clearErrors
  } = form;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isSubmitSuccessful) {
      props.reset && reset();
    }
  }, [isSubmitSuccessful, reset, errors]);

  const onSubmitHandler = async (values) => {
    setIsLoading(true);
    try {
      const data = await props.onSave(values);
      if (data.error) {
        updateErrors(data.errors, setError);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    form,
    handleSubmit,
    onSubmitHandler,
    setError,
    clearErrors
  }
}

function updateErrors(errors, setError) {
  errors.forEach((error) => {
    setError(error.param, { type: "manual", message: error.msg });
  })
}
