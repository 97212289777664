// TODO refactor to a base URL and move it to the .env file
import config from "src/config.js";

const baseUrl = config.apiUrl;
const getAllShipmentsEndpoint = "/shipment";
const getShipmentEndpoint = "/shipment/{id}";

export const getShipments = async (token, filter) => {
  const uri = new URL(baseUrl + getAllShipmentsEndpoint);
  if (filter) uri.searchParams.append("filter", filter);

  const response = await fetch(uri.toString(), {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  // return responseMapper(await response.json());
  return await response.json();
};

export const getShipment = async (
  shipmentId,
  token
) => {
  const response = await fetch(
    baseUrl + getShipmentEndpoint.replace("{id}", shipmentId), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  // return responseMapper(await response.json());
  return await response.json();
};