import { createContext, useEffect } from "react";
import { useWarehouseFormHook } from "../hooks/useWarehouseFormHook";
import { FormProvider } from "react-hook-form";
import { Box } from "@mui/material";

export const WarehouseFormContext = createContext(null);

export function WarehouseFormProvider({ children, ...props }) {
  const useFormParams = props.useFormParams;
  const onSave = props.onSave;
  const reset = props.reset || false;

  const {
    isLoading,
    form,
    handleSubmit,
    onSubmitHandler,
    setError,
    clearErrors,
    mapFields,
    ...rest
  } = useWarehouseFormHook({ useFormParams, onSave, reset })

  useEffect(() => {
    if (useFormParams?.defaultValues) {
      mapFields(useFormParams.defaultValues);
    }
  }, [useFormParams]);

  return (
    <WarehouseFormContext.Provider
      value={{
        isLoading,
        form,
        handleSubmit,
        onSubmitHandler,
        setError,
        clearErrors,
        mapFields,
        ...rest,
      }}
    >
      <FormProvider {...form}>
        <Box
          component="form"
          autoComplete="off"
          onSubmit={(event) => {
            clearErrors();
            handleSubmit(onSubmitHandler)(event);
          }}
          sx={props.sx}
        >
          { children }
        </Box>
      </FormProvider>
    </WarehouseFormContext.Provider>
  );
}
