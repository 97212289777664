import {
  AttachFileOutlined,
  CloudUpload,
  CloudUploadOutlined,
  TaskAltOutlined,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { useEffect, useRef, useState, setValue } from "react";
import { useShipmentQueries } from "../hooks/useShipmentQueries";
import { useAuth } from "src/hooks/useAuth";
import { DeletePackageSlipFileButton } from "./DeleteFilePackageSlipButton";
import { useSnackbar } from "src/hooks/useSnackbar";
import { Controller, useFormContext } from "react-hook-form";
import { usePackageForm } from "../context/PackageContext";
import { FileUtils } from "src/utils";

export function UploadPackageSlipInput({
  shipmentId,
}) {
  const {
    isUploadPackageSlipLoading,
    uploadPackageSlipFile,
    isUploadPackageSlipSuccess,
    updatedPackageSlip,
    getShipment,
    isShipmentLoading,
  } = useShipmentQueries();
  const { setValue, getValues, watch } = useFormContext();
  
  const { packageIndex } = usePackageForm();

  const packageSlip = watch(`items.${packageIndex}.packageSlip`) !== null ? watch(`items.${packageIndex}.packageSlip`) : false;
  const packageSlipUrl = watch(`items.${packageIndex}.packageSlip.url`) ? watch(`items.${packageIndex}.packageSlip.url`) : false;

  const [allowUpload, setAllowUpload] = useState(packageSlip);
  const { setSnackbarMessage } = useSnackbar();
  const { session } = useAuth();
  const auth = session.permissions;

  const fileInputRef = useRef(null);
  const openFileSelection = () => fileInputRef.current.click();

  const handleUpload = () => {
    uploadPackageSlipFile({
      shipmentId,
      file: packageSlip,
      itemId: getValues(`items.${packageIndex}.itemId`),
      onUploaded: (data) => {
        if (data.errors) {
          setSnackbarMessage({
            message: <Box> Error submitting package slip file.</Box>,
            severity: "error",
          });
        } else {
          setSnackbarMessage({
            message: <Box> Success to upload package slip file </Box>,
            severity: "success",
          });
        }
      },
      onFailed: (err) =>
        setSnackbarMessage({
          message: <Box> Error to upload package file: {err.message}</Box>,
          severity: "error",
        }),
    });
  };

  useEffect(() => {
    if (isUploadPackageSlipSuccess && updatedPackageSlip) {
      setValue(`items.${packageIndex}.packageSlip`, updatedPackageSlip?.pickups[0]?.items[0]?.packageSlip);
    }
  }, [updatedPackageSlip]);

  const toggleAllowUpload = () => {
    setValue(`items.${packageIndex}.packageSlip`, null);
    setValue(`items.${packageIndex}.packageSlip.url`, null);
    setAllowUpload(!allowUpload);
  };

  useEffect(() => {
    if (shipmentId) {
      getShipment(shipmentId);
    }
  }, []);

  return (
    <Box
      component="form"
      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
    >
      {/* Hidden file input */}
      <Controller
        name={`items.${packageIndex}.packageSlip`}
        render={({ field: { onChange, value } }) => (
          <input
            accept=".jpg,.jpeg,.png,.pdf,.xls,.xlsx"
            id="file-upload"
            type="file"
            style={{ display: "none" }}
            onChange={(e) => {
              onChange(e.target.files[0]);
              setValue(`items.${packageIndex}.packageSlip`, e.target.files[0]);
            }}
            ref={fileInputRef}
          />
        )}
      />

      {/* IconButton to trigger file input */}
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        {!packageSlip && !packageSlipUrl && (
          <label htmlFor="file-upload">
            <Tooltip title={`Select package slip file`}>
              <LoadingButton
                loading={isUploadPackageSlipLoading}
                type="button"
                sx={{
                  display: "flex",
                  width: "13rem",
                  px: 1,
                  gap: 2,
                  borderRadius: "0.5rem",
                  fontWeight: 800,
                  boxShadow: 1,
                }}
                onClick={() => fileInputRef.current.click()}
              >
                <CloudUploadOutlined />
                <Typography
                  variant="button"
                  sx={{ width: "100%", textAlign: "left" }}
                >
                  Upload Package Slip
                </Typography>
              </LoadingButton>
            </Tooltip>
          </label>
        )}
        {packageSlip && !packageSlip.url && !isUploadPackageSlipSuccess && (
          <Box sx={{ display: "flex", gap: 1 }}>
            <Tooltip title={`Select Another file`}>
              {shipmentId ? (
                <Button
                  sx={{ px: "0.5rem", minWidth: "fit-content" }}
                  onClick={() => {
                    openFileSelection();
                  }}
                >
                  {/* <Backspace /> */}
                  <AttachFileOutlined />
                </Button>
              ) : (
                <Button
                  sx={{ px: "0.5rem", minWidth: "fit-content" }}
                  onClick={() => {
                    openFileSelection();
                  }}
                >
                  {/* <Backspace /> */}
                  <AttachFileOutlined />
                  Change File
                </Button>
              )}
            </Tooltip>
            <Tooltip title={`Upload ${packageSlip.name}`}>
              {shipmentId && (
                <LoadingButton
                  loading={isUploadPackageSlipLoading || isShipmentLoading}
                  type="button"
                  sx={{
                    display: "flex",
                    width: "10rem",
                    px: 1,
                    gap: 2,
                    borderRadius: "0.5rem",
                    fontWeight: 800,
                    boxShadow: 1,
                  }}
                  onClick={() => {
                    handleUpload();
                  }}
                >
                  <CloudUpload />
                  <Typography
                    variant="button"
                    sx={{ width: "100%", textAlign: "left" }}
                  >
                    Send File
                  </Typography>
                </LoadingButton>
              )}
            </Tooltip>
          </Box>
        )}
        {isUploadPackageSlipSuccess && (
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              sx={{
                display: "flex",
                width: "14rem",
                px: 1,
                gap: 2,
                borderRadius: "0.5rem",
                fontWeight: 800,
                boxShadow: 1,
                "&.Mui-disabled": {
                  color: (theme) => theme.palette.primary.main,
                },
              }}
              disabled
            >
              <TaskAltOutlined />
              <Typography
                variant="button"
                sx={{ width: "100%", textAlign: "left" }}
              >
                Package slip Uploaded
              </Typography>
            </Button>
          </Box>
        )}
        {packageSlipUrl && !isUploadPackageSlipSuccess && (
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              href={packageSlipUrl}
              sx={{
                display: "flex",
                width: "13rem",
                px: 1,
                gap: 2,
                borderRadius: "0.5rem",
                fontWeight: 800,
                boxShadow: 1,
              }}
            >
              Download Package Slip
            </Button>
            {(auth.IsBackOffice || auth.IsLTLManager) && (
              <Tooltip title="Remove current File">
                <DeletePackageSlipFileButton
                  shipmentId={shipmentId}
                  itemId={getValues(`items.${packageIndex}.itemId`)}
                  onFileDeleted={() => { toggleAllowUpload() }}
                />
              </Tooltip>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}
