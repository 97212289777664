import { z } from "zod";

export class Hazardous {
  static get createSchema() {
    return z.object({
      hazardous: z.boolean(),
      hazMatId: z.string(),
      hazMatClass: z.number(),
      hazMatGroup: z.string(),
      hazMatContactName: z.string(),
      hazMatContactPhone: z.string(),
      properShippingName: z.string(),
    });
  }
}