import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormInput } from "src/components/form/FormInput";
import { ChangeNoteFormProvider } from "../context/ChangeNoteFormContext";
import { useChangeNoteQueries } from "../hooks/useChangeNoteQueries";
import { LoadingButton } from "@mui/lab";
import { StandardDialog } from "src/components/dialog/Dialog";
import { MdEditor } from "md-editor-rt";
import { Controller, useFormContext } from "react-hook-form";
import "md-editor-rt/lib/style.css";

export function ChangeNoteForm(props) {
  const { newNote, isCreationLoading, updateNote, isUpdatingLoading } =
    useChangeNoteQueries();
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleClose = () => {
    props.onClose();
  };

  return (
    <Box sx={{ maxWidth: "40%", margin: "auto", py: 3 }}>
      <Box
        sx={{
          width: "100%",
          display: {
            xs: "block",
            sm: "flex",
          },
          alignItems: "center",
          justifyContent: "space-between",
          mb: "2rem",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", pb: 2 }}>
          <Typography
            fontWeight={600}
            variant="h1"
            sx={{ "&.MuiTypography-h1": { fontSize: "1.5rem" } }}
          >
            {"Add release note"}
          </Typography>
        </Box>
      </Box>
      <ChangeNoteFormProvider
        onSave={!props.changeNote ? newNote : updateNote}
        reset={props.reset ? props.reset : false}
        onClose={props.onClose}
        useFormParams={{ defaultValues: props.changeNote }}
      >
        <FormInput
          label="Title"
          name={`changeNote.title`}
          type="text"
          required
          fullWidth
        />
        <Box height="12px" />
        <FormInput
          label="Subtitle"
          name={`changeNote.subtitle`}
          type="text"
          required
          fullWidth
        />
        <Box height="12px" />
        <MarkDownEditor />
        <Box height="12px" />
        <Box>
          <Controller
            name="changeNote.showForUsers"
            render={({ field }) => (
              <FormControlLabel
                sx={{ justifyContent: "flex-start", width: "100%" }}
                control={<Checkbox {...field} checked={!!field.value} />}
                label={`Show for users`}
              />
            )}
          />
        </Box>

        <LoadingButton
          variant="contained"
          type="submit"
          loading={!props.changeNote ? isCreationLoading : isUpdatingLoading}
          fullWidth
        >
          {!props.changeNote ? "Create" : "Update"}
        </LoadingButton>
      </ChangeNoteFormProvider>
    </Box>
  );
}

function MarkDownEditor() {
  const { setValue, watch } = useFormContext();
  const content = watch("changeNote.content");

  return (
    <MdEditor
      style={{
        backgroundColor: "transparent",
        color: "black",
        minHeight: "450px",
        maxHeight: "600px",
        overflowY: "auto",
      }}
      noUploadImg
      modelValue={content || ""}
      onChange={(value) => setValue("changeNote.content", value)}
      language="en-US"
    />
  );
}

ChangeNoteForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
