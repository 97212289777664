import { ContactPageOutlined, DescriptionOutlined, TimelapseRounded } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useState } from "react";
import { DetailsDrawer } from "src/components/drawer/DetailsDrawer";
import { LocationDetails } from "src/components/location-details";
import { OperationHoursDetails } from "src/components/operation-hours/OperationsHoursDetails";
import { ContactsDetails } from "./ContactDetails";

const tabs = [
  {
    title: "Basic Info",
    icon: <DescriptionOutlined />,
  },
  {
    title: "Operation Hours",
    icon: <TimelapseRounded />,
  },
  {
    title: "Contacts",
    icon: <ContactPageOutlined />,
  },
];

export function LocationDetailsDrawer({
  title = "Testing",
  selectedItem: location,
  onVisibilityChanged,
  onEditTapped,
  columns,
}) {
  const [selectedTabIndex, setSelectedTabIndex] = useState("0");
  const onTabSelected = (event, tabIndex) => {
    setSelectedTabIndex(tabIndex);
  };

  return (
    <DetailsDrawer
      title={`Location Details`}
      selectedItem={location}
      onVisibilityChanged={onVisibilityChanged}
      onEditTapped={onEditTapped}
      rootRoute={"locations"}
    >
      <TabContext value={selectedTabIndex}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
          }}
        >
          <LocationDrawerTabs onChange={onTabSelected} />
        </Box>
        <TabPanel value={"0"}>
          <LocationDetails location={location} />
        </TabPanel>

        <TabPanel value={"1"}>
          <OperationHoursDetails operationHours={location.operationHours} />
        </TabPanel>

        <TabPanel value={"2"}>
          <ContactsDetails contacts={location.contacts} />
        </TabPanel>
      </TabContext>
    </DetailsDrawer>
  );
}

function LocationDrawerTabs({ onChange }) {
  return (
    <TabList
      onChange={onChange}
      variant="scrollable"
      scrollButtons={true}
      allowScrollButtonsMobile
    >
      {tabs.map((item, index) => {
        return (
          <Tab
            icon={item.icon}
            iconPosition="start"
            label={item.title}
            value={index.toString()}
            key={item.title}
          />
        );
      })}
    </TabList>
  );
}

export function valueGetter(value, caseEmptyValue = "--") {
  return value ? value : caseEmptyValue;
}
