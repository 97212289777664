import { PlayCircleOutlineRounded } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

export const VideoTutorialItem = ({ onItemTapped, thumbnail, title }) => {
    return <>
        <Box onClick={() => onItemTapped()}
            height={"212px"}
            sx={{
                cursor: "pointer",
                borderRadius: "12px",
                boxShadow: 12,
                justifyContent: "center",
                alignItems: "center",
                position: 'relative',
                overflow: 'hidden',
                '&:hover img': {
                    transform: 'scale(1.1)',
                    transition: 'transform 0.3s ease-in-out',
                },
            }}>
            <img src={thumbnail} style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                transition: 'transform 0.3s ease-in-out',
            }} />
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }}>
                <PlayCircleOutlineRounded sx={{ fontSize: 60, color: 'grey' }} />
            </Box>
        </Box>
        <Typography fontWeight={"bold"} marginTop={"8px"} fontSize={"20px"}>{title}</Typography>
    </>;
} 