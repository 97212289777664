import { Box } from "@mui/material";
import { createContext } from "react";
import { FormProvider } from "react-hook-form";
import { useForgotPasswordFormHook } from "../hooks/useForgotPasswordFormHook";

export const ForgotPasswordFormContext = createContext(null);

export function ForgotPasswordFormProvider(props) {
    const useFormParams = props.useFormParams;
    const onSend = props.onSend;
    const reset = props.reset || false;

    const {
        isLoading,
        form,
        handleSubmit,
        onSubmitHandler,
        clearErrors
    } = useForgotPasswordFormHook({ useFormParams, onSend, reset });

    return (
        <ForgotPasswordFormContext.Provider
            value={{
                isLoading,
                form,
                handleSubmit,
                onSubmitHandler,
                clearErrors
            }}>
            <FormProvider {...form}>
                <Box
                    component="form"
                    autoComplete="off"
                    onSubmit={(event) => {
                        clearErrors();
                        handleSubmit(onSubmitHandler)(event);
                    }}>
                    {props.children}
                </Box>
            </FormProvider>
        </ForgotPasswordFormContext.Provider>
    );
}