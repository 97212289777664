import { z } from "zod";
import { Item } from "./item";

export class Pickup {
  constructor(pickupData) {
    this.type = pickupData.type;
    this.originName = pickupData.originName;
    this.originAddr1 = pickupData.originAddr1;
    this.originAddr2 = pickupData.originAddr2;
    this.originCity = pickupData.originCity;
    this.originState = pickupData.originState;
    this.originZip = pickupData.originZip;
    this.originContact = pickupData.originContact;
    this.originPhone = pickupData.originPhone;
    this.originEmail = pickupData.originEmail;
    this.targetShipEarly = pickupData.targetShipEarly;
    this.targetShipLate = pickupData.targetShipLate;
    this.items = pickupData.items.map((itemData) => new Item(itemData));
  }

  static get createSchema() {
    return z.object({
      type: z.enum(["pickup", "delivery"]).default("pickup"),
      originName: z.string().min(1, "Required Field"),
      originAddr1: z.string().min(1, "Required Field"),
      originAddr2: z.string().min(1, "Required Field"),
      originCity: z.string().min(1, "Required Field"),
      originState: z.string().min(1, "Required Field"),
      originZip: z.string().min(1, "Required Field"),
      originCtry: z.string().min(1, "Required Field"),
      originContact: z.string().min(1, "Required Field"),
      originPhone: z.string().min(1, "Required Field"),
      originEmail: z.string().email(),
      targetShipEarly: z.coerce.date(),
      targetShipLate: z.coerce.date(),
      actualShip: z.coerce.date(),
      items: z.array(Item.createSchema),
    });
  }
}
