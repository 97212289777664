import config from "src/config";

const baseUrl = `${config.apiUrl}/auth`;

export class ForgotPasswordService {
    async forgotPassword(email) {
        const uri = new URL(`${baseUrl}/forgot-password`);
        const response = await fetch(uri.toString(), {
            method: "POST",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "email": email
            })
        });

        if (response.status !== 204) {
            return false
        }

        return true;
    }

    async resetPassword(values) {
        const uri = new URL(`${baseUrl}/reset-password`);

        const response = await fetch(uri.toString(), {
            method: "POST",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json",
                "Reset-Token": values.token
            },
            body: JSON.stringify({
                "email": values.email,
                "password": values.password,
            })
        });

        if (response.status !== 200) {
            throw new Error('Failed to send contact us request.');
        }

        return await response.json();
    }
}