import { formatDate } from "@fullcalendar/core";
import { Box, Card, CardContent, CardHeader, Divider, Grid, LinearProgress, Typography } from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router-dom"
import { useShipmentQueries } from "src/pages/shipments/hooks/useShipmentQueries";
import { fDateString } from "src/utils/formatTime";

export const OrderDetails = () => {
    const { shipmentId } = useParams();

    const { getOrderDetails, orders, isOrdersLoading } = useShipmentQueries();

    useEffect(() => {
        getOrderDetails(shipmentId);
    }, []);

    return (
        <Card
            variant="outlined"
            sx={{
                py: "0.5rem",
                px: "1rem",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "0.75rem",
            }}
        >
            <CardHeader
                title={"Order Details"}
                sx={{
                    "&.MuiCardHeader-root": {
                        p: 0,
                    },
                }}
            />
            <Divider />
            <CardContent>
                <Box>
                    {isOrdersLoading && <LinearProgress />}
                    {(!isOrdersLoading && !orders) && <Typography>Failed to load orders</Typography>}
                    {(!isOrdersLoading && orders) &&
                        <Grid container gap={'1rem'}>
                            {orders?.map(order => (<OrderDetail order={order} />))}
                        </Grid>
                    }
                </Box>
            </CardContent>
        </Card>
    );
}

const OrderDetail = ({ order }) => {
    const Row = ({ title, value }) => {
        return <Box sx={{ display: 'flex', gap: '0.4rem' }}>
            <Typography>{title}</Typography>
            <Typography fontWeight={600}>{value}</Typography>
        </Box>;
    }

    return (
        <Grid item>
            <Card variant="outlined" sx={{ px: '2rem', py: '1rem' }}>
                <Box sx={{ gap: '0.8rem' }}>
                    <Typography
                        sx={{ fontWeight: 600, fontSize: '1.1rem', mb: '1rem' }}
                    >{`${order.PO_NUM}-${order.RELEASE_NUM ?? 0}`}</Typography>
                    <Row title={'Shipment Number: '} value={order.SHIPMENT_NUM} />
                    <Row title={'PO Line Number: '} value={order.PO_LINE_NUM} />
                    <Row title={'Promised Date NBD: '} value={fDateString(order.PROMISED_DT_NBD)} />
                    <Row title={'Actual NBD: '} value={fDateString(order.ACTUAL_NBD)} />
                </Box>
            </Card>
        </Grid>
    );
}