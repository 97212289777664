export const detailColumns = [
  {
    key: "name",
    label: "Warehouse Name",
  },
  {
    key: "location",
    label: "Location",
  },
  {
    key: "city",
    label: "City",
  },
  {
    key: "state",
    label: "State",
  }, {
    key: "zip",
    label: "Zip Code",
  },
  {
    key: "capacity",
    label: "Number of docks",
    renderValue: (value) => value.docks?.length
  },
  {
    key: "operatingHours",
    label: "Normal Operating Hours",
    renderValue: (params) => {
      return (
        <div></div>
      )
    }
  },
  {
    key: "holidaysHours",
    label: "Special/Holiday Hours",
    renderValue: (params) => {
      return (
        <div></div>
      )
    }
  },
  {
    key: "contacts",
    label: "Contacts",
    renderValue: (params) => {
      return (
        <div></div>
      )
    }
  }
];
