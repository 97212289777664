import config from "src/config.js";

const baseUrl = config.apiUrl;

const headers = (token, tenantId) => {
  return {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`,
      tenant: tenantId
    }
  }
}

const customer = async (token) =>
  (await fetch(baseUrl + "/customer", headers(token))).json();

const user = async (token) =>
  (await fetch(baseUrl + "/user", headers(token))).json();

const tenants = async (token) => {
  const response = await fetch(baseUrl + "/tenants", headers(token));  
  const data = response.json()

  if(response.ok)
    return data
  
  if(response.status === 401)
    throw new Error(data?.message || response.statusText);

}

const vendors = async (token, tenantId) => {
  const response = await fetch(baseUrl + "/relationships/vendors", headers(token, tenantId));  
  const data = response.json()

  if(response.ok)
    return data
  
  if(response.status === 401)
    throw new Error(data?.message || response.statusText);

}

export default {
  customer,
  user,
  tenants,
  vendors
}