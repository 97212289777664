import { Alert, Snackbar } from "@mui/material";

const { createContext, useState, useEffect } = require("react");
const initialState = {
  isOpen: false,
  message: "",
  severity: "success",
  onClose: () => {
    console.log("default close");
  },
};

export const SnackbarContext = createContext(initialState);

export function SnackbarProvider({ children }) {
  const resetSnackbar = () => setSnackbarState(prev => ({...prev, isOpen: false}));

  const setSnackbarOnCloseCallback = (callback) =>
    setSnackbarState((prev) => ({ ...prev, onClose: callback }));

  const setSnackbarMessage = (message) =>
    setSnackbarState((prev) => ({
      ...message,
      onClose: prev.onClose,
      isOpen: true,
    }));

  const defaultState = {
    isOpen: false,
    message: "",
    severity: 'info',
    onClose: resetSnackbar,
  };

  const [snackbarState, setSnackbarState] = useState(defaultState);
  const { isOpen, message, severity, onClose } = snackbarState;

  return (
    <SnackbarContext.Provider
      value={{
        setSnackbarOnCloseCallback,
        setSnackbarMessage,
        snackbarState,
        Snackbar: () => (
          <Snackbar
            open={isOpen}
            severity={severity}
            onClose={onClose}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert
              children={message}
              severity={severity}
              onClose={onClose}
              sx={{ maxHeight: "600px" }}
            />
          </Snackbar>
        ),
      }}
    >
      {children}
      <Snackbar
        open={isOpen}
        severity={severity}
        onClose={onClose}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          children={message}
          severity={severity}
          onClose={onClose}
          sx={{ maxHeight: "600px" }}
        />
      </Snackbar>
    </SnackbarContext.Provider>
  );
}
