import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,  
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { FormInput } from "src/components/form/FormInput";
import { getDayjsDate } from "src/utils/formatTime";

const DAYS = [
  {
    key: "sunday",
    label: "S",
  },
  {
    key: "monday",
    label: "M",
  },
  {
    key: "tuesday",
    label: "T",
  },
  {
    key: "wednesday",
    label: "W",
  },
  {
    key: "thursday",
    label: "T",
  },
  {
    key: "friday",
    label: "F",
  },
  {
    key: "saturday",
    label: "S",
  },
];

const StyledToggleButtonGroup = styled(ToggleButtonGroup)((theme) => ({
  margin: 2,
  padding: "0 1",
}));

const StyledToggle = styled(ToggleButton)({
  color: (theme) => theme.primary,
  "&.Mui-selected": {
    border: "1px solid",
    borderColor: (theme) => theme.primary,
  },
  "&:hover": {
    background: (theme) => theme.primary,
  },
  minWidth: 32,
  maxWidth: 32,
  height: 40,
  // textTransform: "unset",
  fontSize: "0.75rem",
});

const ToggleDays = ({ selectedRecurrenceDays, onRecurrenceDaysSelected }) => {
  return (
    <>
      <StyledToggleButtonGroup
        size="small"
        arial-label="Days of the week"
        value={selectedRecurrenceDays}
        onChange={(event, selectedValues) => {
          onRecurrenceDaysSelected(selectedValues)
        }}
      >
        {DAYS.map((day, index) => {
          return (
            <StyledToggle key={day.key} value={index} aria-label={day.key} >
              {day.label}
            </StyledToggle>
          );
        })}
      </StyledToggleButtonGroup>
    </>
  );
};

export function RecurrenceFields() {
  const { control, watch, getValues } = useFormContext();
  const [ isRecurring, setIsRecurring ] = useState(getValues('isRecurring'));
  
  const { fields, append, remove } = useFieldArray({
    control,
    name: "recurring.daysOfWeek",
  });

  const [recurrenceDays, setSelectedDaysNumbers] = useState(
    getRecurrenceDaysFromFields
  );  

  function getRecurrenceDaysFromFields() {
    if (!fields) return [];
    let daysInFields = [];

    fields.forEach((field) => daysInFields.push(field.value));

    return daysInFields;
  }

  function handleDayOfWeekClick(selectedDays) {
    const removedDays = recurrenceDays?.filter( dayOfWeek => !selectedDays?.includes(dayOfWeek) );

    // REMOVE FROM FIELDS NOT SELECTED DAYS
    if(removedDays?.length) {
      removedDays.forEach( dayOfWeek => {
        remove(recurrenceDays.indexOf(dayOfWeek)) 
      });
    }

    const toAppend = !recurrenceDays ? selectedDays : selectedDays.filter( dayOfWeek => !recurrenceDays?.includes(dayOfWeek) ) ;

    // APPEND TO FIELDS SELECTED DAYS
    if(toAppend?.length) {
      toAppend.forEach( dayOfWeek => {
        append({ value: dayOfWeek })
      } );
    }
  }

  useEffect(() => {
    const subscription = watch((schedule, { name }) => {
      if(name === 'isRecurring') {
        setIsRecurring(schedule.isRecurring);
      }
      if(name === 'recurring.daysOfWeek') {
        setSelectedDaysNumbers(schedule.recurring.daysOfWeek.map( field => field.value ))
      }
    });

    return () => subscription.unsubscribe(); 
  }, [watch]);

  return (
    <Card>
      <CardHeader
        title={"Recurrent Schedule"}
        action={
          <FormControlLabel
            // sx={{ maxHeight: "3rem", alignSelf: "end" }}
            labelPlacement="end"
            label={"Is Recurrent?"}
            control={
              <Controller
                name={`isRecurring`}
                control={control}
                defaultValue={false}
                render={({ field: { ref, onChange, ...field } }) => (
                  <Switch {...field} inputRef={ref} checked={field.value} onChange={onChange} />
                )}
              />
            }
          />
        }
      />
      <CardContent
        sx={{
          display: "flex",
          alignItems: "left",
          gap: 2,
          opacity: isRecurring ? 1 : 0.5,
          pointerEvents: isRecurring ? "all" : "none",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>          
          <Typography> Repeat every </Typography>
          <FormInput   
            name="recurring.repeatEvery"         
            type="number"                        
            sx={{ width: "80px" }}                                    
          />

          <FormControl sx={{ minWidth: "100px" }}>
            <InputLabel id="recurrence-type-label">Period</InputLabel>
            <Select
              labelId="recurrence-type-label"                            
              label="Period"
              defaultValue='Week'
              disabled
            >
              <MenuItem value="Week">Week</MenuItem>              
            </Select>
          </FormControl>
        </Box>

        <ToggleDays
          selectedRecurrenceDays={recurrenceDays}
          onRecurrenceDaysSelected={(selectedDaysOfWeek)=> handleDayOfWeekClick(selectedDaysOfWeek)}
        />
        <Controller
          control={control}
          name={`recurring.end`}
          rules={{ required: isRecurring ? true : false }}
          render={({ field: { ref, ...field } }) => (
            <DatePicker
              {...field}
              value={getDayjsDate(field.value)}
              onChange={(date) => {
                field.onChange(date);
              }}
              timezone="UTC"
              minDate={dayjs()}
              inputRef={ref}
              sx={{ flex: 1 }}
              label="Recurrence End Date"
            />
          )}
        />
      </CardContent>
    </Card>
  );
}
