import { Box, Paper, Typography } from "@mui/material";
import { useSnackbar } from "src/hooks/useSnackbar";
import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { ProfileForm } from "./ProfileForm";
import { getProfile, updateProfile } from "src/api/profile";
import { useAuth } from "src/hooks/useAuth";

export function ProfilePage() {
    const { Snackbar } = useSnackbar();
    const { session } = useAuth();
    const [user, setUser] = useState(null);
    const { setSnackbarMessage } = useSnackbar();

    useEffect(() => {
        async function fetchData() {
            const user = await getProfile(session.token);
            setUser(user);
        }
        fetchData();
    }, []);

    return (
        <Paper
            sx={{
                maxWidth: { lg: "1000px", xs: "90%" },
                margin: "auto",
                py: 3,
            }}
            elevation={3}
        >
            <Helmet>
                <title>Profile</title>
            </Helmet>
            <Box sx={{ maxWidth: "80%", margin: "auto" }}>
                <Typography variant="h4" component="h1" sx={{ mb: "2rem" }}>
                    Profile
                </Typography>
                <ProfileForm
                    reset={false}
                    useFormParams={user}
                    onSave={async (values) => await updateProfile(session.token, values)
                        .then((data) => {
                            if (data.errors) {
                                setSnackbarMessage({
                                    message: (<Box> Error updating profile </Box>),
                                    severity: "error",
                                });
                            } else {
                                setSnackbarMessage({
                                    message: `Profile successfully updated`,
                                    severity: "success",
                                });
                            }
                        })
                    }
                />
            </Box>
            {/* DISPLAY SUCESS OR ERRORS MESSAGE */}
            <Snackbar />
        </Paper>
    );
}
