import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useAuth } from "src/hooks/useAuth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ControlPointDuplicate } from "@mui/icons-material";

function DetailRowDrawer({
  title = "Testing",
  selectedItem,
  onVisibilityChanged,
  onEditTapped,
  columns,
  modelRoute
}) {
  const { session } = useAuth();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [params] = useSearchParams();
  const navigate = useNavigate()
  
  const toggleDrawer = (isVisible) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsDrawerOpen(isVisible);
    onVisibilityChanged(isVisible);

    navigate(`/${modelRoute}?${params.toString()}`);
  };

  useEffect(() => {
    setIsDrawerOpen(!!selectedItem);
  }, [selectedItem]);

  const handleContentClick = (event) => {
    event.stopPropagation();
  };

  return (
    <>
      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <Box
          xs={12}
          padding={3}
          role="presentation"
          onKeyDown={toggleDrawer(false)}
          sx={{
            maxWidth: '100vw',
            width: {
              xs: 240,
              sm: 800,
            },
          }}
        >
          {/* DRAWER HEADER */}
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <IconButton
                onClick={() => onEditTapped && onEditTapped(selectedItem)}
                sx={{ p: 2 }}
              >
                <EditIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="h6">{title}</Typography>
            </Grid>
            <Box flexGrow={1} />
            <Grid item>
              <IconButton onClick={toggleDrawer(false)} sx={{ p: 2 }}>
                <ChevronRightIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Divider />

          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              overflow: "hidden",
            }}
            onClick={handleContentClick}
          >
            {columns.map((col) => {
              return (
                <>
                  <ListItemWrapper
                    key={col.key}
                    label={col.label}
                    value={
                      selectedItem
                        ? col.renderValue
                          ? col.renderValue(selectedItem, session.user)
                          : selectedItem[col.key]
                        : ""
                    }
                  />
                  <Divider />
                </>
              );
            })}
            {/* <ListItems selectedItem={selectedItem} /> */}
          </List>
        </Box>
      </Drawer>
    </>
  );
}

function ListItemWrapper({ label, value }) {
  const arrayFields = ["items", "operatingHours", "contacts", "holidaysHours"];
  const isArrayField = arrayFields.includes(label.toLocaleLowerCase());
  const hasValue = isArrayField ? !!value?.length : !!value;

  return (
    <ListItem
      alignItems="flex-start"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        py: 0.5,
        flexDirection: isArrayField ? "column" : "row",
      }}
    >
      <Box
        component="span"
        sx={{
          width: isArrayField ? "100%" : "fit-content",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5">{label}</Typography>
        {isArrayField && (
          <Typography
            sx={{ opacity: "0.7", minWidth: "4rem", display: "flex", justifyContent: "center" }}
          >
            {hasValue ? `${value.length} item(s)` : `No ${label.toLocaleLowerCase()}`}
          </Typography>
        )}
      </Box>
      {/* CONTENT */}
      <ListItemText
        sx={{
          width: isArrayField ? "100%" : "fit-content",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          textAlign: "right",
        }}
        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
      >
        {value ?? ""}
      </ListItemText>
    </ListItem>
  );
}

export default DetailRowDrawer;
