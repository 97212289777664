import {
    Button
} from "@mui/material";
import { Box, Paper, Typography } from "@mui/material";
import { useSnackbar } from "src/hooks/useSnackbar";
import { Helmet } from "react-helmet-async";
import { WarehouseService } from "src/api/warehouse.service";
import { WarehouseForm } from "./WarehouseForm";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Clear } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "src/hooks/useAuth";

export function WarehousesPageUpdate() {
    const { Snackbar } = useSnackbar();
    const { session } = useAuth();
    const { setSnackbarMessage } = useSnackbar();
    const { warehouseId } = useParams();
    const [warehouse, setWarehouse] = useState(null);
    const [update, setUpdate] = useState(null);
    const navigate = useNavigate();

    const service = new WarehouseService(session.token, session.tenant._id);

    useEffect(() => {
        async function fetchData() {
            const warehouse = await service.warehouseDetails(warehouseId);
            setWarehouse(warehouse);
        }
        fetchData();
    }, []);

    useEffect(() => {
        setWarehouse(update);
    }, [update]);


    /**
     * Handles the removal of a warehouse.
     * @returns {Promise<void>}
     */
    const handleRemove = async () => {
        await service.deleteWarehouse(warehouseId).then((data) => {
            if (data.errors) {
                setSnackbarMessage({
                    message: (<Box> Error removing warehouse.</Box>),
                    severity: "error",
                });
            } else {
                setSnackbarMessage({
                    message: `Warehouse ${data.name} removed successfully.`,
                    severity: "success",
                });
                navigate("/warehouses/create");
            }
        });
    }

    /**
     * Handles the save operation for updating a warehouse.
     * 
     * @param {Object} values - The updated values for the warehouse.
     * @returns {Promise<void>} - A promise that resolves when the save operation is complete.
     */
    const handleSave = async (values) => {
        setUpdate(values);
        await service.updateWarehouse(warehouseId, values).then((data) => {
            if (data.errors) {
                setSnackbarMessage({
                    message: (<Box> Error submitting warehouse.</Box>),
                    severity: "error",
                });
            } else {
                setSnackbarMessage({
                    message: `Warehouse ${data.name} updated successfully.`,
                    severity: "success",
                });
                
                navigate(`/warehouses/${warehouseId}`);
            }
        });
    }

    return (
        <Paper sx={{ maxWidth: { lg: "1000px", xs: "90%" }, margin: "auto", py: 3, }} elevation={3}>
            <Helmet>
                <title>Edit Warehouse</title>
            </Helmet>
            <Box sx={{ maxWidth: "80%", margin: "auto" }}>
                <Typography variant="h4" component="h1" sx={{ mb: "2rem" }}>
                    Edit Warehouse{(warehouse ? ": " + warehouse.name : "")}
                    <Box sx={{ display: "inline-block", float: "right" }}>
                        <Button size="small" variant="text" color="error" onClick={handleRemove}>
                            <Clear /> Remove
                        </Button>
                    </Box>
                </Typography>

                <WarehouseForm
                    useFormParams={{ defaultValues: warehouse }}
                    onSave={handleSave}
                />
            </Box>
            {/* DISPLAY SUCESS OR ERRORS MESSAGE */}
            <Snackbar />
        </Paper>
    );
}
