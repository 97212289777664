export const mexicoStates = [
    {
        name: "Aguascalientes",
        acronym: "AGS"
    },
    {
        name: "Baja California",
        acronym: "BC"
    },
    {
        name: "Baja California Sur",
        acronym: "BCS"
    },
    {
        name: "Campeche",
        acronym: "CAMP"
    },
    {
        name: "Chiapas",
        acronym: "CHIS"
    },
    {
        name: "Chihuahua",
        acronym: "CHIH"
    },
    {
        name: "Coahuila",
        acronym: "COAH"
    },
    {
        name: "Colima",
        acronym: "COL"
    },
    {
        name: "Durango",
        acronym: "DGO"
    },
    {
        name: "Guanajuato",
        acronym: "GTO"
    },
    {
        name: "Guerrero",
        acronym: "GRO"
    },
    {
        name: "Hidalgo",
        acronym: "HGO"
    },
    {
        name: "Jalisco",
        acronym: "JAL"
    },
    {
        name: "Mexico City",
        acronym: "CDMX"
    },
    {
        name: "Mexico State",
        acronym: "MEX"
    },
    {
        name: "Michoacan",
        acronym: "MICH"
    },
    {
        name: "Morelos",
        acronym: "MOR"
    },
    {
        name: "Nayarit",
        acronym: "NAY"
    },
    {
        name: "Nuevo Leon",
        acronym: "NL"
    },
    {
        name: "Oaxaca",
        acronym: "OAX"
    },
    {
        name: "Puebla",
        acronym: "PUE"
    },
    {
        name: "Queretaro",
        acronym: "QRO"
    },
    {
        name: "Quintana Roo",
        acronym: "QR"
    },
    {
        name: "San Luis Potosi",
        acronym: "SLP"
    },
    {
        name: "Sinaloa",
        acronym: "SIN"
    },
    {
        name: "Sonora",
        acronym: "SON"
    },
    {
        name: "Tabasco",
        acronym: "TAB"
    },
    {
        name: "Tamaulipas",
        acronym: "TAM"
    },
    {
        name: "Tlaxcala",
        acronym: "TLAX"
    },
    {
        name: "Veracruz",
        acronym: "VER"
    },
    {
        name: "Yucatan",
        acronym: "YUC"
    },
    {
        name: "Zacatecas",
        acronym: "ZAC"
    }
];
