import { createContext } from "react";
import { FormProvider } from "react-hook-form";
import { Box } from "@mui/material";
import { useShipmentCarrierFormHook } from "../hooks/useShipmentCarrierFormHook";

export const ShipmentCarrierFormContext = createContext(null);

export function ShipmentCarrierFormProvider({ children, ...props }) {
  const useFormParams = props.useFormParams;
  const onSave = props.onSave;
  const reset = props.reset || false;
  const candidatesCarriers = props.candidatesCarriers

  const {
    isLoading,
    form,
    handleSubmit,
    onSubmitHandler,
    setError,
    clearErrors,
    ...rest
  } = useShipmentCarrierFormHook({ useFormParams, onSave, reset, candidatesCarriers });

  return (
    <ShipmentCarrierFormContext.Provider
      value={{
        isLoading,
        form,
        handleSubmit,
        onSubmitHandler,
        clearErrors,
        ...rest,
      }}
    >
      <FormProvider {...form}>
        <Box
          component="form"
          autoComplete="off"
          onSubmit={(event) => {
            clearErrors();
            handleSubmit(onSubmitHandler)(event);
          }}
          sx={props.sx}
        >
          {children}
        </Box>
      </FormProvider>
    </ShipmentCarrierFormContext.Provider>
  );
}
