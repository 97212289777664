import React from "react";
import { ContactUsForm } from "../components/ContactUsForm";

export const ContactUs = (props) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        backgroundColor: "#3e496c",
        borderRadius: "8px",
        padding: "32px",
      }}
    >

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "8px",
          backgroundColor: "#f5f5f5",
          maxWidth: "600px",
          width: "100%",
          maxHeight: "95vh",
          overflow: "auto",
          padding: "30px"
        }}
      >
        <div style={{ marginTop: -30 }}>
          <h2>Support Request</h2>
          <p>
            For any inquiries or assistance, please fill out the form below and
            we will get back to you as soon as possible.
          </p>
        </div>
        <div>
          <ContactUsForm isSupportOnly={props.isSupportOnly}/>
        </div>
      </div>
    </div>
  );
};
