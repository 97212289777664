import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useReportQueries } from "./hooks/useReportQueries";
import { ReportDashboardLoading } from "./components/ReportDashboardLoading";
import { Page404 } from "../Page404";
import { Box, Container, Skeleton } from "@mui/material";

export const ReportDashboardPage = () => {
  const { dashboardId } = useParams();
  const {
    getReportUrl,
    isLoadingReportURL,
    reportURL: dashboard,
  } = useReportQueries();
  const [isIFrameLoading, setIFrameLoad] = useState(true);

  useEffect(() => {
    getReportUrl(dashboardId);
  }, []);

  return (
    <Box
      display={"flex"}
      height={"100%"}
      width={"100%"}
    >
      {(!isLoadingReportURL && !!dashboard?.link) &&
        <Box display={"flex"} alignItems={"center"} flex={1} height={"82vh"}>
          <iframe
            src={dashboard.link}
            width="100%"
            height="100%" // Adjust height as needed
            style={{ border: "none", display: isIFrameLoading ? 'none' : 'flex' }}
            onLoad={() => setIFrameLoad(false)}
          />
        </Box>
      }
      {(isLoadingReportURL || !dashboard?.link || isIFrameLoading) && <ReportDashboardLoading />}
    </Box>
  );
};
