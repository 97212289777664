import config from "src/config";

export const updateProfile = async (token, data) => {
    const response = await fetch(config.apiUrl + '/profile', {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    });

    return await response.json();
};

export const getProfile = async (token) => {
    const response = await fetch(config.apiUrl + '/profile', {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });

    return await response.json();
};

