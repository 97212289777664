export const countries = [
  {
    "name": "United States of America",
    "acronym": "USA"
  },
  {
    "name": "Canada",
    "acronym": "CA"
  },
  {
    "name": "Mexico",
    "acronym": "MX"
  }
]