import { Autocomplete, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useAuth } from "src/hooks/useAuth";

export function OrdersNumbersField() {
  const { control } = useFormContext();
  const { session } = useAuth();

  const orderNumb = () => {
    let orderNumber = ''
    if (session.tenant.code === 'COX') {
      orderNumber = 'Shipper Reference Number'
    } else {
      orderNumber = 'Order Number'
    }
    
    return  orderNumber
  }


  return (
    <Controller
      name={'ordersNumbers'}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const { onChange, value } = field;
        return (
          <Autocomplete
            {...field}
            multiple
            limitTags={1}
            freeSolo
            fullWidth
            autoComplete
            includeInputInList
            options={[]}
            value={value ? typeof (value) === 'string' ? value.split(',') : value : []}
            onChange={(event, newValue) => {
              onChange(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={orderNumb()}
                fullWidth
                label={orderNumb()}
                {...error}
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
          />
        );
      }}
    />
  );
}
