import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSnackbar } from "src/hooks/useSnackbar";

export function useChangeNoteFormHook(props) {
    const useFormParams = props?.useFormParams;
    const defaultValues = useFormParams?.defaultValues;
    const { setSnackbarMessage } = useSnackbar();

    const form = useForm({
        ...useFormParams,
        defaultValues,
        mode: "onChange"
    });

    const {
        formState: { errors, isSubmitSuccessful },
        reset,
        handleSubmit,
        setError,
        clearErrors,
    } = form;

    useEffect(() => {
        if (isSubmitSuccessful) {
            props.reset && reset();
        }
    }, [isSubmitSuccessful, reset, errors]);

    const [isLoading, setIsLoading] = useState(false);

    async function onSubmitHandler(values) {
        setIsLoading(true);
        try {
            const data = await props.onSave(values);

            if (data?.errors) {
                updateErrors(data.errors, setError);
            }
            setIsLoading(false);
        } catch (error) {
            setSnackbarMessage({
                severity: 'error',
                message: error.message,
            });
            setIsLoading(false);
        }
        props.onClose();
    }

    return {
        isLoading,
        form,
        handleSubmit,
        onSubmitHandler,
        clearErrors,
    }
}

function updateErrors(errors, setError) {
    errors.forEach((error) => {
        const param = error.param.replace("[", ".").replace("]", "");
        setError(param, { type: "manual", message: error.msg });
    });
}
