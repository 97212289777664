import * as React from 'react';
import { Box, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { StandardDialog } from 'src/components/dialog/Dialog';
import { useTenant } from "src/hooks/useTenant";
import { NumericFormat } from 'react-number-format'; // Importando o react-number-format

function DialogConfirmForShipments(props) {

    const { tenant } = useTenant();
    const [rate, setRate] = React.useState("");

    React.useEffect(() => { setRate("") }, [])

    function handleRateChange(event) {
        setRate(event.target.value);
    }

    return (
        <StandardDialog isOpen={props.open} onClose={props.onClose}>
            <Box sx={{ width: '100%' }}>
                <DialogTitle>{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {props.text}
                    </DialogContentText>
                    {(tenant.modules?.spotmarket && props.inputRate) && (
                        <TextField
                            label="Rate value"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            value={rate}
                            onChange={handleRateChange}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                inputComponent: NumericFormat,
                            }}
                            inputProps={{
                                thousandSeparator: true,
                                decimalSeparator: ".",
                                decimalScale: 2,
                                fixedDecimalScale: true,
                            }}
                        />
                    )}

                </DialogContent>
                <DialogActions sx={{ pr: 6, pb: 4 }}>
                    <Button variant="text" onClick={props.onClose}>No</Button>
                    <Button variant="contained" onClick={() => props.onConfirm(rate)}>Yes</Button>
                </DialogActions>
            </Box>
        </StandardDialog>
    );
}

export default DialogConfirmForShipments;
