import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Divider, Drawer, Grid, IconButton, Link } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { ScheduleDetails } from "src/pages/schedule/components/ScheduleDetails";
import { ScheduleForm } from "src/pages/schedule/components/ScheduleForm";
import { shipmentDetailColumns } from "src/pages/dashboard/shipmentColumns";
import { useLocation, useNavigate } from "react-router-dom";
import { ShipmentDetailsDrawer } from "src/pages/shipments/components/details-drawer/backoffice/DetailsDrawer";
import { useAuth } from "src/hooks/useAuth";

export function EventDetailsDrawer({
  selectedItem: calendarEvent,
  onVisibilityChanged,
  handleRefreshCalendar,
  // onEditTapped, OPEN EDIT PAGE
}) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const { session } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    // apiDetail()
  }, []);

  const toggleDrawer = (isVisible) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsDrawerOpen(isVisible);
    onVisibilityChanged(isVisible);
  };

  useEffect(() => {
    setIsDrawerOpen(!!calendarEvent);
  }, [calendarEvent]);

  return (
    <>
      { calendarEvent.extendedProps &&
        calendarEvent.extendedProps.eventType == "shipment" ? (
          <ShipmentDetailsDrawer
            title="Shipment Details"
            onVisibilityChanged={toggleDrawer(false)}
            onEditTapped={(value) => navigate(`/shipments/${value._id}/edit`)}
            selectedItem={calendarEvent.extendedProps}
            columns={shipmentDetailColumns(session)}
            isSchedule
          />
        ) : (
          <Drawer
            anchor="right"
            open={isDrawerOpen}
            onClose={toggleDrawer(false)}
            sx={{
              width: "30vw",
            }}
          >
            <Box
              xs={8}
              padding={3}
              role="presentation"
            >
              
              {/* DRAWER HEADER */}
              <Grid container alignItems="center" spacing={2} sx={{ maxWidth: "50vw" }}>
                {!!calendarEvent.extendedProps && (
                  <Grid item>
                    <IconButton
                      onClick={() => setIsEditMode(true)}
                      disabled={isEditMode}
                      sx={{ p: 2 }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Grid>
                )}
                <Grid item>
                  <Typography variant="subtitle1" fontSize={"24px"}>
                    { 
                      calendarEvent.title
                        ? "Appointment Details"
                        : "New Appointment"
                    }
                  </Typography>
                </Grid>
                <Box flexGrow={1} />
                <Grid item>
                  <IconButton onClick={toggleDrawer(false)} sx={{ p: 2 }}>
                    <ChevronRightIcon />
                  </IconButton>
                </Grid>

              </Grid>
              <Divider />
              {/* DRAWER CONTENT */}

              { !calendarEvent.extendedProps || isEditMode ? (
                <ScheduleForm
                  handleRefreshCalendar={handleRefreshCalendar}
                  schedule={{ calendarEvent }}
                  useFormParams={{ defaultValues: calendarEvent.extendedProps }}
                  onVisibilityChanged={toggleDrawer(false)}
                />
              ) : calendarEvent.extendedProps &&
                calendarEvent.extendedProps.eventType == "appointment" && (
                  isEditMode ? (
                    <ScheduleForm
                      handleRefreshCalendar={handleRefreshCalendar}
                      schedule={{ calendarEvent }}
                      useFormParams={{ defaultValues: calendarEvent.extendedProps }}
                      onVisibilityChanged={toggleDrawer(false)}
                    />
                  ) 
                  : (
                    <ScheduleDetails
                      schedule={calendarEvent}
                      handleRefreshCalendar={handleRefreshCalendar}
                      onVisibilityChanged={onVisibilityChanged}
                    />
                  )
                )
              }
            </Box>
          </Drawer>
        )
      }
    </>
  );
}
