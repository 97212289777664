import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAuth } from "src/hooks/useAuth";

function useLoginForm() {

  const { isLogged, login, error: loginError } = useAuth();
  const { register, handleSubmit, formState: { errors: formErrors } } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmitLoginForm = (data) => {
    setIsLoginLoading(true);
    login(data)
      .then(() => {
        setIsLoginLoading(false) 
      });
  };
  
  // Observing login event
  useEffect(() => {
    if (isLogged) {
      navigate('/app');
    }
  }, [isLogged, navigate])

  return {
    loginError,
    handleSubmit,
    formErrors,
    showPassword,
    setShowPassword,
    onSubmitLoginForm,
    isLoginLoading,
    emailValidator: {...register("username", {
      required: "Email is required",
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: "Invalid format",
      },
    })},
    passwordValidator: {...register("password", { required: "Password is required" })}
  }
}

export default useLoginForm