import { Compare } from "src/utils/compare";
import { fDateString } from "src/utils/formatTime";

export const OrderColumns = (session) => {
  let poNumberLabel = "PO Number";
  if (session?.tenant?.code === "COX") {
    poNumberLabel = "COX PO Number";
  }

  return [
    {
      field: "po_number",
      headerName: poNumberLabel,
      minWidth: 200,
      renderValue: (params) => {
        const poNumber = params.row.PO_NUM;
        const releaseNumber = params.row.RELEASE_NUM;
        return <>{`${poNumber}-${releaseNumber}`}</>;
      },
      valueGetter: (params) => {
        const poNumber = params.row.PO_NUM;
        const releaseNumber = params.row.RELEASE_NUM;
        return `${poNumber}-${releaseNumber}`;
      },
    },
    {
      field: "SHIPMENT_NUM",
      headerName: "Shipment Number",
    },
    {
      field: "PO_LINE_NUM",
      headerName: "PO Line Number",
    },
    {
      field: "PROMISED_DT_NBD",
      headerName: "Promised",
      renderCell: (params) => (
        <div>{fDateString(params.row.PROMISED_DT_NBD)}</div>
      ),
      valueGetter: (params) => params.row.PROMISED_DT_NBD,
      sortComparator: Compare.dayInMoth,
    },
    {
      field: "ACTUAL_NBD",
      headerName: "Actual",
      renderCell: (params) => <div>{fDateString(params.row.ACTUAL_NBD)}</div>,
      valueGetter: (params) => params.row.ACTUAL_NBD,
      sortComparator: Compare.dayInMoth,
    },
    {
      field: "VENDOR_NM",
      headerName: "Vendor Name",
      minWidth: 200,
    },
    {
      field: "SHIP_TO_LOCATION",
      headerName: "Ship to Location",
      minWidth: 200,
    },
    {
      field: "ADDRESS1",
      headerName: "Address 1",
      minWidth: 250,
    },
    {
      field: "ADDRESS2",
      headerName: "Address 2",
      minWidth: 200,
    },
    {
      field: "CITY_ST_ZIP",
      headerName: "City, State and ZIP",
      minWidth: 150,
    },
    {
      field: "EXTD_COST",
      headerName: "Extended Cost",
      minWidth: 150,
    },
    {
      field: "ITEM_NUM",
      headerName: "Item Number",
      minWidth: 150,
    },
    {
      field: "ITEM_DESC",
      headerName: "Item Description",
      minWidth: 150,
    },
    {
      field: "QTY_OPEN",
      headerName: "Quantity Open",
      minWidth: 150,
    },
  ];
};

export const OrderDetailColumns = (session) => {
  let poNumberLabel = "PO Number";
  if (session?.tenant?.code === "COX") {
    poNumberLabel = "COX PO Number";
  }

  return [
    {
      key: "po_number",
      label: poNumberLabel,
      renderValue: (value) => {
        const poNumber = value.PO_NUM;
        const releaseNumber = value.RELEASE_NUM;
        return <>{`${poNumber}-${releaseNumber}`}</>;
      },
    },
    {
      key: "SHIPMENT_NUM",
      label: "Shipment Number",
    },
    {
      key: "PO_LINE_NUMBER",
      label: "PO Line Number",
    },
    {
      key: "VENDOR_NM",
      label: "Vendor Name",
    },
    {
      key: "SHIP_TO_LOCATION",
      label: "Ship to Location",
    },
    {
      key: "ADDRESS1",
      label: "Address 1",
    },
    {
      key: "ADDRESS2",
      label: "Address 2",
    },
    {
      key: "CITY_ST_ZIP",
      label: "City, State and ZIP",
    },
    {
      key: "EXTD_COST",
      label: "Extended Cost",
    },
    {
      key: "ITEM_NUM",
      label: "Item Number",
    },
    {
      key: "ITEM_DESC",
      label: "Item Description",
    },
    {
      key: "QTY_OPEN",
      label: "Quantity Open",
    },
  ];
};
