import { Box, Paper, Typography } from "@mui/material";
import { useSnackbar } from "src/hooks/useSnackbar";
import { Helmet } from "react-helmet-async";
import { UserForm } from "./UserForm";
import { useNavigate } from "react-router-dom";
import { useAuth } from "src/hooks/useAuth";
import { UserService } from "src/api/user.service";
import { Roles } from "src/models/roles";
import { ErrorMessage } from "src/components/ErrorMessage";

export function CreateUserPage() {
    const { Snackbar } = useSnackbar();
    const { session } = useAuth();
    const { setSnackbarMessage } = useSnackbar();
    const navigate = useNavigate();

    const service = new UserService(session.token, session.tenant._id);


    /**
     * Handles saving a user.
     * @param {Object} values - The values of the user to be saved.
     * @returns {Promise<void>} - A promise that resolves when the user is saved.
     */
    const handleSave = async (values) => {
        if (values?.location == "")
            delete values.location;
        
        await service.postUser(values).then((data) => {

            if (data.errors) {
                setSnackbarMessage({
                    message: (
                    <Box>
                        <Typography mb={1} fontWeight={600}>
                            Error submitting user 
                        </Typography>
                        <ErrorMessage response={data} />
                    </Box>
                ),
                    severity: "error",
                });
            } else {
                setSnackbarMessage({
                    message: `New user ${data.firstname} created successfully.`,
                    severity: "success",
                });
                navigate(`/users/${data.id}`);
            }
            return data
        })
    };

    return (
        <Paper sx={{ maxWidth: { lg: "1000px", xs: "90%" }, margin: "auto", py: 3, }} elevation={3}>
            <Helmet>
                <title>Create a New User</title>
            </Helmet>
            <Box sx={{ maxWidth: "80%", margin: "auto" }}>
                <Typography variant="h4" component="h1" sx={{ mb: "2rem" }}>
                    Create New User
                </Typography>
                <UserForm
                    showPassword={true}
                    onSave={handleSave} />
            </Box>
            {/* DISPLAY SUCESS OR ERRORS MESSAGE */}
            <Snackbar />
        </Paper>
    );
}
