import {
  Box,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useVendorsForm } from "./useVendorsForm";
import { FormProvider } from "react-hook-form";

const { FormInput } = require("src/components/form/FormInput");

export function VendorsForm({ useFormParams, onSave, reset, isLoading }) {
  const { form, handleSubmit, onSubmitHandler, clearErrors } =
    useVendorsForm({ useFormParams, onSave, reset });

  const mapFields = (params, prefix = "") => {
    if (!params) return;

    Object.entries(params).forEach(([key, value]) => {
      if (!value) return;
      switch (key) {
        case "vendors":
          form.setValue(prefix + key, value.map((v) => v._id) ?? false);
          break;
      }

      if (typeof value === "object")
        return mapFields(value, `${prefix + key}.`);

      form.setValue(prefix + key, value ?? false);
    });
  };

  return (
    <FormProvider {...form}>
      <Box
        component="form"
        autoComplete="off"
        onSubmit={(event) => {
          clearErrors();
          handleSubmit(onSubmitHandler)(event);
        }}
        sx={{
          ".MuiFormHelperText-root": {
            color: "red",
          },
        }}
      >
        <Stack spacing={2}>
          <Box sx={{ display: "flex", gap: 2 }}>
            <FormInput
              name={`name`}
              label="Name"
              required
              type="text"
              sx={{ flexGrow: 1 }}
            />
          </Box>
        </Stack>

        <LoadingButton
          variant="contained"
          fullWidth
          type="submit"
          loading={isLoading}
          sx={{ py: "0.8rem", mt: "1rem" }}
        >
          Save Vendor
        </LoadingButton>
      </Box>
    </FormProvider>
  );
}
