import { Box, MenuItem, Stack, Typography } from "@mui/material";
import { FormInput } from "src/components/form/FormInput";
import { freightClasses } from "../../units";
import { usePackageForm } from "src/pages/shipments/context/PackageContext";
import { useFormContext } from "react-hook-form";

export const PackageAddionalInfo = () => {
    const { state, packageIndex } = usePackageForm();
    const { watch } = useFormContext();

    return <>
        {state.isShowAdditionalInfo && <>
            {/* FREIGHT CLASS & NMFC CODE & MANUFACTER PART NUMBER */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                    my: '1rem'
                }}
            >
                <Typography variant="caption" fontSize={"1.2rem"}>
                    Addional Information
                </Typography>
                <Stack
                    spacing={2}
                    direction={{
                        xs: "column",
                        md: "row",
                    }}
                >
                    <FormInput
                        fullWidth
                        select
                        name={`items.${packageIndex}.class`}
                        label="Freight Class"
                        required={watch("mode") == "LTL" ? true : false}
                        SelectProps={{
                            MenuProps: {
                                PaperProps: { sx: { maxHeight: "10rem" } },
                            },
                        }}
                    >
                        {freightClasses
                            .sort((a, b) => parseFloat(a) - parseFloat(b))
                            .map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                    </FormInput>
                    <FormInput
                        fullWidth
                        name={`items.${packageIndex}.nmfcCode`}
                        label="NMFC Code"
                        type="text"
                    />
                    <FormInput
                        fullWidth
                        name={`items.${packageIndex}.manufacturerPartNumber`}
                        label="Manufacter Part Number"
                        type="text"
                    />
                </Stack>
            </Box>
        </>
        }
    </>
}