import { FormInput } from "src/components/form/FormInput";
import { Controller, useFormContext } from "react-hook-form";
import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useAuth } from "src/hooks/useAuth";
import { usePackageForm } from "src/pages/shipments/context/PackageContext";
import { UploadPackageSlipInput } from "../../UploadPackageSlipInput";

export function PackageBasicInfoForm({ isSupplierManager }) {
  const { watch, control } = useFormContext();
  const { session } = useAuth();
  
  const { packageIndex } = usePackageForm();

  const poNumbers = watch("poNumber");

  const poNumb = () => {
    let poNumber = "";

    if (session.tenant.code === "COX") {
      poNumber = "COX PO Numbers:";
    } else {
      poNumber = "PO Numbers:";
    }

    return poNumber;
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        mb: "1rem",
      }}
    >
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Typography variant="caption" fontSize={"1.2rem"} fontWeight={600}>
        Basic Info
      </Typography>
      <UploadPackageSlipInput/>

    </Box>
      <Stack
        spacing={1}
        direction={{
          xs: "column",
          md: "row",
        }}
      >
        <FormInput
          label="Package ID"
          name={`items.${packageIndex}.itemId`}
          type="text"
          fullWidth
        />
        <FormInput
          name={`items.${packageIndex}.actualValue`}
          label="Value"
          type="number"
          required
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        {isSupplierManager && (
          <>
            <FormInput
              name={`items.${packageIndex}.palletNumber`}
              label="Pallet Id Number"
              type="number"
              fullWidth
            />
            <FormControl fullWidth>
              <InputLabel id={`items.${packageIndex}.poNumber`}>
                {poNumb()}
              </InputLabel>
              <Controller
                control={control}
                name={`items.${packageIndex}.poNumber`}
                render={({ field }) => {
                  const selectedValues = Array.isArray(field.value)
                    ? field.value
                    : [];

                  return (
                    <Select
                      multiple
                      {...field}
                      label={poNumb()}
                      name={`items.${packageIndex}.poNumber`}
                      fullWidth
                      value={selectedValues}
                      onChange={(event) => {
                        const value = Array.isArray(event.target.value)
                          ? event.target.value
                          : [event.target.value];
                        field.onChange(value);
                      }}
                    >
                      {Array.isArray(poNumbers) && poNumbers.length > 0 ? (
                        poNumbers.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem key="" value="">
                          No PO Numbers available
                        </MenuItem>
                      )}
                    </Select>
                  );
                }}
              />
            </FormControl>
          </>
        )}
      </Stack>
      <Controller
        control={control}
        name={`items.${packageIndex}.description`}
        render={({ field }) => (
          <TextField label="Description" {...field} fullWidth required />
        )}
      />
    </Box>
  );
}
