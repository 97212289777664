import { Button, Grid, Stack, TextField, Tooltip } from "@mui/material";

import { BackspaceRounded } from "@mui/icons-material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { Controller, useFieldArray } from "react-hook-form";
import { getDayjsDate } from "src/utils/formatTime";
import { useWarehouseForm } from "../../hooks/useForm";

export function DockMaintenanceWindowFields({ dock, maintenanceIndex }) {
  const { form } = useWarehouseForm();
  const { remove } = useFieldArray({
    control: form.control,
    name: `docks.${dock.index}.maintenanceWindow`
  })

  return (
    <Stack direction={"row"}>
      <Grid
        container
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Grid item xs={3}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Dock"
            label={`Dock | ID - Door`}
            disabled
            value={`${dock.dockId} - ${dock.door}`}
          />
        </Grid>
        <Grid item xs={4.4}>
          <Controller
            control={form.control}
            name={`docks.${dock.index}.maintenanceWindow.${maintenanceIndex}.startAt`}
            sx={{ flex: 1 }}
            render={({ field }) => (
              <DateTimePicker
                {...field}
                value={getDayjsDate(field.value)}
                label="Start"
                required
                timezone="UTC"
                minutesStep={15}
                onChange={(e) => {
                  field.onChange(e);
                }}
                slotProps={{
                  textField: {
                    size: "small",
                  },
                }}
                // viewRenderers={{
                //   hours: renderTimeViewClock,
                //   minutes: renderTimeViewClock,
                // }}
              />
            )}
          />
        </Grid>
        <Grid item xs={4.4}>
          <Controller
            control={form.control}
            name={`docks.${dock.index}.maintenanceWindow.${maintenanceIndex}.endAt`}
            sx={{ flex: 1 }}
            render={({ field }) => (
              <DateTimePicker
                {...field}
                value={getDayjsDate(field.value)}
                label="End"
                required
                timezone="UTC"
                minutesStep={15}
                onChange={(e) => {
                  field.onChange(e);
                }}
                slotProps={{
                  textField: {
                    size: "small",
                  },
                }}
                // viewRenderers={{
                //   hours: renderTimeViewClock,
                //   minutes: renderTimeViewClock,
                // }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Tooltip title={"Remove Maintenance Window"}>
        <Button
          size="small"
          onClick={() => remove(maintenanceIndex)}
          type="button"
          sx={{
            p: 0,
            width: "fit-content",
          }}
        >
          <BackspaceRounded />
        </Button>
      </Tooltip>
    </Stack>
  );
}
