import { ChevronRightOutlined } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Container,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Navigate } from "react-router-dom";
import { ContactButton } from "src/components/contact-button";
import Logo from "src/components/logo/Logo";
import { useAuth } from "src/hooks/useAuth";
import { useTenant } from "src/hooks/useTenant";

export function SetTenantPage() {
  const { setTenant, tenants, isLoadingTenants } = useTenant();
  const { session } = useAuth();
  if (session.tenant) return <Navigate to={"/"} />;

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "90vh",
        py: 8,
      }}
    >
      <Stack gap={2}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // mb: 2,
            // py: 1,
          }}
        >
          <Logo sx={{ width: "5rem" }} />
          <Typography
            variant="h1"
            sx={{
              "&.MuiTypography-h1": { fontSize: "2.5rem" },
              width: "fit-content",
            }}
          >
            Welcome to Loadmanager!
          </Typography>
        </Box>

        <Paper
          elevation={3}
          sx={{
            width: "100%",
            pt: "1.2rem",
            display: "flex",
            flexDirection: "column",
            rowGap: 2,
            borderRadius: 4,
            maxWidth: "560px",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              "&.MuiTypography-h1": { fontSize: "1.5rem" },
              px: 2,
            }}
          >
            Select Your Tenant
          </Typography>
          {isLoadingTenants || !tenants.length ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                py: 6,
              }}
            >
              <CircularProgress sx={{ m: "auto" }} />
            </Box>
          ) : (
            <List
              sx={{
                "& .MuiListItem-gutters": { p: 0 },
                maxHeight: '40vh',
                overflow: 'auto'
              }}
            >
              {tenants?.map((tenant, index) => (
                <Box key={tenant._id}>
                  <ListItem >
                    <ListItemButton onClick={() => setTenant(tenant)}>
                      <ListItemText
                        primary={tenant.name}
                        secondary={tenant.title}
                      />
                      <ChevronRightOutlined />
                    </ListItemButton>
                  </ListItem>
                  {index < tenants.length - 1 && <Divider />}
                </Box>
              ))}
            </List>
          )}
        </Paper>
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: 4,
            maxWidth: "560px",
            p: "1.2rem",
            gap: 1,
          }}
        >
          <Typography variant="body1">Need help?</Typography>
          <ContactButton variant="text" sx={{ px: 2, fontSize: "1rem" }} isSupportOnly />
        </Paper>
      </Stack>
    </Container>
  );
}
