import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { WarehouseDockFields } from "./DockFields";
import { useWarehouseForm } from "../../hooks/useForm";
import { FormAccordion } from "src/components/form/FormAccordion";
import { DockMaintenanceFieldsWrapper } from "../dock-maintenance-fields/DockMaintenanceFieldsWrapper";

export function DocksFieldsWrapper() {
  const { fieldsDocks, appendDock, form } = useWarehouseForm();

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          py: "1.5rem",
          px: "1rem",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "0.75rem",
          "&.MuiCard-root": { py: 0 },
        }}
      >
        <CardContent
          sx={{
            "&.MuiCardContent-root": { p: 0 },
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FormAccordion
              header={
                <Box display={"flex"} gap={1}>
                  <Typography fontWeight={600}>Docks Information</Typography>
                  <Typography fontWeight={"0.9rem"} color={"gray"}>
                    {`${fieldsDocks.length} dock(s)`}
                  </Typography>
                </Box>
              }
              sx={{
                p: 0,
                "& .MuiAccordionDetails-root": { p: 0 },
                "& .MuiAccordionSummary-root": { p: 0 },
              }}
              elevation={0}
              defaultExpanded={true}
            >
              {fieldsDocks.length === 0 && (
                <Typography variant="body2" color="text.secondary">
                  No docks added yet
                </Typography>
              )}
              {fieldsDocks.map((dock, index) => (
                <WarehouseDockFields
                  key={`${dock.id}`}
                  dock={dock}
                  dockIndex={index}
                />
              ))}
            </FormAccordion>
          </Box>
          <Box display="flex" pb={1}>
            <Button
              type="button"
              size="small"
              onClick={() =>
                appendDock({
                  id: null,
                  type: null,
                  disabled: false,
                })
              }
              sx={{ minWidth: "fit-content", height: "2.5rem" }}
            >
              + Add Dock
            </Button>
          </Box>
        </CardContent>
      </Card>
      <DockMaintenanceFieldsWrapper docks={form.watch("docks")} />
    </>
  );
}
