import { alpha } from '@mui/material/styles';
import palette from '../palette';

// ----------------------------------------------------------------------

const color = palette.common.black;

export default function Backdrop() {
  return {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: alpha(color, 0.28),
        },
        invisible: {
          background: 'transparent',
        },
      },
    },
  };
}
