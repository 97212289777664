import {
  AttachFileOutlined,
  CloudUpload,
  CloudUploadOutlined,
  TaskAltOutlined,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useShipmentQueries } from "../hooks/useShipmentQueries";
import { useAuth } from "src/hooks/useAuth";
import { DeleteFileAttachmentButton } from "./DeleteFileAttachmentButton";
import { Controller, useFormContext } from "react-hook-form";
import { useSnackbar } from "src/hooks/useSnackbar";

export function UploadAttachment({}) {
  const { setValue, getValues, watch } = useFormContext();
  const { setSnackbarMessage } = useSnackbar();
  
  const shipmentId = getValues('_id');

  const attachment = watch(`attachment`) !== null ? watch(`attachment`) : false;
  const attachmentUrl = watch(`attachment.url`) ? watch(`attachment.url`) : false;    

  const [selectedFile, setSelectedFile] = useState(null);
  const { session } = useAuth();
  const auth = session.permissions;
  const [allowUpload, setAllowUpload] = useState(attachment);

  const fileInputRef = useRef(null);
  const openFileSelection = () => fileInputRef.current.click();  

  const { isUploadAttachmentLoading, uploadAttachmentFile, isUploadAttachmentSuccess, updatedAttachment } =
    useShipmentQueries();

  const handleUpload = () => {
    uploadAttachmentFile({
      shipmentId,
      file: selectedFile,      
      onUploaded: (data) => {
        if (data.errors) {
          setSnackbarMessage({
            message: <Box> Error submitting package slip file.</Box>,
            severity: "error",
          });
        } else {
          setSnackbarMessage({
            message: <Box> Success to upload package slip file </Box>,
            severity: "success",
          });
        }
      },
      onFailed: (err) =>
        setSnackbarMessage({
          message: <Box> Error to upload package file: {err.message}</Box>,
          severity: "error",
        }),
    });
  };

  const toggleAllowUpload = () => {    
      setValue(`attachment`, null);      
      setAllowUpload(!allowUpload);    
  };

  useEffect(() => {
    if (isUploadAttachmentSuccess && updatedAttachment) {
      setValue(`attachment`, updatedAttachment.attachment);
    }
  }, [updatedAttachment]);

  return (
    <Box
      component="form"
      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
    >
      {/* Hidden file input */}
      <Controller
        name={`attachment`}
        render={({ field: { onChange, value } }) => (
      <input
        accept=".jpg,.jpeg,.png,.pdf,.xls,.xlsx"
        id="file-upload"
        type="file"
        style={{ display: "none" }}
        onChange={(e) => {
          onChange(e.target.files[0]);
          setSelectedFile(e.target.files[0]);
          setValue(`attachment`, e.target.files[0]);
        }}
        ref={fileInputRef}
        />
      )}
    />
      {/* IconButton to trigger file input */}
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        {!selectedFile && !attachmentUrl && (
          <label htmlFor="file-upload">
            <Tooltip title={`Select Attachment File`}>
              <LoadingButton
                loading={isUploadAttachmentLoading}
                type="button"
                sx={{
                  display: "flex",
                  px: '2rem',
                  gap: 2,
                  borderRadius: "0.5rem",
                  fontWeight: 800,
                  boxShadow: 1,
                }}
                onClick={() => fileInputRef.current.click()}
              >
                <CloudUploadOutlined />
                <Typography
                  variant="button"
                  sx={{ width: "100%", textAlign: "left" }}
                >
                  Upload
                </Typography>
              </LoadingButton>
            </Tooltip>
          </label>
        )}
        {selectedFile && !isUploadAttachmentSuccess && (
          <Box sx={{ display: "flex", gap: 1 }}>
            <Tooltip title={`Select Another File`}>
            {shipmentId ? (
                <Button
                  sx={{ px: "0.5rem", minWidth: "fit-content" }}
                  onClick={() => {
                    openFileSelection();
                  }}
                >
                  {/* <Backspace /> */}
                  <AttachFileOutlined />
                </Button>
              ) : (
                <Button
                  sx={{ px: "0.5rem", minWidth: "fit-content" }}
                  onClick={() => {
                    openFileSelection();
                  }}
                >
                  {/* <Backspace /> */}
                  <AttachFileOutlined />
                  Change File
                </Button>
              )}
            </Tooltip>
            <Tooltip title={`Upload ${selectedFile.name}`}>
            {shipmentId && (
              <LoadingButton
                loading={isUploadAttachmentLoading}
                type="button"
                sx={{
                  display: "flex",
                  width: "10rem",
                  px: 1,
                  gap: 2,
                  borderRadius: "0.5rem",
                  fontWeight: 800,
                  boxShadow: 1,
                }}
                onClick={handleUpload}
              >
                <CloudUpload />
                <Typography
                  variant="button"
                  sx={{ width: "100%", textAlign: "left" }}
                >
                  Send File
                </Typography>
              </LoadingButton>
            )}
            </Tooltip>
          </Box>
        )}
        {isUploadAttachmentSuccess && (
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              sx={{
                display: "flex",
                width: "10rem",
                px: 1,
                gap: 2,
                borderRadius: "0.5rem",
                fontWeight: 800,
                boxShadow: 1,
                "&.Mui-disabled": {
                  color: (theme) => theme.palette.primary.main,
                },
              }}
              disabled
            >
              <TaskAltOutlined />
              <Typography
                variant="button"
                sx={{ width: "100%", textAlign: "left" }}
              >
                File Uploaded
              </Typography>
            </Button>
          </Box>
        )}
        {attachmentUrl && !isUploadAttachmentSuccess && (
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              href={attachmentUrl}
              sx={{
                display: "flex",
                width: "10rem",
                px: 1,
                gap: 2,
                borderRadius: "0.5rem",
                fontWeight: 800,
                boxShadow: 1,
              }}
            >
              Download
            </Button>
            {(auth.IsBackOffice || auth.IsLTLManager) && (
              <Tooltip title="Remove current File">
                <DeleteFileAttachmentButton
                  shipmentId={shipmentId}
                  onFileDeleted={() => {
                    toggleAllowUpload();
                  }}
                />
              </Tooltip>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}
