const { styled } = require("@mui/material");
const { DataGridPro } = require("@mui/x-data-grid-pro");

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
    "&.MuiDataGrid-root": {
      border: 0,
      color: "rgba(84,95,125,0.95)",
      fontSize: 13,
      fontWeight: 400,
      lineHeight: 1.43,
      WebkitFontSmoothing: "antialiased",
      letterSpacing: "normal",
      "& .MuiDataGrid-columnsContainer": {
        backgroundColor: "#fafafa",
      },
      "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
        borderRight: "none",
      },
      "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
        borderBottom: "1px solid #f0f0f0",
      },
      "& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaders": {
        fontSize: 12,
        backgroundColor: "#F9FBFC",
        textTransform: "uppercase",
      },
      "& .MuiDataGrid-cell": {
        color: "rgba(84,95,125,0.95)",
      },
      "& .MuiDataGrid-cell.text--cell": {
        textTransform: "uppercase",
      },
      "& .MuiPaginationItem-root": {
        borderRadius: 0,
      },
      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.primary.main,
        borderWidth: "1px",
      },
      "& .MuiDataGrid-cell--editable": {
        backgroundColor: "rgb(249,251,252,0.5)",
        borderBottom: "1px solid rgb(25,118,210,0.16)",
      },
      "& .MuiDataGrid-cell--editable .MuiDataGrid-cell:focus": {
        outline: "inherit",
      },
      "& .MuiDataGrid-cell--editable .MuiDataGrid-cell:focus-within": {
        outline: "inherit",
      },
      "& .MuiDataGrid-cell--editing": {
        backgroundColor: "#fff",
      },
      "& .MuiDataGrid-cell--editing .MuiDataGrid-cell:focus": {
        outline: "none",
      },
      "& .MuiDataGrid-cell--editing .MuiDataGrid-cell:focus-within": {
        outline: "none",
      },
      "& .MuiDataGrid-cell--editing .MuiDataGrid-cell:focus-within .MuiDataGrid-editInputCell": {
        backgroundColor: "#fff",
      },
      "& .MuiDataGrid-cell--editing .MuiDataGrid-cell:focus-within .MuiDataGrid-editInputCell .MuiDataGrid-editInput": {
        color: "rgba(84,95,125,0.95)",
      },
      "& .MuiDataGrid-cell--editing .MuiDataGrid-cell:focus-within .MuiDataGrid-editInputCell .MuiDataGrid-editInput::placeholder": {
        color: "rgba(84,95,125,0.95)",
      },
      "& .MuiDataGrid-cell--editing .MuiDataGrid-cell:focus-within .MuiDataGrid-editInputCell .MuiDataGrid-editInput:focus": {
        outline: "none",
      },
      "& .MuiDataGrid-cell--editing .MuiDataGrid-cell:focus-within .MuiDataGrid-editInputCell .MuiDataGrid-editInput:focus::placeholder": {
        color: "rgba(84,95,125,0.95)",
      },
      "& .MuiDataGrid-cell--editing .MuiDataGrid-cell:focus-within .MuiDataGrid-editInputCell .MuiDataGrid-editInput:focus-visible": {
        outline: "none",
      },
      "& .MuiDataGrid-cell--editing .MuiDataGrid-cell:focus-within .MuiDataGrid-editInputCell .MuiDataGrid-editInput:focus-visible::placeholder": {
        color: "rgba(84,95,125,0.95)",
      },
      "& .MuiDataGrid-cell--editing .MuiDataGrid-cell:focus-within .MuiDataGrid-editInputCell .MuiDataGrid-editInput:hover": {
        outline: "none",
      },
      "& .MuiDataGrid-cell--editing .MuiDataGrid-cell:focus-within .MuiDataGrid-editInputCell .MuiDataGrid-editInput:hover::placeholder": {
        color: "rgba(84,95,125,0.95)",
      },
      "& .MuiDataGrid-cell--editing .MuiDataGrid-cell:focus-within .MuiDataGrid-editInputCell .MuiDataGrid-editInput:hover-visible": {
        outline: "none",
      },
      "& .MuiDataGrid-cell--editing .MuiDataGrid-cell:focus-within .MuiDataGrid-editInputCell .MuiDataGrid-editInput:hover-visible::placeholder": {
        color: "rgba(84,95,125,0.95)",
      },
      "& .MuiDataGrid-cell--editing .MuiDataGrid-cell:focus-within .MuiDataGrid-editInputCell .MuiDataGrid-editInput:active": {
        outline: "none",
      },
      "& .MuiDataGrid-cell--editing .MuiDataGrid-cell:focus-within .MuiDataGrid-editInputCell .MuiDataGrid-editInput:active::placeholder": {
        color: "rgba(84,95,125,0.95)",
      },
      "& .MuiDataGrid-cell:focus-within": {
        outline: "none",
      },
      "& .MuiDataGrid-cell:focus-within .MuiDataGrid-cell:focus": {
        outline: "none",
      },
      "& .MuiDataGrid-cell:focus-within .MuiDataGrid-cell:focus-within": {
        outline: "none",
      },
      "& .MuiDataGrid-cell:focus-within .MuiDataGrid-cell:focus-within .MuiDataGrid-editInputCell": {
        backgroundColor: "#fff",
      },
      "& .MuiDataGrid-cell:focus-within .MuiDataGrid-cell:focus-within .MuiDataGrid-editInputCell .MuiDataGrid-editInput": {
        color: "rgba(84,95,125,0.95)",
      },
      "& .MuiDataGrid-cell:focus-within .MuiDataGrid-cell:focus-within .MuiDataGrid-editInputCell .MuiDataGrid-editInput::placeholder": {
        color: "rgba(84,95,125,0.95)",
      },
      "& .MuiDataGrid-cell:focus-within .MuiDataGrid-cell:focus-within .MuiDataGrid-editInputCell .MuiDataGrid-editInput:focus": {
        outline: "inherit",
      },
      "& .MuiDataGrid-cell:focus-within .MuiDataGrid-cell:focus-within .MuiDataGrid-editInputCell .MuiDataGrid-editInput:focus::placeholder": {
        color: "rgba(84,95,125,0.95)",
      },
      "& .MuiDataGrid-cell:focus-within .MuiDataGrid-cell:focus-within .MuiDataGrid-editInputCell .MuiDataGrid-editInput:focus-visible": {
        outline: "inherit",
      },
      "& .MuiDataGrid-cell:focus-within .MuiDataGrid-cell:focus-within .MuiDataGrid-editInputCell .MuiDataGrid-editInput:focus-visible::placeholder": {
        color: "rgba(84,95,125,0.95)",
      },
      "& .MuiDataGrid-cell:focus-within .MuiDataGrid-cell:focus-within .MuiDataGrid-editInputCell .MuiDataGrid-editInput:hover": {
        outline: "none",
      },
      "& .MuiDataGrid-cell:focus-within .MuiDataGrid-cell:focus-within .MuiDataGrid-editInputCell .MuiDataGrid-editInput:hover::placeholder": {
        color: "rgba(84,95,125,0.95)",
      },
      "& .MuiDataGrid-cell:focus-within .MuiDataGrid-cell:focus-within .MuiDataGrid-editInputCell .MuiDataGrid-editInput:hover-visible": {
        outline: "none",
      },
      "& .MuiDataGrid-cell:focus-within .MuiDataGrid-cell:focus-within .MuiDataGrid-editInputCell .MuiDataGrid-editInput:hover-visible::placeholder": {
        color: "rgba(84,95,125,0.95)",
      },
      "& .MuiDataGrid-cell:focus-within .MuiDataGrid-cell:focus-within .MuiDataGrid-editInputCell .MuiDataGrid-editInput:active": {
        outline: "none",
      },
      "& .MuiDataGrid-cell:focus-within .MuiDataGrid-cell:focus-within .MuiDataGrid-editInputCell .MuiDataGrid-editInput:active::placeholder": {
        color: "rgba(84,95,125,0.95)",
      },
      "& .MuiDataGrid-columnHeader:focus": {
        outline: "none",
      },
      "& .MuiDataGrid-columnHeader:focus .MuiDataGrid-columnHeaderTitleContainer": {
        color: "rgba(84,95,125,0.95)",
      },
      "& .MuiDataGrid-columnHeader:focus .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-columnHeaderTitle": {
        color: "rgba(84,95,125,0.95)",
      },
      "& .MuiDataGrid-columnHeader:focus .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-columnHeaderTitle:focus": {
        outline: "none",
      },
      "& .MuiDataGrid-columnHeader:focus .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-columnHeaderTitle:focus-visible": {
        outline: "none",
      },
      "& .MuiDataGrid-columnHeader:focus .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-columnHeaderTitle:focus-visible::before": {
        backgroundColor: "rgba(84,95,125,0.95)",
      },
      "& .MuiDataGrid-columnHeader:focus .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-columnHeaderTitle:focus-visible::after": {
        backgroundColor: "rgba(84,95,125,0.95)",
      }},
  }));

  export default StyledDataGrid;