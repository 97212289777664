const { Typography } = require("@mui/material");

export function ErrorMessage({ response, defaultMessage }) {
  if (response.errors) {
    return response.errors?.map((error) => {
      if (typeof error == "string") {
        return <Typography key={error} >{error}</Typography>;
      } else if (error.msg || error.message) {
        return (
          <Typography key={error.msg || error.message}>
            {error.msg || error.message} {error.param ? ` at ${error.param}` : ""}
          </Typography>
        );
      }
    });
  }

  if (response?.messages || response?.message)
    return <Typography>{response?.messages || response?.message}</Typography>;
  if (defaultMessage) return <Typography>{defaultMessage}</Typography>;
}
