import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useNavigate } from 'react-router-dom';
import { Roles } from 'src/models/roles';
import { useAuth } from 'src/hooks/useAuth';

const options = [{
  label: 'Create Shipment',
  url: '/shipments/create',
  roles: [Roles.Administrator, Roles.CustomerManager, Roles.WarehouseManager, Roles.SupplierManager],
}, {
  label: 'Create User',
  url: '/users/create',
  roles: [Roles.Administrator, Roles.System],
}];

export default function SplitButton() {
  const { session } = useAuth();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [authorizedOptions, setAuthorizedOptions] = React.useState(
    options.filter(option => option.roles.some(role => role === session?.user?.role?.name))
  );
  
  React.useEffect(() => {
    setAuthorizedOptions(options.filter(option => option.roles.some(role => role === session?.user?.role?.name)));
  }, [session]);

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(authorizedOptions[selectedIndex].url);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  if (!authorizedOptions.length) return (<></>); 

  return (
    <React.Fragment>
      <ButtonGroup variant="outlined" ref={anchorRef} aria-label="split button" sx={{ my: 2, ml: 2 }}>
        <Button size="small" onClick={handleClick} startIcon={<AddOutlinedIcon />}>
          {authorizedOptions[selectedIndex].label}
        </Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="create items"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon fontSize='small' />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {authorizedOptions.map((option, index) => {
                    return (
                      <MenuItem
                        key={option.label}
                        //   disabled={index === 2}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        <ListItemIcon>
                          <AddOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        {option.label}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}