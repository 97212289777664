import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useFormSteps } from "./FormStepper";
import { ChevronLeft, ChevronRight, Done } from "@mui/icons-material";

export function FormSteps() {
  const { stepList, currentStepIndex, setCurrentStepIndex } = useFormSteps();

  return (
    <Stepper
      activeStep={currentStepIndex}
      sx={{
        "& .MuiStepLabel-label.Mui-active": {
          fontWeight: 800,
        },
        "& .MuiStepLabel-labelContainer .MuiStepLabel-label": {
          color: 'white'
        },
        '& .Mui-completed .MuiSvgIcon-root': {
          color: 'white'
        },
        '& .Mui-active .MuiStepIcon-text': {
          fill: '#637381'
        },

        '& .Mui-active circle': {
          color: 'white'
        },
      }}
    >
      {stepList.map((step, index) => {
        const { isValid, completed } = step;
        const labelProps = { completed };

        // console.debug(isValid, step.label, step, stepList, currentStepIndex);
        return (
          <Step completed={completed} key={step.label}>
            <StepLabel
              {...labelProps}
              sx={{ cursor: "pointer" }}
              optional={
                step.optional && (
                  <Typography fontSize={"0.6rem"} color={'white'}>{step.optional}</Typography>
                )
              }
              onClick={() => setCurrentStepIndex(index)}
            >
              {step.label}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
}

export function StepsHandlers() {
  const { isLastStep, handleNext, isFirstStep, handlePrevious } =
    useFormSteps();
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        py: 2,
      }}
    >
      {!isFirstStep && (
        <Button
          sx={{ mr: "auto" }}
          onClick={handlePrevious}
          variant="contained"
        >
          <ChevronLeft /> Previous
        </Button>
      )}
      {!isLastStep && (
        <Button sx={{ ml: "auto" }} onClick={handleNext} variant="contained">
          Next <ChevronRight />
        </Button>
      )}
    </Box>
  );
}

export function NextStepButton({ buttonProps, onLastStepTapped, reset }) {
  const { isLastStep, handleNext, handleReset } = useFormSteps();
  return (
    <Button
      sx={{ ml: "auto" }}
      onClick={() => {
        handleNext(onLastStepTapped)
        
        if (reset && isLastStep) handleReset();
      }}
      disabled={isLastStep && !onLastStepTapped}
      {...buttonProps}
    >
      {isLastStep && onLastStepTapped ? 'Done' : 'Next'} 
      {isLastStep && onLastStepTapped ? <Done /> : <ChevronRight />}
    </Button>
  );
}

export function PreviousStepButton({ buttonProps }) {
  const { isFirstStep, handlePrevious } = useFormSteps();
  return (
    <Button
      sx={{ mr: "auto" }}
      onClick={handlePrevious}
      disabled={isFirstStep}
      {...buttonProps}
    >
      <ChevronLeft /> Previous
    </Button>
  );
}
