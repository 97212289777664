import { Checkbox, FormControlLabel, MenuItem, Stack } from "@mui/material";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormInput } from "src/components/form/FormInput";

/**
 * type = 'pickup' | 'stop'
 * */
export const serviceDetails = (type) => ({
  name:
    type == "pickup"
      ? "services.limitedAccessPickup"
      : "services.limitedAccessDelivery",
  key: type == "pickup" ? "limitedAccessPickup" : "limitedAccessDelivery",
  label: "Limited Access",
  options: [
    { label: "Unspecified", value: "UNSPE" },
    { label: "Hotel", value: "HOTL" },
    { label: "Military Base", value: "BASE" },
    { label: "Remote Government", value: "GOVT" },
    { label: "Mini-Storage", value: "MINI" },
    { label: "School", value: "SCHL" },
    { label: "Construction Site", value: "SITE" },
    { label: "Airport", value: "AIRP" },
    { label: "Container Station", value: "CNST" },
    { label: "Hospital/Medical Facility", value: "HMED" },
    { label: "Shopping Center/Mall", value: "SHCM" },
    { label: "Farm Country Club Estate", value: "FARMC" },
    { label: "Fair/Amusement Park", value: "FAIR" },
    { label: "Convention Center", value: "CVNT" },

    // * NOT MAPPED
    // ! GIVEN VALUES
    // "Nursing Home",
    // "Restaurant",
    // "Utility Site",
    // "Golf Course",
    // "Reservation",
    // "Recreational/athletic Facility",
  ],
});

/**
 * type = 'pickup' | 'stop'
 * */
export function LimitedAccessField({ type }) {
  const { watch } = useFormContext();

  if (watch(serviceDetails(type).name))
    return (
      <FormInput
        name={`${serviceDetails(type).name}Type`}
        select
        label={serviceDetails(type).label}
        sx={{ width: "100%" }}
        fullWidth
        required
      >
        {serviceDetails(type).options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </FormInput>
    );
}
