import {
  Card,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  Button,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import { useTenantDetails } from "../hooks/useTenantDetails";
import { ContentCopy } from "@mui/icons-material";

export function TenantIntegrations() {
  const { tenant } = useTenantDetails();
  const { integrations } = tenant;

  console.log(tenant);
  return (
    <Card
      sx={{
        py: ".5rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.75rem",
      }}
      elevation={0}
    >
      <CardHeader
        title={"Integrations"}
        sx={{ "&.MuiCardHeader-root": { p: 0 } }}
      />
      <Grid
        container
        sx={{ width: "100%", justifyContent: "space-between", rowGap: 2 }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography fontWeight={600}>Trinity</Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography>{integrations?.trinity}</Typography>
            <CopyButton value={integrations?.trinity} />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography fontWeight={600}>Banyan</Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography>{integrations?.banyan}</Typography>
            <CopyButton value={integrations?.banyan} />
          </Box>{" "}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography fontWeight={600}>Backoffice ID</Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography>{integrations?.backoffice}</Typography>
            {/* TODO - Button to copy integration value */}
            <CopyButton value={integrations?.backoffice} />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography fontWeight={600}>ASN</Typography>
          <Checkbox checked={!!integrations?.asn} disabled />
        </Grid>
      </Grid>
    </Card>
  );
}
export function CopyButton({ value }) {
  return (
    <IconButton
      size="small"
      variant="contained"
      onClick={() => navigator.clipboard.writeText(value)}
      disabled={!value}
    >
      <ContentCopy fontSize="0.5rem" />
    </IconButton>
  );
}
