import { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import "./fc.css"

import {
  Box,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { EventDetailsDrawer } from "src/pages/schedule/components/ScheduleDrawer";
import useResponsive from "src/hooks/useResponsive";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/New_York");

// import { tokens } from '../theme';

export function Calendar({ events, handleRefreshCalendar }) {
  // const theme = useTheme();
  // const colors = tokens(theme.palette.mode);
  const [currentEvents, setCurrentEvents] = useState([...events]);

  useEffect(() => {
    if (events)
      setCurrentEvents(events);
  }, [events]);

  const [drawerVisibility, setDrawerVisibility] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    if (!drawerVisibility && !!selectedEvent) setDrawerVisibility(true);
  }, [selectedEvent]);

  function handleEventClick(selected) {
    setSelectedEvent(selected.event);
  }

  function handleEventRemove(calendarEvent) {
    calendarEvent.remove();
  }

  function handleDateClick(selected) {
    const breakpoint = new Date(new Date().toDateString());
    if (selected.end < breakpoint)
      return;
    
    // TODO - VERIFY CONFLICTS
    // addEvent(selectedDate);
    setSelectedEvent(selected);
  }

  const detailDrawerVisibilityHandler = (isVisible) => {
    if (!isVisible) {
      setSelectedEvent(null);
      setDrawerVisibility(false);
    }
  };

  const calendarRef = useRef(null)
  const isMobile = useResponsive('down', 'sm');

  useEffect(()=> {
    if(calendarRef.current){
      if (isMobile) {
        calendarRef.current?.getApi()?.changeView('timeGridDay');
      } else {
        calendarRef.current?.getApi()?.changeView('dayGridMonth');
      }
    }
  }, [isMobile])


  return (
    <Box m="20px">
      {/* <MainHeader title='Calendar' subtitle='Full Calendar Interactive Page' /> */}
      <Box display="flex" justifyContent="space-between">
        {/* CALENDAR SIDEBAR */}
        {/* <EventsListSidebar events={currentEvents} /> */}

        {/* CALENDAR */}
        <Box flex="1 1 100%" ml="15px">
          <FullCalendar
            ref={calendarRef}
            timeZone="America/New_York"
            height="75vh"
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin,
            ]}
            headerToolbar={{
              left: "prev,next,today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
            }}
            initialView="dayGridMonth"
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            select={handleDateClick}
            eventClick={handleEventClick}
            // eventsSet={(events) => setCurrentEvents(events)}
            events={currentEvents}
          />
        </Box>
      </Box>
      {drawerVisibility && (
        <EventDetailsDrawer
          // onEditTapped={onEditTapped} - EDIT THE EVENT
          // anchor='right'
          handleRefreshCalendar={handleRefreshCalendar}
          detailDrawerVisibilityHandler={detailDrawerVisibilityHandler}
          onVisibilityChanged={detailDrawerVisibilityHandler}
          selectedItem={selectedEvent}
        />
      )}
    </Box>
  );
}

function EventsListSidebar() {
  return (
    <Box
      flex="1 1 20%"
      // background-color={colors.primary[400]}
      p="15px"
      borderRadius="4px"
    >
      <Typography variant="h5">Events</Typography>
      <List>
        {metadata.status?.map((statusMeta) => (
          <ListItem
            key={statusMeta.status}
            sx={{
              margin: "10px 0",
              borderRadius: "2px",
            }}
          >
            <Box className="fc-daygrid-event-harness" sx={{ marginTop: "0px" }}>
              <a className="fc-event fc-event-draggable fc-event-resizable fc-event-start fc-event-end fc-event-today fc-daygrid-event fc-daygrid-dot-event">
                <Box
                  className="fc-daygrid-event-dot"
                  sx={{ borderColor: statusMeta.backgroundColor }}
                ></Box>
                <Box className="fc-event-title">{statusMeta.status}</Box>
              </a>
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

const metadata = {
  types: [
    {
      type: "schedule",
      borderColor: "blue",
    },
    {
      type: "inbound",
      borderColor: "#616161",
    },
    {
      type: "outbound",
      borderColor: "#455a64",
    },
  ],
  status: [
    { status: "approved", backgroundColor: "#378006" },
    { status: "pending", backgroundColor: "#ffc400" },
    { status: "rejected", backgroundColor: "#ff3d00" },
  ],
};
