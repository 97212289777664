import { Box, DialogContent, DialogContentText } from "@mui/material";
import { StandardDialog } from "src/components/dialog/Dialog";
import Lottie from 'lottie-react';
import shippingAnimation from 'src/assets/animation/shipping.json'
import { useEffect, useState } from "react";

export const SavingShipmentDialog = ({ isOpen }) => {
    const statuses = [
        {
            text: "Preparing your packages...",
            time: 2000,
        },
        {
            text: "Checking your locations...",
            time: 2500,
        },
        {
            text: "Searching for the best route...",
            time: 3000,
        },
        {
            text: "Uploading package slips...",
            time: 3000,
        },
        {
            text: "Saving your shipment...",
            time: 6000,
        },
        {
            text: "Almost there...",
            time: 6000,
        },
        {
            text: "Hang tight, we're finishing it...",
            time: 6000,
        },
    ];


    const [loading, setLoading] = useState(statuses[0]);

    useEffect(() => {
        const interval = setInterval(() => {
            setLoading(prev => {
                const currentIndex = statuses.indexOf(prev);
                const nextIndex = (currentIndex + 1) % statuses.length;
                return statuses[nextIndex];
            });
        }, loading.time); // Change text every 2 seconds

        // Clear interval on component unmount
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        setLoading(statuses[0]);
    }, [!isOpen])

    return <StandardDialog isOpen={isOpen}>
        <Box sx={{ width: '100%' }}>
            <DialogContent sx={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center'
            }}>
                <Box>
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Lottie
                            animationData={shippingAnimation}
                            style={{ width: '200px', height: '200px' }}
                        />
                    </Box>
                    <DialogContentText
                        sx={{
                            mt: '4rem',
                            fontSize: '1.5rem',
                            textAlign: 'center'
                        }}
                    >
                        {loading.text}
                    </DialogContentText>
                    <DialogContentText
                        sx={{
                            fontSize: '0.8rem',
                            textAlign: 'center'
                        }}
                    >
                        Do not close this tab until the shipment is saved.
                    </DialogContentText>
                </Box>
            </DialogContent>
        </Box>
    </StandardDialog>;
}