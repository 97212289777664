import config from "src/config.js";
import { BaseService } from "./base.service";

const baseUrl = config.apiUrl;
const prefix = "/reports";
const getAvailableReports = `${prefix}/avaliables`;
const getReportAccessURL = `${prefix}/dashboard/:dashboard`;
export class ReportService extends BaseService {
  async getAvailableReports() {
    const response = await fetch(baseUrl + getAvailableReports, this.header());

    return await response.json();
  }

  async getUserReports() {
    const response = await fetch([baseUrl, prefix].join(""), this.header());

    return await response.json();
  }

  async getReportAccessURL(dashboard) {
    if(!dashboard)
      throw new Error('Invalid Dashboard');

    const response = await fetch(
      [baseUrl, getReportAccessURL.replace(':dashboard', dashboard)].join(""),
      this.header()
    );

    return await response.json();
  }
}
