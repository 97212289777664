import { DeleteOutlineOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

import { useNavigate } from "react-router-dom";
import { useShipmentDetails } from "../context/ShipmentDetailsContext";
import { useDeletion } from "../hooks/useDeletion";
import { IconButton } from "@mui/material";

export function DeleteShipmentButton({ allowDeletion, ...props }) {
  const { deleteShipment, isDeletionLoading, shipment } = useShipmentDetails();
  const { handleOpenClickDeletionDialog, DeletionDialog } =
    useDeletion(handleDeleteShipment);

  const callback = props.deletionCallback ? props.deletionCallback : null;

  const navigate = useNavigate();

  function handleDeleteShipment() {
    deleteShipment(shipment._id);
    const deletionCallback = () => {
      navigate("/");
      callback && callback();
    };

    setTimeout(deletionCallback, 3000);
  }

  if (allowDeletion) {
    return (
      <>
        <IconButton
          variant="text"
          type="button"
          color="inherit"
          onClick={handleOpenClickDeletionDialog}
          sx={{
            py: "0.8rem",
            width: "2rem",
            height: "2rem"
          }}
        >
          <DeleteOutlineOutlined />
        </IconButton>
        {DeletionDialog}
      </>
    );
  }
}
