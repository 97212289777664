import React, { useEffect, useRef, useState } from "react";
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Typography,
} from "@mui/material";
import { StandardDialog } from "src/components/dialog/Dialog";
import { MdPreview } from "md-editor-rt";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MDEditor from "@uiw/react-md-editor";
import ReactMarkdown from "react-markdown";

export default function DialogWhatsNew(props) {
    const [checked, setChecked] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    let sliderRef = useRef(null);
    const next = () => {
        props.onDone();
    }

    const previous = () => {
        sliderRef.current.slickPrev();
    };

    const handleChange = (event) => {
        localStorage.setItem("changeSeem", event.target.checked);
        setChecked(event.target.checked);
    };

    const settings = {
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => setCurrentIndex(current),
    };

    return (
        <StandardDialog onClose={props.onClose} isOpen={props.open}>
            <Box sx={{ width: "100%" }}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '1rem'
                    }}
                >
                    <Box
                        component={'img'}
                        src="/assets/welcome-aboard-pana.svg"
                        alt="Descrição do SVG"
                        width={"10%"}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Typography sx={{ fontSize: '1.6rem', padding: 0 }}>Dear, {props.userName}</Typography>
                        <Typography sx={{ fontSize: '1rem', padding: 0 }}>Update - November 04, 2024</Typography>
                    </Box>
                </Box>
                <Slider
                    ref={slider => {
                        sliderRef.current = slider
                    }}
                    {...settings}

                >
                    <Box>
                        <ReactMarkdown>
                            {whatsNewContent}
                        </ReactMarkdown>
                    </Box>
                </Slider>
                <Box sx={{ display: "flex", justifyContent: "space-between", mt: '2rem' }}>
                    <Box></Box>
                    <Box sx={{ display: 'flex' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checked}
                                    onChange={handleChange}
                                    name="changeSeem"
                                    color="primary"
                                />
                            }
                            label="I see this change!"
                            sx={{ justifyContent: "flex-start", width: "100%" }}
                        />
                        <Button variant="contained" onClick={next}>{'Done'}</Button>
                    </Box>
                </Box>
            </Box>
        </StandardDialog>
    );
}

const whatsNewContent = `

We are pleased to announce a new update to our shipment creation process. We encourage you to take a moment to review the changes outlined below as they will be required fields moving forward.

**New Updates:**

- **Pieces per Pallet**: Users will now need to specify the number of pieces contained in each pallet. 

- **Uploading Packing Slip**: A PDF file of the shipping packing slip will now be required during the shipment creation process. 

- **Items**: When creating a shipment, users must add an item for each type of product included in the pallet. Each item should have a SKU number, a description, the carton number (if applicable), and the quantity of items per box. 

- **Attachments**: Users can now attach their commercial invoice to the shipment being created. 

 
**Purpose of This Update**: These changes will enable us to provide more accurate and detailed documentation for each shipment, enhancing your overall experience. 

 
If you have any questions or concerns, please feel free to contact our support team through the "Contact Us" prompt in the portal or by emailing support@loadmanager.io. 

 
Thank you for your continued support. 

Best regards, 
The Load Manager Support Team 
`;
