import { Box, Typography } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { VendorService } from "src/api/vendor.service";
import { useAuth } from "src/hooks/useAuth";
import { useSnackbar } from "src/hooks/useSnackbar";

export const useVendorQueries = () => {
    const navigate = useNavigate();
    const { setSnackbarMessage } = useSnackbar();
    const { session } = useAuth();
    const { vendorId } = useParams();    
    const service = new VendorService(session.token, session.tenant._id);

    const { mutate: createVendor, isLoading: isCreatingVendor } = useMutation({
        mutationFn: (values) =>
            service
                .createVendor(values)
                .then((data) => {
                    setSnackbarMessage({
                        message: `New vendor ${data.name} created successfully.`,
                        severity: "success",
                    });

                    navigate(`/vendors/${data._id}`);
                    return data;
                }).catch((error) => {
                    setSnackbarMessage({
                        message: (
                            <Box>
                                <Typography mb={1}>Error submitting vendor</Typography>
                                <Typography>{error.message}</Typography>
                            </Box>
                        ),
                        severity: "error",
                    });
                }),
    });

    const { mutate: updateVendor, isLoading: isUpdatingLoading } = useMutation({        
        mutationFn: (values) =>            
            service.updateVendor(vendorId, values).then((data) => {
                    setSnackbarMessage({
                        message: `Note updated`,
                        severity: "success",
                    });

                    navigate(`/vendors/${vendorId}`);     
                    console.log(data)         
                    return data;                    
                }).catch((error) => {
                    setSnackbarMessage({                        
                        message: (
                            <Box>
                                <Typography mb={1}>Error update vendor</Typography>
                                <Typography>{error.message}</Typography>
                            </Box>
                        ),                        
                        severity: "error",
                    });
                    console.log(vendorId)
                    console.log(values)                    
                }),                
    });

    const {
        isLoading: isDeletingVendor,
        mutate: deleteVendor,
    } = useMutation({
        mutationFn: (idVendor) => {
            service
                .deleteVendor(idVendor)
                .then((data) => {
                    if (data) {
                        setSnackbarMessage({
                            message: 'Vendor was deleted with success!',
                            severity: 'success',
                        });
                        navigate("/vendors/create");
                        return data;
                    }                    
                })
                .catch((error) => {
                    setSnackbarMessage({
                        message: (
                            <Box>
                                <Typography mb={1}>Error to deleting vendor</Typography>
                                <Typography>{error.message}</Typography>
                            </Box>
                        ),
                        severity: "error",
                    });
                })
        }
    });

    const handleDelete = (idVendor) => {
        deleteVendor(idVendor);
    }


    
    const {
        isLoading: isLoadingVendor,        
        data: vendor,
        refetch: getVendorById,
    } = useQuery({
        queryFn: async () => {
            return await service.getVendorById(vendorId);
        },
        enabled: false
    });        

    return {
        createVendor,
        isCreatingVendor,

        handleDelete,
        isDeletingVendor,

        updateVendor,
        isUpdatingLoading,

        vendor,
        isLoadingVendor,
        getVendorById
    };
};