import { Box, Skeleton } from "@mui/material"

export const ReportDashboardLoading = () => {
    return (
        <Box sx={{
            position: 'absolute',
            gap: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '75vh',
            width: '85%',
        }}>
            <Box sx={{ display: 'flex', gap: 2 }}>
                <Skeleton variant="rectangular" sx={{ height: '14vw', width: '14vw', borderRadius: '2rem' }} />
                <Skeleton variant="rectangular" sx={{ height: '14vw', width: '14vw', borderRadius: '2rem' }} />
                <Skeleton variant="rectangular" sx={{ height: '14vw', width: '14vw', borderRadius: '2rem' }} />
                <Skeleton variant="rectangular" sx={{ height: '14vw', width: '14vw', borderRadius: '2rem' }} />
            </Box>
            <Box sx={{ display: 'flex', gap: 2 }}>
                <Skeleton variant="rectangular" sx={{ height: '14vw', width: '14vw', borderRadius: '2rem' }} />
                <Skeleton variant="rectangular" sx={{ height: '14vw', width: '14vw', borderRadius: '2rem' }} />
                <Skeleton variant="rectangular" sx={{ height: '14vw', width: '14vw', borderRadius: '2rem' }} />
                <Skeleton variant="rectangular" sx={{ height: '14vw', width: '14vw', borderRadius: '2rem' }} />
            </Box>
        </Box>
    )
}