import NotificationsIcon from "@mui/icons-material/Notifications";
import { Badge, IconButton } from "@mui/material";
import {
  NovuProvider,
  PopoverNotificationCenter,
} from "@novu/notification-center";
import { useAuth } from "src/hooks/useAuth";

export default function NotificationsPopover() {
  const onNotificationClick = (message) => {
    console.log(
      "The payload of the selected notification is ",
      message.payload
    );
  };

  const { session } = useAuth();
  // console.log("User notification suscriberId is ", session.id);

  return (
    <>
      <NovuProvider
        subscriberId={session.user.id.toString()}
        applicationIdentifier={"PnaixzkVpwoc"}
      >
        <PopoverNotificationCenter
          colorScheme={"light"}
          footer={() => {}}
          onNotificationClick={onNotificationClick}
          showUserPreferences={false}
        >
          {({ unseenCount }) => (
            <IconButton size="large">
              <Badge badgeContent={unseenCount} color="info">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          )}
        </PopoverNotificationCenter>
      </NovuProvider>
    </>
  );
}
