import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
import { useTenantDetails } from "./hooks/useTenantDetails";
import { ContactsDetails } from "../location/components/ContactDetails";

export function TenantContactDetails() {
  const { tenant } = useTenantDetails();
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "0.75rem",
        flex: 1,
      }}
      elevation={0}
    >
      <CardHeader
        component={() => (
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", xl: "row" },
              justifyContent: "space-between",
              py: "0.25rem",
              gap: "0.6rem",
            }}
          >
            <Typography
              variant="h1"
              fontWeight={600}
              sx={{
                "&.MuiTypography-h1": {
                  fontSize: "1.2rem",
                },
              }}
            >
              Contact
            </Typography>
          </Box>
        )}
        sx={{
          "&.MuiCardHeader-root": {
            p: 0,
          },
        }}
      />
      <CardContent
        sx={{
          "&.MuiCardContent-root": {
            p: 0,
          },
        }}
      >
        <ContactsDetails contacts={tenant?.billingInfo?.contacts} />
      </CardContent>
    </Card>
  );
}
