import {
  Card,
  Button,
  Tooltip,
  Typography,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

export function ReportCard({ report }) {
  const navigator = useNavigate();
  const onLinkTapped = () => {
    navigator(`dashboard/${report.dashboardId}`);
  };

  return (
    <Card
      variant="outlined"
      sx={{
        borderRadius: "12px",
        padding: "24px",
        height: "10rem",
        width: "16rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Typography fontWeight={"bold"} fontSize={"18px"} marginBottom={'8px'}>{report.name}</Typography>
      <Tooltip title="Open report" >
        <Button onClick={onLinkTapped}>
          OPEN REPORT
        </Button>
      </Tooltip>
    </Card>
  );
}
