import {
  addDays,
  addMonths,
  addWeeks,
  format,
  getWeek,
  isSameDay,
  lastDayOfWeek,
  startOfWeek,
  subMonths,
  subWeeks,
} from 'date-fns';
import { useState } from 'react';
import './calendar.css';

const DAY_OF_WEEK_FORMAT = 'EEE';
const MONTH_OF_YEAR_FORMAT = 'MMMM yyyy';
const DAY_NUMBER_FORMAT = 'd';

export const WeekCalendar = ({ startingDate = new Date(), onClickHandler }) => {
  const [currentMonth, setCurrentMonth] = useState(startingDate);
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  const [selectedDate, setSelectedDate] = useState(startingDate);

  const changeMonthHandle = (btnType) => {
    if (btnType === 'prev') {
      setCurrentMonth(subMonths(currentMonth, 1));
    }
    if (btnType === 'next') {
      setCurrentMonth(addMonths(currentMonth, 1));
    }
  };

  const changeWeekHandle = (btnType) => {
    // console.log("current week", currentWeek);
    if (btnType === 'prev') {
      // console.log(subWeeks(currentMonth, 1));
      setCurrentMonth(subWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
    }
    if (btnType === 'next') {
      // console.log(addWeeks(currentMonth, 1));
      setCurrentMonth(addWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
    }
  };

  const onClickDayCell = (cloneDay) => {
    // const dayStr = format(cloneDay, 'ccc dd MMM yy');
    setSelectedDate(cloneDay);
    onClickHandler(cloneDay);
  };

  const renderHeader = () => (
    <div className="header row flex-middle">
      <div className="col col-start">
        <div
          className="icon"
          onClick={() => changeWeekHandle('prev')}
          onKeyDown={() => changeWeekHandle('prev')}
          role="button"
          tabIndex="0"
        >
          prev week
        </div>
      </div>
      <div className="col col-center">
        <span>{format(currentMonth, MONTH_OF_YEAR_FORMAT)}</span>
      </div>
      <div className="col col-end">
        <div
          className="icon"
          onClick={() => changeWeekHandle('next')}
          onKeyDown={() => changeWeekHandle('next')}
          role="button"
          tabIndex="0"
        >
          next week
        </div>
      </div>
    </div>
  );

  const renderDays = () => {
    const days = [];
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    for (let i = 0; i < 7; i += 1) {
      days.push(
        <div className="col col-center" key={i}>
          {format(addDays(startDate, i), DAY_OF_WEEK_FORMAT)}
        </div>
      );
    }
    return <div className="days row">{days}</div>;
  };

  const renderCells = () => {
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = '';
    while (day <= endDate) {
      for (let i = 0; i < 7; i += 1) {
        formattedDate = format(day, DAY_NUMBER_FORMAT);
        const cloneDay = day;
        days.push(
          <div
            className={`col cell ${isSameDay(day, selectedDate) ? 'selected' : ''}`}
            key={day}
            onClick={() => onClickDayCell(cloneDay)}
            onKeyDown={() => onClickDayCell(cloneDay)}
            role="button"
            tabIndex="0"
          >
            <span className="number">{formattedDate}</span>
            <span className="bg">{formattedDate}</span>
          </div>
        );
        day = addDays(day, 1);
      }

      rows.push(
        <div className="row" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  };

  return (
    <div className="calendar">
      {renderHeader()}
      {renderDays()}
      {renderCells()}
    </div>
  );
};
