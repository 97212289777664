import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import { valueGetter } from "src/components/drawer/DetailsDrawer";

export function ContactsDetails({ contacts }) {
  return (
    <Stack
      sx={{
        gap: 2,
      }}
    > 
      {!contacts && 
        <Typography color={'GrayText'}> No contact available </Typography>
      }
      {contacts &&
        contacts.map((contact, index) => {
          return (
            <Card
              key={index}
              variant="outlined"
              sx={{
                py: "0.5rem",
                px: "1rem",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "0.75rem",
              }}
            >
              <CardHeader
                sx={{
                  "&.MuiCardHeader-root": {
                    p: 0,
                  },
                }}
              />
              <CardContent
                sx={{
                  "&.MuiCardContent-root": {
                    p: 0,
                  },
                }}
              >
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      py: "0.25rem",
                    }}
                  >
                    <Typography fontWeight={600}>Name</Typography>
                    <Typography>{valueGetter(contact.name)}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      py: "0.25rem",
                    }}
                  >
                    <Typography fontWeight={600}>E-mail</Typography>
                    <Typography>{valueGetter(contact.email)}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      py: "0.25rem",
                    }}
                  >
                    <Typography fontWeight={600}>Phone</Typography>
                    <Typography>{valueGetter(contact.phone)}</Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          );
        })}
    </Stack>
  );
}
