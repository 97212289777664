// TODO refactor to a base URL and move it to the .env file
import config from "src/config.js";

const baseUrl = config.apiUrl;
const resourcePath = `/auth/login`;

export const postLogin = async (credentials) => {
  const response = await fetch(baseUrl + resourcePath, {
    method: 'POST',
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials)
  });

  if (response.status !== 200) {
    return {
      status: response.status,
    };
  }

  const jsonResponse = await response.json();
  return {
    status: 200,
    value: jsonResponse,
  };
};