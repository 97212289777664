import {
  z
} from "zod";
import {
  Equipment
} from "./equipment";
import {
  Hazardous
} from "./hazardous";
import {
  Item
} from "./item";
import {
  Pickup
} from "./pickup";
import {
  Service
} from "./service";
import {
  Stop
} from "./stop";
import {
  shipmentServices
} from "src/pages/shipments/constants/services";

export class Shipment {
  constructor(shipmentData) {
    this._id = shipmentData._id;
    this.primaryReference = shipmentData.primaryReference;
    this.poNumber = shipmentData.poNumber;
    this.shipmentNumber = shipmentData.shipmentNumber;
    this.isTransfer = shipmentData.isTransfer;
    this.quantity = shipmentData.quantity;
    this.quantityUOM = shipmentData.quantityUOM;
    this.weight = shipmentData.weight;
    this.weightUOM = shipmentData.weightUOM;
    this.width = shipmentData.width;
    this.length = shipmentData.length;
    this.height = shipmentData.height;
    this.dimensionUOM = shipmentData.dimensionUOM;
    this.stackability = shipmentData.stackability;
    this.status = shipmentData.status;
    this.mode = shipmentData.mode;
    this.internalStatus = shipmentData.internalStatus;
    this.pickups = shipmentData.pickups.map(
      (pickupData) => new Pickup(pickupData)
    );
    this.stops = shipmentData.stops.map((stopData) => new Stop(stopData));
    this.services = shipmentData.services;
    this.equipment = shipmentData.equipment;
    this.created = shipmentData.created;
    this.updated = shipmentData.updated;
    this.specialInstructions = shipmentData.specialInstructions;
    this.source = shipmentData.source;
    this.tenant = shipmentData.tenant;
    this.integrations = shipmentData.integrations;
    this.integrated = shipmentData.integrated;
    this.mileage = shipmentData.mileage;
  }

  needsValidation() {
    const needsValidation = !!(this.poNumber?.length > 0) &&
      !this.validated &&
      !this.isModeLogisticIntregationSuccess();

    return needsValidation;
  }

  isModeLogisticIntregationSuccess() {
    switch (this.mode) {
      case "TL":
        return (
          this.integrations?.find(
            (integration) => integration?.target == "trinity"
          )?.status == "success"
        );
      case "LTL":
        return (
          this.integrations?.find(
            (integration) => integration?.target == "banyan"
          )?.status == "success"
        );
      default:
        return false;
    }
  }

  // CONVERTERS
  static fromJson(shipment, type) {
    if (!shipment.poNumber || shipment.poNumber === 'transfer') {
      shipment.poNumber = null;
    } else {
      shipment.poNumber = Array.isArray(shipment.poNumber) ? shipment.poNumber : shipment.poNumber.split(',');
    }

    if (!shipment.shipmentNumber) {
      shipment.shipmentNumber = null;
    } else {
      shipment.shipmentNumber = Array.isArray(shipment.shipmentNumber) ? shipment.shipmentNumber : shipment.shipmentNumber.split(',');
    }

    shipment.items = shipment.pickups[0].items;

    shipment.items.forEach(item => {
      if (item.poNumber) {
        item.poNumber = item.poNumber.split(',');
      }
    });

    delete shipment.pickups[0].items;
    delete shipment.stops[0].items;

    if (shipment.mode == 'LTL' && shipment.services) {
      shipment.services.limitedAccessDeliveryType = shipment.services?.limitedAccessDelivery ? shipment.services.limitedAccessDelivery : '';
      shipment.services.limitedAccessPickupType = shipment.services?.limitedAccessPickup ? shipment.services.limitedAccessPickup : '';

      if (!shipment.services.limitedAccessDelivery) shipment.services.limitedAccessDelivery = '';
      if (!shipment.services.limitedAccessPickup) shipment.services.limitedAccessPickup = '';
    }

    if (type == 'duplicate') {
      const tmp = {};
      tmp.pickups = shipment.pickups;
      tmp.stops = shipment.stops;
      tmp.customer = shipment.customer;
      tmp.mode = shipment.mode;
      tmp.isTransfer = shipment.isTransfer;
      shipment = tmp;

      delete shipment.pickups[0].targetShipEarly;
      delete shipment.pickups[0].targetShipLate;
      delete shipment.stops[0].targetDeliveryEarly;
      delete shipment.stops[0].targetDeliveryLate;
    }

    return shipment;
  }

  static toJson(shipment) {
    shipment.items.forEach((item) => {
      if (item.poNumber && Array.isArray(item.poNumber)) {
        item.poNumber = item.poNumber.join(',');
      }

      if (!item.description) return;
      item.description = item.description.replace(/"/g, '"').replace(/'/g, "'");
    });

    shipment.pickups[0].items = [...shipment.items];
    shipment.stops[0].items = [...shipment.items];

    delete shipment.items;

    // TRANSFER or PO Number VALIDATION
    if (!!shipment.isTransfer) {
      delete shipment.poNumber;
    } else {
      if (shipment.poNumber) {
        shipment.poNumber = Array.isArray(shipment.poNumber) ?
          shipment.poNumber
          ?.map((poNumber) => {
            return poNumber.trim();
          })
          .join(",") :
          shipment.poNumber;
      }
    }
    // SHIPMENT NUMBERS
    if (shipment.shipmentNumber) {
      shipment.shipmentNumber = Array.isArray(shipment.shipmentNumber) ?
        shipment.shipmentNumber
        ?.map((shipmentNumber) => {
          if (typeof (shipmentNumber) === String) return shipmentNumber.trim();
          return shipmentNumber;
        })
        .join(",") :
        shipment.shipmentNumber;
    }

    // ORDERS NUMBERS
    if (shipment.ordersNumbers) {
      shipment.ordersNumbers = Array.isArray(shipment.ordersNumbers) ?
        shipment.ordersNumbers
        ?.map((ordersNumbers) => {
          return ordersNumbers.trim();
        })
        .join(",") :
        shipment.ordersNumbers;
    }

    // MODE SERVICES VERIFICATION
    const mode = shipment.mode?.toUpperCase();
    const isLTL = mode === "LTL";
    const isTL = mode === "TL";

    const LTLServices = [
      ...shipmentServices.find(
        (servicePerMode) => servicePerMode.mode === "LTL"
      ).services,
      ...shipmentServices.find(
        (servicePerMode) => servicePerMode.mode === "LTL"
      ).pickups,
      ...shipmentServices.find(
        (servicePerMode) => servicePerMode.mode === "LTL"
      ).stops,
    ].map(service => service.key);

    const TLServices = [
      ...shipmentServices.find(
        (servicePerMode) => servicePerMode.mode === "TL"
      ).services,
      ...shipmentServices.find(
        (servicePerMode) => servicePerMode.mode === "TL"
      ).pickups,
      ...shipmentServices.find(
        (servicePerMode) => servicePerMode.mode === "TL"
      ).stops,
    ].map(service => service.key);


    if (isLTL) {
      // REMOVE SELECTED EQUIPMENTS
      if (shipment.equipment)
        delete shipment.equipment

    } else {

      if (shipment.services) {
        const exclusiveLTLServices = LTLServices.filter(service => !TLServices.includes(service))

        exclusiveLTLServices.forEach((key) => {
          if (shipment.services[key])
            shipment.services[key] = false;
        });
      }

      if (shipment.equipment) {
        const tmpId = shipment.equipment._id;
        delete shipment.equipment._id;

        const hasSelectedEquipment = Object.values(shipment.equipment).some(v => v);
        if (!hasSelectedEquipment) throw Error('Select at least one equipment');

        if (typeof (tmpId) === 'string') shipment.equipment._id = tmpId;
      }
    }


    // FORMAT LIMITED ACCESS
    shipment.services.limitedAccessPickup = shipment.services?.limitedAccessPickup ? shipment.services?.limitedAccessPickupType : null;
    shipment.services.limitedAccessDelivery = shipment.services?.limitedAccessDelivery ? shipment.services?.limitedAccessDeliveryType : null;
    if (shipment.equipment?._id) delete shipment.equipment._id;

    let minDate = new Date("2024-11-06T03:00:00.000+00:00");
    let shipmentCreatedAt = new Date(shipment?.created?.at);

    minDate = minDate.getTime();
    shipmentCreatedAt = shipmentCreatedAt.getTime();

    let isTestable = false

    if (!shipmentCreatedAt && shipment.isCox && shipment.poNumber)
      isTestable = true
    if (shipmentCreatedAt >= minDate && shipment.isCox && shipment.poNumber)
      isTestable = true

    if (isTestable) {
      if (!shipment.pickups[0]?.items[0]) throw Error('Select at least one Package');

      //Fields required for ASN integration
      const packageSlipRequired = shipment.pickups[0].packageSlip || shipment.stops[0].packageSlip;
      shipment.pickups[0].items.forEach((item) => {
        if (!item.poNumber) throw Error(`Select at least one PO Number to Package ${item.description ? item.description : ''}`);

        if (packageSlipRequired && !item.packageSlip) throw Error('Package slip required for package');

        item.subItems.forEach((subItem) => {
          if (!subItem.sku) throw Error(`SKU can't be empty for item ${item.description ? item.description : ''}`);

          if (!subItem.quantity) throw Error(`Item quantity can't be empty on ${item.description ? item.description : ''} item`);
        })
      })
    }

    return shipment;
  }

  static event(shipment, type = "inbound") {
    let start;
    let end;

    if (type == "inbound") {
      start = shipment.stops[0].targetDeliveryEarly;
      end = shipment.stops[0].targetDeliveryLate;
    } else {
      start = shipment.pickups[0].targetShipEarly;
      end = shipment.pickups[0].targetShipLate;
    }

    return {
      title: shipment.primaryRefence,
      start,
      end,
      extendedProps: shipment,
    };
  }

  static get createSchema() {
    return z
      .object({
        customer: z.string().min(2, "Required Field"),
        createDate: z.coerce.date().default(new Date()),
        createdBy: z.string().min(2, "Required Field"),
        poNumber: z.string().min(2, "Required Field"),
        mode: z.string().min(1, "Required Field"),
        coxPONumber: z.string().min(2, "Required Field"),
        shipmentNumber: z.string().min(2, "Required Field"),
        equipmentDescriptions: z.string(),
        serviceDescriptions: z.string().min(2, "Required Field"),
        pickups: Pickup.createSchema, // Array of pickup stops
        stops: Stop.createSchema, // Array of delivery stops
        hazardous: Hazardous.createSchema.required(),
        services: Service.createSchema,
        items: z.array(Item.createSchema), // Array of items
        equipment: Equipment.createSchema,
      })
      .required();
  }
}