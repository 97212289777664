import { EventAvailableOutlined, EventBusyOutlined, PendingActions, PendingActionsOutlined } from "@mui/icons-material";
import { Chip } from "@mui/material";

export function StatusChip({status}) {

  function getStatusIcon() {
    switch (status) {
      case "pending":
        return <PendingActionsOutlined />;
      case "approved":
        return <EventAvailableOutlined />;
      case "rejected":
        return <EventBusyOutlined />;
      default:
        return <PendingActions />;
    }
  }

  return (
    <Chip
      size="sm"
      icon={getStatusIcon()}
      className={status}
      variant="outlined"
      label={status[0].toUpperCase() + status.slice(1)}
      sx={{
        opacity: 0.7,
        px: "0.5rem",
        py: "0.35rem",
        "&.rejected, &.rejected svg": {
          color: "error.dark",
        },
        "&.approved, &.approved svg": {
          color: "success.darker",
        },
      }}
    />
  );
}
