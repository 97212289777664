import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Checkbox,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";

export function CalendarFilters({ options, onFilterChange }) {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        pl: "34px",
        pr: "22px",
        mx: "auto",
      }}
    >
      <Grid container flex={1} columns={12} columnGap={2}>
        { Object.keys(options)?.length &&
          Object.keys(options).map((key) => {
            const filter = options[key];
            return (
              <Grid item xs={2} key={key} sx={{ width: "100%", flex: 1 }}>
                <CalendarFiltersAutocomplete
                  filter={filter}
                  onFilterChange={onFilterChange}
                  filterKey={key}
                />
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
}

export function CalendarFiltersAutocomplete({
  filter,
  filterKey,
  onFilterChange,
}) {
  return (
    <Autocomplete
      multiple
      freeSolo
      limitTags={0}
      options={filter.options}
      getOptionLabel={(option) => option.label}
      onChange={(_, values) => onFilterChange(filterKey, values)}
      size="small"
      disableCloseOnSelect
      renderInput={(params) => {
        console.log(params)
        const { startAdornment } = params.InputProps;
        return (
          <TextField
          variant="standard"
            placeholder={
              filterKey.charAt(0).toLocaleUpperCase() + filterKey.slice(1)
            }
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: [<InputAdornment>{filter.icon}</InputAdornment>],
              endAdornment: [
                params.InputProps?.endAdornment,
                startAdornment && (
                  <InputAdornment position="end">
                    {startAdornment?.length > 1 
                      ? `+ ${startAdornment?.length}`
                      : startAdornment}
                  </InputAdornment>
                ),
              ],
            }}
          />
        );
      }}
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props;
        return (
          <li key={key} {...optionProps}>
            <Checkbox
              icon={<CheckBoxOutlineBlank fontSize="small" />}
              checkedIcon={<CheckBox fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.label?.charAt(0)?.toLocaleUpperCase() + option.label?.slice(1)}
          </li>
        );
      }}
    />
  );
}
