import { createContext, useState } from "react";
import { useSnackbar } from "src/hooks/useSnackbar";

export const TenantDetailsContext = createContext(null);

export function TenantDetailsProvider({ children, tenant: initialTenant }) {
  const { setSnackbarMessage } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [tenant, setTenant] = useState(initialTenant);

  return (
    <TenantDetailsContext.Provider
      value={{
        isLoading,
        tenant,
        setTenant,
      }}
    >
      {children}
    </TenantDetailsContext.Provider>
  );
}
