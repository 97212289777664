import { Checkbox, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import { Controller, useFormContext } from "react-hook-form";

export const FormInput = (props) => {
  const { name, ...otherProps } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const getNestedError = (errors, fieldName) => {
    const [...keys] = fieldName.split(".");

    return keys.reduce((value, key) => {
      if (value && value[key]) {
        return value[key];
      }
      return null;
    }, errors);
  };
  const isCheckbox = props.type == "checkbox";
  const isDateTime = false;//props.type == "date";

  const Component = isCheckbox
    ? Checkbox
    : isDateTime
      ? DateTimePicker
      : TextField;

  const customProps = () => {
    let props = {};
    if (!isCheckbox) {
      props.helperText = getNestedError(errors, name)?.message

    } else if (!isCheckbox && !isDateTime) {
      props.InputLabelProps = { shrink: true, ...otherProps.InputLabelProps };
      props.error = !!getNestedError(errors, name);
    }

    isDateTime && console.log(otherProps)

    return props;
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={props.defaultValue ? props.defaultValue : ""}
      render={({ field }) => {
        const { onChange } = field;
        return (
          <Component
            // size="small"
            {...otherProps}
            {...customProps()}
            {...field}
            onChange={(e) => {
              if (otherProps.onChange)
                otherProps.onChange(e);
              onChange(e);
            }}
          />
        )
      }}
    />
  );
};
