import { Box, Grid, ListItem, Typography, Divider } from "@mui/material";
import { Location } from "src/models/location";
import { Service } from "src/models/service";
import { useShipmentFormContext } from "src/pages/shipments/hooks/useForm";

export function ListItemLocationDetail(props) {
  const { location, customProps } = props;
  const type = props.type ? props.type : 'pickup'

  const { mapFields: fieldMapper } = useShipmentFormContext();

  function handleLocationSelection() {
    const shipmentAux = {
      ...Location.toShipment(location, type),
      services: Service.fromLocation(location, type)
    };
    fieldMapper(shipmentAux);
  }

  return (
    <>
      {type == "pickup" && (location.category) && (
        <>
          <Divider />
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1, mb: 1, textAlign: 'center' }}>
            <strong>{ location.category }</strong>
          </Typography>
        </>
      )}
      <ListItem
        {...customProps}
        onClick={(event) => {
          customProps.onClick(event);
          handleLocationSelection();
        }}
      >
        <Grid container alignItems="center">
          <Grid item sx={{ width: "100%" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body2" color="text.secondary">
                Location Name
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
                noWrap
                fontWeight={600}
              >
                {location.name}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body2" color="text.secondary">
                Address 1
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
                fontWeight={600}
                noWrap
              >
                {location.addr1 ? location.addr1 : location.location ? location.location : '--'}
              </Typography>
            </Box>
            <Box sx={{
                    width:"100%",
                    display:"flex",
                    alignItems:"center",
                    justifyContent: "space-between",
                    }}>
            <Typography variant="body2" color="text.secondary">
                ZIP
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ textOverflow: "ellipsis", overflow: "hidden"}}
                noWrap
                fontWeight={600}
              >
                {location.zip ? location.zip : '--'}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </ListItem>
    </>
  );
}
