import { Helmet } from "react-helmet-async";
import { DataTable } from "src/components/datagrid";
import { useLocationQueries } from "./hooks/useLocationQueries";
import { LocationColums, LocationDetailComuns as LocationDetailColumns } from "./components/LocationColums";
import CustomToolbar from "src/components/datagrid/widgets/CustomToolbar";
import { Box, Button, Card, Container, Grid, LinearProgress, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { LocationDetailsDrawer } from "./components/DetailsDrawer";

export function LocationsPage() {
    const { isLocationsLoading, locations, getLocations, isLocationsRefetching } = useLocationQueries();
    const { locationId } = useParams();
    const navigate = useNavigate();

    useEffect(()=> {
        getLocations()
    },[])

    return (
        <>
            <Helmet><title>Locations</title></Helmet>
            <Container
                maxWidth="false"
                sx={{ width: 'fit-content', maxWidth: '100%' }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <Typography
                        variant="h4"
                        sx={{
                            mb: 1,
                            textAlign: 'center',
                            display: 'flex'
                        }}
                    >
                        Locations
                    </Typography>
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={() => navigate('/locations/create')}
                        sx={{ mb: 1, textTransform: 'capitalize' }}
                    >
                        + Add Locations
                    </Button>
                </Box>
                <Grid container item xs={12} justifyContent={'center'}>
                    <Card>
                        <section className="dataTable">
                            <DataTable
                                onEditTapped={(value) => navigate(`/locations/${value._id}/edit`)}
                                selectedItemId={locationId}
                                loading={isLocationsLoading || isLocationsRefetching}
                                loadData={locations ?? []}
                                columns={LocationColums}
                                customDrawerComponent={LocationDetailsDrawer}

                                className="datagrid"
                                pagination
                                autoHeight
                                density="compact"
                                disableDensitySelector
                                initState={{
                                    columns: {
                                        columnVisibilityModel: { id: false }
                                    }
                                }}
                                slots={{
                                    toolbar: () => <CustomToolbar columns={LocationColums} rows={locations} title="Locations"/>,
                                    loadingOverlay: LinearProgress
                                }}
                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        printOptions: { disableToolbarButton: true },
                                        quickFilterProps: { debounceMs: 500 },
                                        loadData: locations ?? [],
                                    }
                                }}
                                detailsDrawerTitle="Locations"
                                detailColumns={LocationDetailColumns}
                                modelKey={'locations'}
                                modelGridSetup={{

                                }}
                            />
                        </section>
                    </Card>
                </Grid>

            </Container>
        </>
    );
}