import { Box, Typography } from "@mui/material";
import { useMutation } from "react-query";
import { ForgotPasswordService } from "src/api/forgotPassword.service";
import { useSnackbar } from "src/hooks/useSnackbar";

export function useForgotPasswordNoteQueries() {
    const service = new ForgotPasswordService();
    const { setSnackbarMessage } = useSnackbar();

    const { mutate: forgotPassword, isLoading: isSendingEmailResetPassword } = useMutation({
        mutationFn: (value) =>
            service.forgotPassword(value.email)
                .then((data) => {
                    if (data) {
                        return setSnackbarMessage({
                            message: "A password reset link has been sent to your email address, if it is registered. Please check your inbox and follow the instructions to reset your password. If you don’t receive the email within a few minutes, check your spam or junk folder.",
                            severity: "success",
                        });
                    }
                    setSnackbarMessage({
                        message: "It looks like we were unable to send a password reset email at this time. Please try again later. If the problem persists, you may want to contact support for assistance.",
                        severity: "error"
                    });
                }).catch((error) => {
                    setSnackbarMessage({
                        message: (
                            <Box>
                                <Typography mb={1}>Error to send an e-mail</Typography>
                                <Typography>{error.message}</Typography>
                            </Box>
                        ),
                        severity: "error",
                    });
                }),
    });

    const { mutate: resetPassword, isLoading: isResettingPassword, isSuccess: passwordChanged } = useMutation({
        mutationFn: (values) =>
            service.resetPassword(values)
                .then((data) => {
                    if (data) {
                        return setSnackbarMessage({
                            message: "The password was changed with success!",
                            severity: "success",
                        });
                    }
                    setSnackbarMessage({
                        message: "The password field is currently not editable. Please ensure you are following the correct procedure to update your password. If you need assistance, contact support.",
                        severity: "error"
                    });
                }).catch((error) => {
                    setSnackbarMessage({
                        message: (
                            <Box>
                                <Typography mb={1}>Error to send an e-mail</Typography>
                                <Typography>{error.message}</Typography>
                            </Box>
                        ),
                        severity: "error",
                    });
                }),
    });

    const handleResetPassword = (token, email, password) => {
        resetPassword({token, email, password});
    }

    return {
        forgotPassword,
        isSendingEmailResetPassword,

        handleResetPassword,
        isResettingPassword,
        passwordChanged,
    }
}