import { ExpandMoreOutlined } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, Typography } from "@mui/material";

export const SubItems = ({ subItem }) => {
    const truncatedDescription = subItem?.description?.length > 20
        ? subItem?.description?.substring(0, 30) + "..."
        : subItem?.description

    return (
        <Accordion
            variant="outlined"
        >
            <AccordionSummary
                expandIcon={<ExpandMoreOutlined />}
                sx={{ display: "flex", gap: 2 }}
            >
                <Box
                    component="span"
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        py: "0.25rem",
                    }}
                >
                    <Box display={"flex"} gap={1} sx={{ justifyContent: 'space-between' }}>
                        <Typography color={"GrayText"}>Item</Typography>
                        <Typography color={"GrayText"}>{truncatedDescription}</Typography>
                    </Box>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Card
                    variant="elevation"
                    elevation={0}
                    sx={{
                        py: "0.5rem",
                        px: "1rem",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.75rem",
                        "&.MuiCard-root": {
                            p: 0,
                        },
                    }}
                >
                    <CardContent
                        sx={{
                            "&.MuiCardContent-root": {
                                p: 0,
                            },
                        }}
                    >
                        {/* Description */}
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                py: "0.25rem",
                            }}
                        >
                            <Typography sx={{ whiteSpace: "nowrap" }}>
                                Description -{" "}
                            </Typography>
                            <Typography fontWeight={600}>{subItem.description ? subItem.description.substring(0, 52) + "..." : '--'}</Typography>
                        </Box>

                        {/* SKU */}
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                py: "0.25rem",
                            }}
                        >
                            <Typography>SKU -</Typography>
                            <Typography fontWeight={600}>{subItem.sku ? subItem.sku : '--'}</Typography>
                        </Box>

                        {/* Carton Number */}
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                py: "0.25rem",
                            }}
                        >
                            <Typography>Carton Number -</Typography>
                            <Typography fontWeight={600}>{subItem.cartonNumber ? subItem.cartonNumber : '--'}</Typography>
                        </Box>

                        {/* Quantity */}
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                py: "0.25rem",
                            }}
                        >
                            <Typography>Quantity -</Typography>
                            <Typography fontWeight={600}>{subItem.quantity ? subItem.quantity : '--'}</Typography>
                        </Box>

                        {/* Quantity UOM */}
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                py: "0.25rem",
                            }}
                        >
                            <Typography>Unit -</Typography>
                            <Typography fontWeight={600}>{subItem.quantityUOM ? subItem.quantityUOM : '--'}</Typography>
                        </Box>
                    </CardContent>
                </Card>
            </AccordionDetails>
        </Accordion>
    );
};
