import {
  Card,
  Container,
  Grid,
  LinearProgress,
  Typography,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { DataTable } from "src/components/datagrid";
import CustomToolbar from "src/components/datagrid/widgets/CustomToolbar";
import { vendorsColumns } from "./vendorsColumns";
import { vendorDetailsColumns } from "./vendorDetailsColumns";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "src/hooks/useAuth";
import { VendorService } from "src/api/vendor.service";

export function VendorsPage() {
  const { session } = useAuth();
  const [apiData, setApiData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const navigate = useNavigate();
  const isBackOfficeUser = session.permissions.IsBackOffice;

  const service = new VendorService(session.token, session.tenant._id);

  const { vendorId } = useParams()

  // Fetching the data from API
  useEffect(() => {
    (async () => {
      try {
        setIsLoadingData(true);
        const result = await service.getAll(true);
        setApiData(result);
      } catch (exception) {
        console.error(`An error ocurred on fetching loads data: ${exception}`);
      } finally {
        setIsLoadingData(false);
      }
    })();
  }, []);

  const AddButton = () => {
    if (!isBackOfficeUser) return null;

    return (
      <Button
        size="small"
        variant="outlined"
        onClick={() => navigate("/vendors/create")}
        sx={{ mb: 1, textTransform: "capitalize" }}
      >
        + Add Vendor
      </Button>
    );
  };

  return (
    <>
      <Helmet>
        <title> Vendors </title>
      </Helmet>
      <Container maxWidth="false">
        <Typography
          variant="h4"
          sx={{ mb: 1, textAlign: "center", display: "flex" }}
        >
          Vendors
        </Typography>
        <AddButton />
        <Grid container item xs={3}>
          <Card>
            <section className="dataTable">
              <DataTable
                detailsDrawerTitle="Vendors"
                loadData={apiData}
                columns={vendorsColumns}
                className="datagrid"
                pagination
                autoHeight
                density="compact"
                getRowId={(row) => row._id}
                // disableColumnFilter
                // disableColumnSelector
                disableDensitySelector
                loading={isLoadingData}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      _id: false,
                    },
                  },
                }}
                slots={{
                  toolbar: () => <CustomToolbar columns={vendorsColumns} rows={apiData} title="Vendors"/>,
                  loadingOverlay: LinearProgress,
                }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    printOptions: { disableToolbarButton: true },
                    quickFilterProps: { debounceMs: 500 },
                    loadData: apiData,
                  },
                }}
                detailColumns={vendorDetailsColumns}
                onEditTapped={(value) => navigate(`/vendors/${value._id}/edit`)}
                selectedItemId={vendorId}
                modelKey={"vendors"}
                modelGridSetup={{
                  desktop: desktopSetup,
                  mobile: mobileSetup,
                }}
              />
            </section>
          </Card>
        </Grid>
      </Container>
    </>
  );
}

const desktopSetup = {
  pinnedColumns: {},
  columns: {
    columnVisibilityModel: {},
    orderedFields: [
      "name",      
    ],
    dimensions: {
      name: {
        maxWidth: -1,
        minWidth: 150,
        width: 500,
      },     
    },
  },
  preferencePanel: {
    open: false,
  },
  filter: {
    filterModel: {
      items: [],
      logicOperator: "and",
      quickFilterValues: [],
      quickFilterLogicOperator: "and",
    },
  },
  sorting: {
    sortModel: [],
  },
  pagination: {
    paginationModel: {
      page: 0,
      pageSize: 15,
    },
  },
};

const mobileSetup = {
  pinnedColumns: {},
  columns: {
    columnVisibilityModel: {},
    orderedFields: [
      "name",      
    ],
    dimensions: {
      name: {
        maxWidth: -1,
        minWidth: 150,
        width: 100,
      },      
    },
  },
  preferencePanel: {
    open: false,
  },
  filter: {
    filterModel: {
      items: [],
      logicOperator: "and",
      quickFilterValues: [],
      quickFilterLogicOperator: "and",
    },
  },
  sorting: {
    sortModel: [],
  },
  pagination: {
    paginationModel: {
      page: 0,
      pageSize: 15,
    },
  },
};
