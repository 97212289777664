import { z } from "zod";

export class Equipment {
  static get createSchema() {
    return z.object({
      dryVanTrailer: z.boolean(),
      flatbed: z.boolean(),
      ltl: z.boolean(),
      refrigeratedTrailer: z.boolean(),
      stepDeck: z.boolean(),
    });
  }
}