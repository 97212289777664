import { types } from "./types";

const initialState = {};

/**
 * @example <caption>
 *    Payload = {
 *      isLogged: true,
 *      session: {
 *          "id": 1,
 *          "userName": "example@mail.com",
 *          "token": "eyJhb...",
 *          "sessionId": "4vm989nl",
 *          "sessionDate": "2023-04-17T03:18:39.2974144Z"
 *      },
 *      error: {
 *        internalCode: "INVALID_LOGIN_ATTEMPT"
 *        message: "username and/or password are invalid"
 *      }
 *    }
 * </caption>
 * @param {*} state 
 * @param {*} param1 
 * @returns 
 */
const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.login:
      return {
        isLogged: true,
        session: payload,
      };

    case types.loginFailure:
      const errorMessage = 
        payload.message ? payload.message 
        : typeof payload == 'string' ? payload : JSON.stringify(payload);

      return {
        isLogged: false,
        error: errorMessage
      };

    case types.logout:
      return {
        isLogged: false,
      };

    default:
      return state;
  }
};

export default authReducer;
