import {
  Card,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

export function ModuleFields() {
  const { control } = useFormContext();

  return (
    <Card
      variant="outlined"
      sx={{
        py: "1.5rem",
        px: "1rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.75rem",
      }}
    >
      <CardHeader title={"Modules"} sx={{ "&.MuiCardHeader-root": { p: 0 } }} />
      <Grid container sx={{ width: "100%", justifyContent: "center" }}>
        <Grid item xs={3}>
          <Controller
            control={control}
            name={"modules.shipment"}
            render={({ field }) => (
              <FormControlLabel
                sx={{ width: "100%", margin: 0 }}
                control={<Checkbox {...field} checked={!!field.value} />}
                label={`Shipments`}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            control={control}
            name={"modules.schedule"}
            render={({ field }) => (
              <FormControlLabel
                sx={{ width: "100%", margin: 0 }}
                control={<Checkbox {...field} checked={!!field.value} />}
                label={`Schedule`}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            control={control}
            name={"modules.warehouse"}
            render={({ field }) => (
              <FormControlLabel
                sx={{ width: "100%", margin: 0 }}
                control={<Checkbox {...field} checked={!!field.value} />}
                label={`Warehouses`}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            control={control}
            name={"modules.spotmarket"}
            render={({ field }) => (
              <FormControlLabel
                sx={{ width: "100%", margin: 0 }}
                control={<Checkbox {...field} checked={!!field.value} />}
                label={`Spotmarket`}
              />
            )}
          />
        </Grid>
      </Grid>
    </Card>
  );
}
