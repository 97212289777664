import {
  Box,
  Grid,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FormInput } from "src/components/form/FormInput";
import { dimensionsUnits, palletCountUnits, weightUnits } from "../../units";

import { Controller } from "react-hook-form";
import { useShipmentFormContext } from "../../../hooks/useForm";
import { useAuth } from "src/hooks/useAuth";
import { usePackageForm } from "src/pages/shipments/context/PackageContext";

export function ShipmentItemForm({ itemIndex = 0 }) {
  const { form } = useShipmentFormContext();
  const { session } = useAuth();
  const isBackOfficeUser = session.permissions.IsBackOffice;
  const { packageIndex } = usePackageForm();

  return (
    <Stack spacing={2}>
      <Grid container rowGap={2}>
        <Grid item xs={12} md={3}>
          <FormInput
            label="SKU"
            name={`items.${packageIndex}.subItems.${itemIndex}.sku`}
            type="text"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={form.control}
            name={`items.${packageIndex}.subItems.${itemIndex}.description`}
            required
            render={({ field }) => (
              <TextField label="Description" {...field} fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormInput
            name={`items.${packageIndex}.subItems.${itemIndex}.cartonNumber`}
            label="Carton Number"
            type="text"
            fullWidth
          />
        </Grid>
      </Grid>

      {/* PIECE COUNT & PALLET COUNT */}
      <Grid container rowGap={2}>
        <Grid item xs={12} md={6}>
          <FormInput
            name={`items.${packageIndex}.subItems.${itemIndex}.quantity`}
            label="Quantity"
            type="number"
            required
            min={0}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput
            name={`items.${packageIndex}.subItems.${itemIndex}.quantityUOM`}
            label="Unit"
            defaultValue={
              form.getValues("quantityUOM")
                ? form.getValues("quantityUOM")
                : palletCountUnits[3]
            }
            select
            required
            SelectProps={{
              MenuProps: {
                PaperProps: { sx: { maxHeight: "10rem" } },
              },
            }}
            sx={{ minWidth: "6rem" }}
            fullWidth
          >
            {palletCountUnits.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </FormInput>
        </Grid>
      </Grid>
    </Stack>
  );
}
